import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './MessageModal.css'; 

const MessageModal = (props) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleButtonClick = async (result) => {
    if (result === 'ok') {
      if (!message.trim()) {
        setError('Field cannot be empty');
        return;
      }

      props.onConfirm(message); 
      props.onHide(); 
    } else {
      props.onHide(); 
    }
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <div className="message-modal">
      <div className="message-modal asc-window modal-dlg notransform" id="window-view4241">
        <div className="body">
          <div className="box">
            <h6 style={{ marginBottom: '5px',  }}>
              Enter a message
            </h6>
            <div id="id-dlg-username-caption" className="input-row">
              <div className="input-field">
                <textarea
                  spellCheck="false"
                  className="form-control custom-input"
                  value={message}
                  onChange={handleChange}
                />
                {error && <span className="input-error">{error}</span>}
              </div>
            </div>
          </div>
          <div className="flex-row-center-end" style={{padding: "0px 15px", margin: "15px 0px 10px 0px"}}>
            <Button
              className="custom-button"
              onClick={() => handleButtonClick('ok')}
              style={{ margin: "0px 10px 0px 0px" }}
            >
              OK
            </Button>
            <Button
              className="custom-button"
              onClick={() => handleButtonClick('cancel')}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
