export const setToLS = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  
  export const getFromLS = key => {
    const value = window.localStorage.getItem(key);
  
    if (value && isJsonString(value)) {
      return JSON.parse(value);
    }else{
      return value
    }
  }

  export const setToSS = (key, value) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  export const getFromSS = key => {
    const value = window.sessionStorage.getItem(key);
  
    if (value) {
      return JSON.parse(value);
    }
  }

  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}