import React, { useState, useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { Col } from "react-bootstrap";
import { v4 } from "uuid";
import Form from 'react-bootstrap/Form';
import "./inputbox.css";

const SelectRenderer = (props) => {
    const [selectOptionList, setSelectOptionList] = useState()
    const [inputValue, setInputValue] = useState(props.field.label)

    /**
     * Update current select option list when parent select option list is updated
     */
    useEffect(() => {
        setSelectOptionList(props.selectoptionlist)
    }, [props.selectoptionlist])

    /**
     * Updating the label for the given field
     * @param {*} event: Width select trigger event 
     * @param {*} field: Field component 
     */
    const updateFieldLabel = (event, field) => {
        const newList = [...selectOptionList]
        const index = newList.findIndex((obj) => obj.id === props.field.id)
        const updatedField = newList[index]
        updatedField["label"] = event.target.value
        updatedField["value"] = event.target.value
        newList.splice(index, 1, updatedField)
        setSelectOptionList(newList)
        props.rsprops.updatecomponent(props.selectschema, "options", newList)
        props.setselectoptionlist(newList)
    }

    /**
     * Remove the provided component from schema
     * @param {*} field: Field component 
     */
    const removeOption = (field) => {
        const newList = [...selectOptionList]
        const index = newList.findIndex((obj) => obj.id === field.id)
        if (index !== -1) {
            newList.splice(index, 1)
        }
        setSelectOptionList(newList)
        props.rsprops.updatecomponent(props.selectschema, "options", newList)
        props.setselectoptionlist(newList)
    }

    return (
        <div className='flex-row-center-start' style={{ margin: "0px 0px 10px 0px" }}>
            <Icon.Square style={{ fontSize: 20, color: "var(--theme1_primary)", cursor: "pointer" }} />
            <Col md={9} style={{ margin: "0px 15px" }}>
                <input type="text" className="form-control move-left custom-input"
                    placeholder={'Enter custom data'}
                    value={inputValue}
                    onChange={(event) => {
                        setInputValue(event.target.value)
                        updateFieldLabel(event, props.field)
                    }}
                    autoComplete="nope"
                />
            </Col>
            <Icon.DashCircleFill style={{ fontSize: 20, color: "var(--theme1_primary)", cursor: "pointer", marginLeft: 20 }} onClick={() => removeOption(props.field)} />
        </div>
    )
}

export const RequiredSelect = (rsprops) => {
    const [baseId, setBaseId] = useState(rsprops.baseid)
    const [selectSchema, setSelectSchema] = useState(rsprops.selectschema[0])
    const [selectOptionList, setSelectOptionList] = useState(rsprops.selectschema[0].options)

    /**
     * Every time the parent base id is updated update it in child
     */
    useEffect(() => {
        setBaseId(rsprops.baseid)
    }, [rsprops.baseid])

    /**
     * Create a new select option and add it to the parent initial data
     */
    const addOption = () => {
        const newId = v4()
        setSelectOptionList([...selectOptionList, {
            "id": newId,
            "label": "Untitled",
            "value": "Untitled"
        }])
        rsprops.updatecomponent(selectSchema, "options", [...selectOptionList, {
            "id": newId,
            "label": "Untitled",
            "value": "Untitled"
        }])
    }

    return (
        <Form className='no-padding'>
            <div className='flex-row-center-start' style={{ padding: "10px 0px" }}>
                <p style={{ marginRight: 20, marginBottom: 0 }}>Select options </p>
                <Icon.PlusCircleFill style={{ fontSize: 20, color: "var(--theme1_primary)", cursor: "pointer" }} onClick={() => addOption()} />
            </div>
            {
                selectOptionList.map((field, i) =>
                (<SelectRenderer
                    key={field.id}
                    index={i}
                    field={field}
                    selectschema={selectSchema}
                    rsprops={rsprops}
                    selectoptionlist={selectOptionList}
                    setselectoptionlist={(selectoptionlist) => setSelectOptionList(selectoptionlist)}
                />)
                )
            }
        </Form>
    )
}