import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Modal,
    Form,
    Button
} from "react-bootstrap";
import { getFromLS } from "../../../utils/storage";
import { FormContext } from "../../reusable-components/InputFields/FormContext";
import Element from '../../reusable-components/InputFields/input';
import { v4 } from "uuid";
import './UploadSpreadsheetModal.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as Icon from "react-bootstrap-icons";
import { ThreeCircles, Bars } from "react-loader-spinner";

const AddNewWizardModal = (props) => {
    const [wizardData, setWizardData] = useState([])
    const [selectedFields, setSelectedFields] = useState([])
    const [message, setMessage] = useState(props.message)
    const [wizardDataResetCopy, setWizardDataResetCopy] = useState([])
    const [activePage, setActivePage] = useState(0)
    const [previewComponents, setPreviewComponents] = useState([])
    const [errors, setErrors] = useState([])
    const [engagementLetterTemplateId, setEngagementLetterTemplateId] = useState(props.templateid)

    useEffect(() => {
        if (props.wizardata !== undefined) {
            setWizardData(JSON.parse(JSON.stringify(props.wizardata)))
            console.log("WIZARD DATA: ", JSON.parse(JSON.stringify(props.wizardata)))
            setWizardDataResetCopy(JSON.parse(JSON.stringify(props.wizardata)))
        }
    }, [props.wizardata])

    useEffect(() => {
        if (props.selectedfields !== undefined) {
            // console.log("SELECTED FIELDS PASSED TO WIZARD: ", props.selectedfields)
            setSelectedFields(props.selectedfields)
        }
    }, [props.selectedfields])


    useEffect(() => {
        setMessage(props.message)
    }, [props.message])


    useEffect(() => {
        setEngagementLetterTemplateId(props.templateid)
    }, [props.templateid])


    /**
    * Update fields and preview components everytime initialData changes
    */
    useEffect(() => {
        if (wizardData !== undefined && wizardData !== null && wizardData.length > 0) {
            if (wizardData[activePage]["data"]) {
                setPreviewComponents(wizardData[activePage]["data"])
            }
        }
    }, [activePage, wizardData])

    const [saveWizardApiFlag, setSaveWizardApiFlag] = useState()

    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const saveWizardData = () => {
        let payload_format = { "TemplateId": engagementLetterTemplateId }
        for (let page of wizardData) {
            for (let field of page.data) {
                // Find the field with the matching label
                if(field.type !== "header"){
                    const required_field = selectedFields.find(fld => fld.label === field.label);
                    payload_format[[required_field.value]] = field.value
                }
            }
        }
        // console.log("PAYLOAD FORMAT: ", payload_format)
        setSaveWizardApiFlag(true)
        props.setapiflag(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(payload_format)
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/mail_merge/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    toast.success(
                        "Response saved successfully!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                    props.refresh()
                    props.onHide()
                    props.setapiflag(false)
                    setSaveWizardApiFlag(false)
                    handleClose()
                } else {
                    toast.error(
                        "Failed to save response: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                    props.onHide()
                    props.setapiflag(false)
                    setSaveWizardApiFlag(false)
                    handleClose()
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleClose = () => {
        setWizardData(wizardDataResetCopy);
        setActivePage(0)
        props.setapiflag(false);
        props.onHide();
    }

    // Toggle flag for demonstration (update colors)
    const toggleFlag = (index) => {
        setWizardData((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? { ...item, flag: true } : item
            )
        );
    };


    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const handleChange = (field_id, event) => {
        const newList = [...previewComponents]
        newList.forEach(field => {
            const { type, id } = field;
            if (field_id === id) {
                switch (type) {
                    case 'checkbox':
                        field['value'] = event.target.checked;
                        break;

                    case 'radio':
                        for (let fld of newList) {
                            if (fld["radiogroup"] === field["radiogroup"]) {
                                fld["value"] = false
                            }
                        }
                        field['value'] = event.target.checked;
                        break;

                    case 'select':
                        field['value'] = event.value;
                        break;

                    case 'file':
                        field['value'] = event[0];
                        break;

                    case 'date':
                        field['value'] = event;
                        break;

                    default:
                        field['value'] = event.target.value;
                        break;
                }
            }
            const newListTwo = [...newList]
            setPreviewComponents(newListTwo)
        })
    }

    const PageValidationChecker = (data) => {
        const error_list = []
        for (let record of data) {
            if (record.mandatory && record.value === "") {
                error_list.push(record.label)
            }
        }

        if (error_list.length === 0) {
            toggleFlag(activePage)
            setActivePage(activePage + 1)
        }
        return error_list
    }

    const validateAndNext = () => {
        console.log("PREVIEW")
        console.log("ACTIVE PAGE: ", activePage + 1)
        const errs = PageValidationChecker(previewComponents)
        setErrors(errs)
    }

    return (
        <Modal
            {...props}
            onHide={() => handleClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className={"add-wizard-modal"}
        >
            <Modal.Header closeButton style={{ padding: 0, margin: 0 }}>
            </Modal.Header>
            <Modal.Body style={{ padding: 0, margin: 0 }}>
                {
                    wizardData.length === 0 ?
                        <div className="wizard-loader-container">
                            {
                                message.includes("Loading") ?
                                    <div className="fit-width" style={{ marginRight: 20 }}>
                                        <ThreeCircles
                                            height="40"
                                            width="40"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="var(--theme1_tertiary)"
                                            innerCircleColor="var(--theme1_secondary)"
                                            middleCircleColor="var(--theme1_primary)"
                                        />
                                    </div>
                                    :
                                    <Icon.ExclamationOctagonFill color="orange" style={{ fontSize: 40, marginRight: 20, marginTop: -10 }} />
                            }
                            <div className="fit-width">
                                {selectedFields.length === 0
                                    ?
                                    <h6 style={{ lineHeight: 1.5 }}>Unable to load the wizard, please try again. If the problem persists, please contact support.</h6>
                                    :
                                    <h6 style={{ lineHeight: 1.5 }}>{message}</h6>
                                }
                            </div>
                        </div>
                        :
                        <>
                            <Row className="no-gutter wizard-page-connector">
                                {wizardData.map((item, index) => (
                                    <div className="circle-wrapper" key={item.id}>
                                        {/* Circle with index */}
                                        <div
                                            className={`circle ${item.flag ? 'active' : ''} ${index === activePage ? 'current' : ''}`}
                                            // style={{ width: `calc(750px/${wizardData.length * 3})`, height: `calc(750px/${wizardData.length * 3})` }}
                                            style={{ width: 50, height: 50 }}
                                        >
                                            {item.flag ? (index === activePage ? index + 1 : <Icon.CheckAll style={{ fontSize: 30 }} />) : index + 1}
                                        </div>
                                        {/* Line connecting circles */}
                                        {index < wizardData.length - 1 && <div className={`line  ${item.flag ? 'active' : ''}`} />}
                                    </div>
                                ))}
                            </Row>
                            <Row className="no-gutter">
                                <div className="current-page-container">
                                    <h6 className="current-page-name">{wizardData[activePage].pageName}</h6>
                                </div>
                            </Row>
                            <Row className="no-gutter wizard-page-form-container">
                                <FormContext.Provider value={{ handleChange }}>
                                    <Form className='no-padding'>
                                        <Row>
                                            {
                                                previewComponents &&
                                                previewComponents.map((field, i) =>
                                                    <Element
                                                        key={i}
                                                        field={field}
                                                    />
                                                )
                                            }
                                        </Row>
                                    </Form>
                                </FormContext.Provider>
                            </Row>
                            <Row className="no-gutter wizard-page-buttons-container">
                                {errors.length !== 0 ?
                                    <div className="wizard-form-validation-error-container">
                                        {/* The {errors.map((item, index)=> index<errors.length-1 ? <b>{item}, </b>: <b>{item}</b>)} are required fields, please make sure all required fields are filled before moving on to the next step. */}
                                        * are required fields
                                    </div> :
                                    <></>
                                }
                                <div className="fit-width">
                                    {
                                        activePage !== 0 ?
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                onClick={() => {
                                                    console.log("ACTIVE PAGE: ", activePage - 1)
                                                    setActivePage(activePage - 1)
                                                }}
                                                className="custom-button"
                                            >
                                                Back
                                            </Button> : <></>
                                    }
                                </div>
                                <div className="fit-width">
                                    {
                                        activePage !== wizardData.length - 1 ?
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                onClick={() => validateAndNext()}
                                                className="custom-button"
                                            >
                                                Next
                                            </Button> :
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                onClick={() => saveWizardData()}
                                                className="custom-button"
                                            >
                                                {
                                                    saveWizardApiFlag ?
                                                        <div style={{ marginRight: 6 }}>
                                                            <ThreeCircles
                                                                height="15"
                                                                width="15"
                                                                color="#4fa94d"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={true}
                                                                ariaLabel="three-circles-rotating"
                                                                outerCircleColor="var(--theme1_tertiary)"
                                                                innerCircleColor="var(--theme1_secondary)"
                                                                middleCircleColor="var(--theme1_primary)"
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                <p style={{ marginBottom: 0, marginTop: 0 }}>Submit</p>
                                            </Button>
                                    }
                                </div>
                            </Row>
                        </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default AddNewWizardModal;