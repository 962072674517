import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import uploadImg from "../../../images/cloud-upload-regular-240.png";
import fileDefault from "../../../images/file-blank-solid-240.png";
import fileExcel from "../../../images/excel.png";
import "./DropFile.css";

export const ImageConfig = {
  default: fileDefault,
  xlsx: fileExcel,
  xls: fileExcel,
};

const DropFileInput = (props) => {
  console.log("DROP FILE INPUT PROPS: ", props)
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => {
    wrapperRef.current.classList.remove("dragover");
    wrapperRef.current.classList.add("dropped");
  };

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      let updatedList = [...fileList, newFile];
      if (props && props.fileLimit && props.fileLimit === "single") {
        updatedList = [newFile];
        setFileList(updatedList);
      } else {
        setFileList(updatedList);
      }
      props.onFileChange(updatedList, e);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {fileList.length > 0 ? (
          <div className="drop-file-preview">
            {fileList.map((item, index) => (
              <div key={index} className="drop-file-preview__item">
                <img
                  src={
                    ImageConfig[item.name.split(".")[1]] ||
                    ImageConfig["default"]
                  }
                  alt=""
                />
                <div className="drop-file-preview__item__info">
                  {item.name}
                  {/* <p>{item.size}B</p> */}
                </div>
                <span
                  className="drop-file-preview__item__del"
                  onClick={() => fileRemove(item)}
                >
                  x
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className="drop-file-input__label">
            <img src={uploadImg} alt="" />
            {props.message === undefined || props.message === "" ?<p>Drag and drop your file here</p>: <p>{props.message}</p>}
          </div>
        )}
        <input type="file" value="" onChange={onFileDrop} />
      </div>
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
