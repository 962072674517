import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import {
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { setToLS } from "../../../utils/storage";
import { logout } from "../../../utils";
import * as Icon from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { PreviewFullWindowModal } from "./PreviewFullWindowModal";
import { RecordsFullWindowModal } from "./RecordsFullWindowModal";
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";


const MyWizardFormsGrid = (my_wizard_forms_grid_props) => {
    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const usertype = localStorage.getItem("usertype");
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);
    const navigate = useNavigate()
    const defaultColDef = useMemo(
        () => ({
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    const StatusRenderer = (status_props) => {
        console.log("STATUS PROPS: ", status_props)
        return(
            status_props.data.Status !== "Final" ?
            <><Icon.ExclamationDiamondFill color="orange"/> &nbsp;Drafted</>:
            <><Icon.CheckCircleFill color="green"/> &nbsp;Finalized</>
        )
    }
    const ActionsOnWizardFormRenderer = (action_on_wizard_form_props) => {
        console.log("ACTIO ON FORMS PROPS: ", action_on_wizard_form_props.data)
        useEffect(() => {
            my_wizard_forms_grid_props.setwizardformtitle(action_on_wizard_form_props.data.WizardFormName)
            my_wizard_forms_grid_props.setwizardformdescription(action_on_wizard_form_props.data.WizardFormPurpose)
        }, [action_on_wizard_form_props])

        const editWizardForm = () => {
            my_wizard_forms_grid_props.transporter("client_wizard_form_config", {
                "wizard_config_option": {
                    "create_wizard_form_option":
                    {
                        "id": action_on_wizard_form_props.data.WizardPageId,
                        "template_name": action_on_wizard_form_props.data.TemplateName,
                        "status": action_on_wizard_form_props.data.Status,
                        "template_purpose": action_on_wizard_form_props.data.TemplatePurpose,
                        "initial_data": action_on_wizard_form_props.data.InputStructure,
                        "preview_data": action_on_wizard_form_props.data.PreviewStructure,
                    }
                }
            })
        }
        const playForm = () => {
            my_wizard_forms_grid_props.setwizardformtitle(action_on_wizard_form_props.data.WizardFormName)
            my_wizard_forms_grid_props.setwizardformdescription(action_on_wizard_form_props.data.WizardFormPurpose)
            my_wizard_forms_grid_props.setwizardformurl(action_on_wizard_form_props.data.Url)
            my_wizard_forms_grid_props.setpreviewcomponents(action_on_wizard_form_props.data.PreviewStructure)
            my_wizard_forms_grid_props.setpreviewfullwindowmodal(true)
        }

        return (
            <div className="flex-row-space-center" style={{ width: 100 }}>
                {/* {usertype === "SuperAdmin" ? */}
                <>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="button-tooltip">
                                Edit form structure
                            </Tooltip>
                        }
                        error={true}
                    >
                        <Button onClick={() => editWizardForm()} style={{ cursor: "pointer", backgroundColor: "transparent", border: "0px solid white" }}>
                            <Icon.PencilSquare style={{ color: "var(--theme1_primary)" }} />
                        </Button>
                    </OverlayTrigger>
                    <>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Add new record
                                </Tooltip>
                            }
                            error={true}
                        >
                            <Button onClick={() => playForm()} style={{ cursor: "pointer", backgroundColor: "transparent", border: "0px solid white" }}>
                                <Icon.PlusCircleFill style={{ color: "var(--theme1_primary)", float: "right" }} />
                            </Button>
                        </OverlayTrigger>
                    </>
                </>
            </div>
        )
    }

    const CreatedDateRenderer = (created_date_props) => {
        const formatDateTime = (dateTimeString) => {
            // Convert the input string to a Date object in the specified timezone offset
            const date = new Date(dateTimeString + '-05:52'); // Append the timezone offset to the input string

            // Adjust to user's local timezone
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');

            // Format hours, minutes, and seconds
            let hour = date.getHours();
            const minute = String(date.getMinutes()).padStart(2, '0');
            const second = String(date.getSeconds()).padStart(2, '0');

            // Determine AM/PM notation
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12; // Hour '0' should be '12'
            const formattedHour = String(hour).padStart(2, '0');

            // Format as "YYYY-MM-DD h:mm:ss A"
            return `${year}-${month}-${day} ${formattedHour}:${minute}:${second} ${ampm}`;
        };

        return (
            <span>
                {formatDateTime(created_date_props.data["CreatedAt"])}
            </span>
        )
    }

    // Declaring the api - grid connections
    const [columnDefs, setColumnDefs] = useState(
        [
            {
                field: "TemplateName",
                headerName: "Template Name",
            },
            {
                field: "Status",
                headerName: "Status",
                cellRenderer: StatusRenderer,
                maxWidth: 150,
                width: 150
            },
            {
                headerName: "Created Date",
                field: "CreatedAt",
                cellRenderer: CreatedDateRenderer,
                maxWidth: 150,
                width: 150,
                sortable: false,
            },
            // {
            //     field: "",
            //     headerName: "Records",
            //     cellRenderer: RecordsRenderer,
            //     maxWidth: 150,
            //     width: 150
            // },
            {
                field: "",
                headerName: "Actions",
                cellRenderer: ActionsOnWizardFormRenderer,
                maxWidth: 100,
                width: 100,
                pinned: "right"
            }
        ]);

    const CustomLoadingOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center"
                style={{ height: '9%' }}
            >
                <Row className="dashboard-row">
                    <div className="spinner">
                        <Bars
                            height="80"
                            width="80"
                            color="var(--theme1_primary)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                </Row>
                {props.loadingMessage}
            </div>
        );
    };

    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const loadingOverlayComponent = useMemo(() => {
        return CustomLoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: "Loading your forms",
        };
    }, []);
    const noRowsOverlayComponent = useMemo(() => {
        return CustomNoRowsOverlay;
    }, []);
    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => !my_wizard_forms_grid_props.getApiFlag ? 'No data available' : "Loading your forms",
        };
    }, [my_wizard_forms_grid_props.getApiFlag]);

    return (
        <div className="grid-container">
            <div className="ag-theme-balham" style={gridStyle}>
                <AgGridReact
                    key={my_wizard_forms_grid_props.getApiFlag}
                    ref={gridRef}
                    rowData={my_wizard_forms_grid_props.rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={my_wizard_forms_grid_props.onGridReady}
                    alwaysShowHorizontalScroll={false}
                    alwaysShowVerticalScroll={false}
                    rowHeight={50}
                    suppressContextMenu={true}
                    onFirstDataRendered={onFirstDataRendered}
                    noRowsOverlayComponent={noRowsOverlayComponent}
                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                />
            </div>
        </div>
    );
};

const MyWizardForms = (props) => {
    console.log("MY WIZARD FORM PROPS: ", props)
    // Setting page url for helper function
    useEffect(() => {
        setToLS("url", ["myforms"])
    }, [])
    const navigate = useNavigate()
    const [dataSource, setDataSource] = useState([]);
    const [myWizardFormsRowData, setMyWizardFormsRowData] = useState(dataSource)
    const setData = (data) => {
        setDataSource(data['data'])
        setMyWizardFormsRowData(data['data'])
    }
    let myFormsGridApi;
    const onMyWizardFormsGridReady = useCallback((params) => {
        myFormsGridApi = params.api;
        myFormsGridApi.closeToolPanel();
    }, []);
    const [previewFullWindowModalShow, setPreviewFullWindowModalShow] = useState(false)
    const [recordsFullWindowModalShow, setRecordsFullWindowModalShow] = useState(false)
    const [previewComponents, setPreviewComponents] = useState([{}])
    const [wizardFormTitle, setWizardFormTitle] = useState("")
    const [wizardFormDescription, setWizardFormDescription] = useState("")
    const [wizardFormUrl, setWizardFormUrl] = useState("")
    const [recordsModalData, setRecordsModalData] = useState([])
    const usertype = localStorage.getItem("usertype")
    const [getApiFlag, setGetApiFlag] = useState(false)

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const getAllWizardForms = useCallback((params) => {
        setGetApiFlag(true);
        
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/wizard_pages/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setData(data)
            })
            .finally(() => {
                setGetApiFlag(false);
            });
    }, []);

    useEffect(() => {
        // if (usertype === "SuperAdmin") {
        getAllWizardForms()
        // } else {
        // console.log("USER DOES NOT HAVE PERMISSION TO ACCESS THIS PAGE!")
        // }
    }, [])


    return (
        <>
            <PreviewFullWindowModal
                show={previewFullWindowModalShow}
                onHide={() => setPreviewFullWindowModalShow(false)}
                previewcomponents={previewComponents}
                wizardformtitle={wizardFormTitle}
                wizardformurl={wizardFormUrl}
            />
            <RecordsFullWindowModal
                show={recordsFullWindowModalShow}
                onHide={() => setRecordsFullWindowModalShow(false)}
                recordsmodaldata={recordsModalData}
                wizardformtitle={wizardFormTitle}
                wizardformdescription={wizardFormDescription}
                wizardformurl={wizardFormUrl}
            />
            <Row className="no-gutter">                
                <p className="display-6" style={{ height: "fit-content", padding: 0, marginBottom: 0  }}>Wizard Page Templates</p>
            </Row>                
                
            <Row className="no-gutter">         
                <Col md={12}>
                    <div className="navigator" style={{ margin: "-2.15rem 20px 0.45rem 0px", width: "fit-content" , float: "right"  }}>
                        <Button className="custom-button" onClick={() => props.transporter("client_wizard_form_config", {
                            "wizard_config_option": {}
                        })}>Create form</Button>
                    </div>
                </Col>  
                <Col md="12" className="grid-row myforms-grid">
                    <MyWizardFormsGrid
                        rowData={myWizardFormsRowData}
                        onGridReady={onMyWizardFormsGridReady}
                        transporter={props.transporter}
                        myformsoption={props.myformsoption}
                        setpreviewcomponents={(previewcomponents) => setPreviewComponents(previewcomponents)}
                        setwizardformtitle={(wizardformtitle) => setWizardFormTitle(wizardformtitle)}
                        setwizardformdescription={(description) => setWizardFormDescription(description)}
                        setwizardformurl={(url) => setWizardFormUrl(url)}
                        setpreviewfullwindowmodal={(flag) => setPreviewFullWindowModalShow(flag)}
                        setrecordsmodaldata={(data) => setRecordsModalData(data)}
                        setrecordsfullwindowmodal={(flag) => setRecordsFullWindowModalShow(flag)}
                        getApiFlag={getApiFlag}
                    />
                </Col>
            </Row >
        </>
    )
}

export default MyWizardForms;