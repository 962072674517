import React, { useContext, forwardRef } from 'react'
import { FormContext } from '../FormContext';
import { Col, Image } from "react-bootstrap";
import "./customelements.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Icon from "react-bootstrap-icons";
import moment from 'moment/moment';

const DateTime = ({ id, source, label, placeholder, value, disabled, example, mandatory, hidden, md, margins }) => {
  const { handleChange } = useContext(FormContext)
  const marginTop = margins["top"] !== undefined && margins["top"] !== null ? margins["top"] : 5
  const marginRight = margins["right"] !== undefined && margins["right"] !== null ? margins["right"] : 5
  const marginBottom = margins["bottom"] !== undefined && margins["bottom"] !== null ? margins["bottom"] : 5
  const marginLeft = margins["left"] !== undefined && margins["left"] !== null ? margins["left"] : 5
  // console.log("VAL: ", [id, source, label, value])
  const ReactDateTime = () => {
    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
      <>
        <input
          value={value}
          placeholder={"Select date and time"}
          className="date-custom-input"
          onClick={onClick}
          onChange={onChange}
          ref={ref}
          disabled={disabled}
          required={mandatory}
          autoComplete="new-password"
        >
        </input>
        <Icon.Calendar2WeekFill className='calendar-icon-custom-input' />
      </>
    ));
    return (
      <DatePicker
        // showIcon={true}
        selected={value === undefined || value === null || value === "" ? new Date() : new Date(Date.parse(value))}
        placeholderText={"Select date and time"}
        onChange={event => handleChange(id, event)}
        customInput={<DateCustomInput />}
        dayClassName={() => "custom-datepicker-day-class"}
        popperClassName="custom-datepicker-class"
        // showTimeSelect={true}
        // timeFormat="HH:mm"
        // timeIntervals={15}
        // timeCaption="time"
        dateFormat="MMMM d, yyyy "
      />
    );
  };
  return (
    <>
      {
        hidden ? <></> :
          <Col md={md} className="pad-trl-10" style={hidden ? { display: "None", visibility: "hidden" } : { margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
            {/* <div className="mb-3"> */}
            {label !== null
              ?
              <label htmlFor="exampleInputEmail1" className="form-label move-left">
                {source
                  ?
                  <Image src={source} style={{ width: 15, height: 15, borderRadius: 5, marginRight: 5 }} />
                  :
                  ""}
                {label}
                {mandatory
                  ?
                  <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  : ""}</label>
              :
              <></>} <br></br>
            <ReactDateTime />
            {example !== null ? <div id="emailHelp" className="text-muted move-left input-example">Example: {example}</div> : <></>}
            {/* </div> */}
          </Col>
      }
    </>
  )
}

export default DateTime