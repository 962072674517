import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

:root {
    --theme1_primary: #263545 !important;
    --theme1_secondary: #192532 !important;
    --theme1_tertiary: #19cd91 !important;
    --theme1_scroll_track: #19cd918a !important;
    --theme1_scroll_background: #2635459a !important;
    --theme1_quarternary: #8c9298 !important;
    --theme1_text: #8c9298 !important;
    --theme1_background: #8c9298 !important;
    --rs-toggle-bg: #3c3f43 !important;
    --rs-toggle-disabled-bg: lightgray !important;
    --rs-toggle-checked-bg: #192532 !important;
    --small_font: 8px !important;
    --medium_font: 10px !important;
    --default: 12px !important;
    --h1: 50px !important;
    --h2: 40px !important;
    --h3: 30px !important;
    --h4: 24px !important;
    --h5: 18px !important;
    --h6: 14px !important;
    --h7: 12px !important;
    --small_image: 20px !important;
    --medium_image: 50px !important;
    --large_image: 100px !important;
  }
`;

// body {
//   background: ${({ mode }) => mode.colors.body} !important;
//   color: ${({ mode }) => mode.colors.text} !important;
// }
