import React from "react";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import { Row, Col, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { logout } from "../../../utils/index.js";
import { ThreeCircles, Bars } from "react-loader-spinner";
import * as Icon from "react-bootstrap-icons";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import './CreateTemplateModal.css';
import TemplatePreviewModal from '../TemplatePreviewModal/TemplatePreviewModal.js';
import DocumentSigningSetupModal from '../DocumentSigningSetupModal/DocumentSigningSetupModal.js';
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import RulesModal from '../RulesModal/RulesModal.js';
import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import "../../../../node_modules/@syncfusion/ej2-documenteditor/styles/material.css";
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import NamingModal from '../NamingModal/TemplateNamingModal.js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from "react-select";
import ContentBlockCRUDModel from '../ContentBlockCRUDModel/ContentBlockCRUDModel.js';

DocumentEditorContainerComponent.Inject(Toolbar);

const stringLimiter = (string) => {
    let limited_string;
    if (string.length > 20) {
        limited_string = string.substring(0, 20) + "..."
    } else {
        limited_string = string
    }
    return limited_string
}

const DraggableField = ({ handleBtnClick, label, value, type }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [target, setTarget] = useState(null);

    const getFieldPlaceholderText = (fieldType, name) => {
        switch (fieldType) {
          case 'Signature':
            return `«${value}:Signer${localStorage.getItem('CurrentRecipientId')}»`;
      
          case 'Selected Field':
            return `{{${value}}}`;
      
          case 'Rule':
            return `{${value}}`;
      
          case 'Content Block':
            return `[[${value}]]`;
      
          default:
            return `«${value}»`;
        }
    };      

    const handleCopyClick = (value, event) => {
        // Stop the click event from propagating to the parent
        event.stopPropagation();

        // Create a temporary textarea element
        const tempTextarea = document.createElement('textarea');
        tempTextarea.value = value;
        document.body.appendChild(tempTextarea);

        // Select the text in the textarea
        tempTextarea.select();
        tempTextarea.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text to the clipboard
        document.execCommand('copy');

        // Remove the temporary textarea
        document.body.removeChild(tempTextarea);
        // Show tooltip
        setTarget(event.target);
        setShowTooltip(true);

        // Hide tooltip after 3 seconds
        setTimeout(() => {
            setShowTooltip(false);
        }, 2000); // Adjust the duration as needed (2000ms = 2 seconds)
    };

    const handleDragStart = useCallback((e) => {
        // Create the drag image element
        const dragImage = document.createElement('div');
        dragImage.className = 'dropped-field';
        dragImage.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16" style="margin-left: 0.1rem;">
                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
            </svg>
            <span>${label}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg delete-button" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
            </svg>
        `;
        document.body.appendChild(dragImage);

        e.dataTransfer.setData('text/plain', getFieldPlaceholderText(type, label) + '\n');
        setTimeout(() => document.body.removeChild(dragImage), 0);
    }, [label]);

    return (
        <div draggable className="draggable-field"
            onDragStart={handleDragStart}
            onClick={() => {
                if (handleBtnClick) {
                    handleBtnClick(label);
                }
            }}
        >
            <div className="icon-wrapper">
                <Icon.GripVertical style={{ marginRight: 6 }} />
            </div>
            <div style={{ display: 'flex', flex: '1 1 auto' }}>
                {stringLimiter(label)}
            </div>
            <OverlayTrigger
                trigger={"click"}
                placement="top"
                show={showTooltip}
                overlay={
                    <Tooltip style={{ zIndex: 10001 }} id={`tooltip-${label}`}>
                        Copied
                    </Tooltip>
                }
            >
                <div className="icon-wrapper">
                    <Icon.ClipboardFill
                        onClick={(event) => handleCopyClick(getFieldPlaceholderText(type, label), event)}
                        style={{ cursor: 'pointer', color: "black", fontSize: 12 }}
                    />
                </div>
            </OverlayTrigger>
        </div>


    );
};

const CreateTemplateModal = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [refreshRules, setRefreshRules] = useState(false);
    const [nextStepStatus, setNextStepStatus] = useState(false);
    const [documentSigningSetupModalShow, setDocumentSigningSetupModalShow] = useState(false);
    const [addRulesModalShow, setAddRulesModalShow] = useState(false);
    const [templateFieldRulesRowData, setTemplateFieldRulesRowData] = useState([]);
    const [templateFieldRulesFilteredRowData, setTemplateFieldRulesFilteredRowData] = useState([]);
    const [templateFieldRulesList, setTemplateFieldRulesList] = useState([]);
    const [ruleName, setRuleName] = useState("");
    const [selectedField, setSelectedField] = useState(null);
    const [isEdit, setIsEdit] = useState(JSON.parse(localStorage.getItem('isEdit')));
    const [namingModalShow, setNamingModalShow] = useState(false);
    const [letterPdfFileUrl, setLetterPdfFileUrl] = useState("");
    const [defaultValue, setDefaultValue] = useState(null);
    const [container, setContainer] = useState(null);
    const [selectedFieldsList, setSelectedFieldsList] = useState([]);
    const [signatureFieldTypes, setSignatureFieldTypes] = useState({});
    const [signaturesList, setSignaturesList] = useState([]);
    const [editType, setEditType] = useState(localStorage.getItem('edit_type'));
    const saveTemplateButtonRef = useRef(null);
    const [selectedRecipient, setSelectedRecipient] = useState({ "label": "Signer1Email", "value": "Signer1Email" });
    const [recipientOptions, setRecipientOptions] = useState([
        { "label": "Signer1Email", "value": "Signer1Email" }
    ])
    const [isAddingFields, setIsAddingFields] = useState(false); // State to toggle between signature and input fields
    const [inputFields, setInputFields] = useState([]); // Store input fields
    const [contentBlocksData, setContentBlocksData] = useState([]);

    const [contentBlockModelShow, setContentBlockModelShow] = useState(false);
    const [contentBlockId, setContentBlockId] = useState();
    const [blockName, setBlockName] = useState("");
    const [blockContent, setBlockContent] = useState("");
    const [active, setActive] = useState("Y");
    
    // Fetch Content Blocks
    const fetchContentBlocksDetails = useCallback(async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/content_blocks/?Status=Active`, requestOptions);
            if (!response.ok) {
                if (response.status === 401) {
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }
            const data = await response.json();
            setContentBlocksData(data.data);
        } catch (error) {
            console.error("Error fetching content blocks:", error);
        }
    }, []);

    // Fetch Firm Template Field Details
    const fetchFirmTemplateFieldDetails = useCallback(async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/firm_template_fields/`, requestOptions);
            if (!response.ok) {
                if (response.status === 401) {
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }
            const data = await response.json();
            if (data.data.length > 0) {
                const details = data.data[0];
                setSignaturesList(JSON.parse(details.SignatureFields));
                setSelectedFieldsList(JSON.parse(details.SelectedFields));
            }
        } catch (error) {
            console.error("Error fetching firm template field details:", error);
        }
    }, []);

    // Fetch Template Field Rules Details
    const fetchTemplateFieldRulesDetails = useCallback(async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        const templateId = localStorage.getItem("template_id");

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/template_rule_details/?TemplateId=${templateId}`, requestOptions);
            if (!response.ok) {
                if (response.status === 401) {
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }
            const data = await response.json();

            const key = localStorage.getItem("key");

            const savedData = localStorage.getItem("FieldRulesRowData");
            const fieldRulesRowData = savedData ? JSON.parse(savedData)[key] || [] : [];

            const updatedRules = [...data.data, ...fieldRulesRowData];
            setTemplateFieldRulesRowData(updatedRules);

            // Create a list of unique RuleNames
            const uniqueRuleNames = updatedRules.reduce((acc, rule) => {
                if (!acc.includes(rule.RuleName)) {
                    acc.push(rule.RuleName);
                }
                return acc;
            }, []);

            setTemplateFieldRulesList(uniqueRuleNames);
        } catch (error) {
            console.error("Error fetching template field rules:", error);
        }
    }, []);

    useEffect(() => {
        // Retrieve signatureFields from localStorage
        const recipients = localStorage.getItem("recipients") !== "null" && localStorage.getItem("recipients")
            ? JSON.parse(localStorage.getItem("recipients"))
            : [];
        
        const defOptions = recipients.map(key => ({
            label: key.replace(/[{{}}]/g, ""),
            value: key.replace(/[{{}}]/g, ""),
        }));

        const tempRecipientOptions = defOptions.length > 0 ? defOptions : [
            { label: "Signer1Email", value: "Signer1Email" },
        ];

        setSelectedRecipient(defOptions[0] || selectedRecipient);
        setRecipientOptions(tempRecipientOptions);

        // Create input fields based on existing recipient options
        setInputFields(tempRecipientOptions.map((option) => ({
            id: Date.now() + Math.random(), // Unique ID
            value: option.label
        })));

        const fetchData = async () => {
            setRefreshGrid(true);

            try {
                await Promise.all([
                    fetchFirmTemplateFieldDetails(),
                    fetchTemplateFieldRulesDetails(),
                    fetchContentBlocksDetails(),
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }

            setRefreshGrid(false);
        };

        fetchData();

        return () => {
            localStorage.removeItem("FieldRulesRowData");

            // Also manually remove the container element
            const containerElem = document.getElementById("container_toolbar_break_listView");
            if (containerElem) {
                containerElem.remove();
            }
        };
    }, []);

    const handleClose = () => {
        props.onHide();
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        const data = e.dataTransfer.getData("text/plain");

        if (data) {
            if (container && container.documentEditor) {
                try {
                    // Get the mouse position relative to the document editor
                    const rect = container.documentEditor.element.getBoundingClientRect();
                    const offsetX = e.clientX - rect.left;
                    const offsetY = e.clientY - rect.top + 20;

                    // Convert these offsets to document coordinates
                    container.documentEditor.editor.selection.select({ x: offsetX, y: offsetY, extend: false });

                    // Insert the dragged field at the selected position
                    container.documentEditor.editor.insertText(data);
                } catch (error) {
                    console.error("Error handling drop:", error);
                }
            } else {
                console.warn("DocumentEditor or container is not available.");
            }
        } else {
            console.warn("No data available to insert.");
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (container) {
            convertDocToSFDT();

            const editorElement = document.getElementById("container");

            if (editorElement) {
                editorElement.addEventListener("dragover", handleDragOver);
                editorElement.addEventListener("drop", handleDrop);
            }

            // Hide revisions pane
            container.documentEditor.showRevisions = false;

            return () => {
                if (editorElement) {
                    editorElement.removeEventListener("dragover", handleDragOver);
                    editorElement.removeEventListener("drop", handleDrop);
                }
            };
        }
    }, [container]);

    const getLabelIndex = (label, recipientOptions) => {
        return recipientOptions.findIndex(option => option.label === label);
    };

    const onHandleRulesModalClose = (type, fieldRulesRowData, deletedRowData, ruleName, defaultValue) => {
        setAddRulesModalShow(false);

        if (type && fieldRulesRowData && type.toLowerCase() == "okay") {
            console.log([templateFieldRulesRowData, fieldRulesRowData])
            setRefreshRules(true);

            setTemplateFieldRulesRowData(prevRowData =>
                prevRowData.filter(row => !deletedRowData.includes(row))
            );

            setTemplateFieldRulesRowData(prevRowData => [...prevRowData, ...fieldRulesRowData]);

            setTemplateFieldRulesRowData(prevRowData =>
                prevRowData.map(row =>
                    row.RuleName === ruleName ? { ...row, DefaultValue: defaultValue } : row
                )
            );

            setTimeout(() => {
                setRefreshRules(false);
            }, 1000);
        }
    }

    useEffect(() => {
        if (templateFieldRulesRowData) {
            const filteredRuleList = [
                ...new Set(
                    templateFieldRulesRowData
                        .filter(row => row.Active !== 'N')
                        .map(row => row.RuleName)
                )
            ];

            console.log("updatedRuleList", filteredRuleList);
            setTemplateFieldRulesList(filteredRuleList);
        }
    }, [templateFieldRulesRowData]);


    useEffect(() => {
        localStorage.setItem('SelectedRecipient', selectedRecipient.label);

        localStorage.setItem('CurrentRecipientId', getLabelIndex(selectedRecipient.label, recipientOptions) + 1);
    }, [selectedRecipient])
    

    const handleRulesFieldClick = (ruleName) => {
        setRuleName(ruleName);
        const filteredData = templateFieldRulesRowData.filter(item => item.RuleName === ruleName && item.Active === 'Y');

        // Get the first SelectedField from the filteredData, if available
        const selectedField = filteredData.length > 0 ? filteredData[0].SelectedFieldLabel : null
        setSelectedField(selectedField);

        // Get the first DefaultValue from the filteredData, if available
        const defaultValue = filteredData.length > 0 ? filteredData[0].DefaultValue : null;
        setDefaultValue(defaultValue);

        console.log(ruleName)
        console.log(selectedField)
        console.log(defaultValue)
        console.log(templateFieldRulesRowData)
        console.log(filteredData)
        setTemplateFieldRulesFilteredRowData(filteredData);
        setAddRulesModalShow(true);
    }

    const saveTemplateFieldConfigDetails = async (NewTemplateDetailsId) => {
        console.log("NewTemplateDetailsId", NewTemplateDetailsId);
        const key = localStorage.getItem("key");

        // Initialize rowData from local storage
        const savedData = localStorage.getItem('FieldRulesRowData');

        // Parse the saved data and get the fieldRulesRowData array
        const fieldRulesRowData = (savedData ? JSON.parse(savedData) : [])[key];

        console.log(savedData);
        if (!fieldRulesRowData) {
            console.error('No field rules data found for the given key.');
            return true;
        }

        // Update the TemplateId for each item in fieldRulesRowData
        fieldRulesRowData.forEach(item => {
            item.TemplateId = NewTemplateDetailsId;
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/template_rule_details/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({ "FieldRulesConfigs": fieldRulesRowData })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended, please log in again.");
                } else {
                    throw new Error("Request failed.");
                }
            }

            const data = await response.json();

            if (data.success) {
                localStorage.setItem('FieldRulesRowData', JSON.stringify([]));
                return true;
            } else {
                toast.error(
                    `Failed to finalize template: ${data.message}`,
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-error",
                    }
                );
                return false;
            }
        } catch (error) {
            console.error('Error finalizing template:', error);
        }
    };


    // Function to convert the blob to base64 data
    const getBase64Data = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                try {
                    const base64Data = reader.result.split(',')[1]; // Remove the data URL prefix
                    resolve(base64Data);
                } catch (error) {
                    reject(error);
                }
            };
            reader.readAsDataURL(blob); // Start reading the file
        });
    };

    // Function to handle saving the template
    const handleSaveTemplate = async (originalFileName, base64Data, pdfBase64Data) => {
        try {
            const requestBody = {
                "FileName": localStorage.getItem("key") + ".docx",
                "OriginalFileName": originalFileName,
                "DocFileData": base64Data, // Base64 encoded doc file data
                "PdfFileData": pdfBase64Data, // Base64 encoded pdf file data,
                "Recipients": recipientOptions.map(entry => `{{${entry.label}}}`)
            };

            if (isEdit) {
                requestBody["TemplateId"] = localStorage.getItem('template_id');
            }

            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/templates/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    logout();
                    alert("Session ended, please log in again.");
                } else {
                    throw new Error("Request failed.");
                }
            }

            const data = await response.json();

            if (data.success) {
                localStorage.setItem('template_id', data.data.TemplateId);

                const status = await saveTemplateFieldConfigDetails(data.data.TemplateId);
                console.log(status);
                if (status) {
                    toast.success(
                        "Finalize template successful!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                }
            } else {
                toast.error(
                    `Failed to finalize template: ${data.message}`,
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-error",
                    }
                );
                throw new Error(data.message);
            }
        } catch (error) {
            console.error('Error saving template:', error);
            throw error;
        }
    };

    // Function to handle saving the processed template
    const handleSaveProcessedTemplate = async (base64Data, pdfBase64Data) => {
        try {
            const requestBody = {
                "ProcessedTemplateId": localStorage.getItem('processed_template_id'),
                "DocFileName": props.docFileName,
                "FileName": localStorage.getItem('letter_file_name'),
                "DocFileData": base64Data, // Base64 encoded doc file data
                "PdfFileData": pdfBase64Data, // Base64 encoded pdf file data,
                "Recipients": recipientOptions.map(entry => entry.label)
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/processed_templates/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    logout();
                    alert("Session ended, please log in again.");
                } else {
                    throw new Error("Request failed.");
                }
            }

            const data = await response.json();

            if (data.success) {
                toast.success(
                    "Finalize letter successful!",
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-success",
                    }
                );
            } else {
                toast.error(
                    `Failed to finalize letter: ${data.message}`,
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-error",
                    }
                );
                throw new Error(data.message);
            }
        } catch (error) {
            console.error('Error saving letter:', error);
            throw error;
        }
    };

    const handleDocToPdfConversion = (base64Data) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                "base64Data": base64Data
            };

            fetch(`${process.env.REACT_APP_CONVERSION_API_BASE_URI}/api/pdfconversionfunction`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            logout();
                            alert("Session ended, please log in again.");
                        }
                        throw new Error("Request failed.");
                    }
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
                    resolve(url);
                })
                .catch(error => {
                    console.error('Error converting Doc to PDF:', error);
                    toast.error(
                        "Failed to convert Doc to PDF.",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                    reject(error);
                });
        });
    };

    const handleSaveAndNextBtnClick = async (originalFileName) => {
        try {
            setNextStepStatus(true);

            const blob = await container.documentEditor.saveAsBlob('Docx');
            const base64Data = await getBase64Data(blob);
            // console.log("Base64 Data:", base64Data);

            const pdf_url = await handleDocToPdfConversion(base64Data);
            console.log("pdf url:", pdf_url);

            // Fetch the PDF from the URL and convert it to a blob
            const pdf_response = await fetch(pdf_url);
            const pdf_blob = await pdf_response.blob();

            // Convert the PDF blob to base64
            const pdfBase64Data = await getBase64Data(pdf_blob);
            // console.log("PDF Base64 Data:", pdfBase64Data);

            if(editType === "Template")
                await handleSaveTemplate(originalFileName, base64Data, pdfBase64Data);
            else
                await handleSaveProcessedTemplate(base64Data, pdfBase64Data);

            setLetterPdfFileUrl(pdf_url);

            setIsEdit(true);
            
            setTimeout(() => handleClose(), 2000);
                
            // setDocumentSigningSetupModalShow(true);
            setNextStepStatus(false);
        } catch (error) {
            console.error("Error saving template:", error);
            setNextStepStatus(false);
        }

    }

    // Calculate the position of the modal based on the button's position
    const buttonPosition = saveTemplateButtonRef.current
        ? saveTemplateButtonRef.current.getBoundingClientRect()
        : { top: 0, left: 0 };

    const convertDocToSFDT = async () => {
        console.log("editType", editType)
        const blobContainer = editType === "Template" ? "templatefiles" : "engagementletterdocfiles";

        const content = {
            fileUrl: `${process.env.REACT_APP_AZURE_BLOB_BASE_URI}/${blobContainer}/${props.docFileName}`
        };

        const baseUrl = `${process.env.REACT_APP_CONVERSION_API_BASE_URI}/api/SFDTConversionFunction`;

        try {
            const response = await fetch(baseUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(content)
            });

            if (response.ok) {
                const sfdtText = await response.text();
                // Open the SFDT text in Document Editor
                container.documentEditor.open(sfdtText);
            } else {
                console.error("Failed to convert document:", response.statusText);
            }
        } catch (error) {
            console.error("Error during conversion:", error);
        }
        finally {
            setRefreshGrid(false);
        }
    }

    const [contentBlocksOpen, setContentBlocksOpen] = useState(false);
    const [rulesOpen, setRulesOpen] = useState(true);
    const [selectionsOpen, setSelectionsOpen] = useState(true);
    const [signaturesOpen, setSignaturesOpen] = useState(false);

    function openContentBlocksNav() {
        setRulesOpen(false);
        setContentBlocksOpen(true);
    }

    function closeContentBlocksNav() {
        setContentBlocksOpen(false);
    }

    function openRulesNav() {
        setContentBlocksOpen(false);
        setRulesOpen(true);
    }

    function closeRulesNav() {
        setRulesOpen(false);
    }

    function openSelectionsNav() {
        setSignaturesOpen(false);
        setSelectionsOpen(true);
    }

    function closeSelectionsNav() {
        setSelectionsOpen(false);
    }

    function openSignatureNav() {
        setSelectionsOpen(false);
        setSignaturesOpen(true);
    }

    function closeSignatureNav() {
        setSignaturesOpen(false);
    }

    // Setting the context menu to default in the created event to ensure only the default menu items are shown, fixing the issue where all menu items were incorrectly displayed.
    function onCreate() {
        // creating Custom Options
        let menuItems = [];
        
        // adding Custom Options
        container.documentEditor.contextMenu.addCustomMenu(menuItems, false);
    }

    const handleAddField = () => {
        setIsAddingFields(true);
        
        // Get the last input field in the array
        const lastField = inputFields[inputFields.length - 1];
    
        // Only add a new field if the last field is not blank (has some value)
        if (lastField && lastField.value.trim() !== '') {
            setInputFields([...inputFields, { id: Date.now() + Math.random(), value: '' }]);
        } else {
            toast.error(
                `The last input field is empty. Please fill it before adding a new one.`,
                {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: "toastify-color-error",
                }
            );
            console.log("The last input field is empty. Please fill it before adding a new one.");
        }
    };    

    const handleFieldChange = (id, value) => {
        setInputFields(inputFields.map(field => field.id === id ? { ...field, value } : field));
    };

    const handleDeleteField = (id) => {
        if (inputFields.length > 1) {
            // Only allow deletion if there is more than one field
            setInputFields(inputFields.filter((field) => field.id !== id));
        }
    };

    const onDragEnd = (result) => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedFields = reorder(
            inputFields,
            result.source.index,
            result.destination.index
        );

        setInputFields(reorderedFields);
    };

    // Function to reorder the input fields array after drag
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleSaveFields = () => {
        // Filter out fields that are empty
        const nonEmptyFields = inputFields.filter((field) => field.value.trim() !== '');

        if (nonEmptyFields.length > 0) {
            // Save non-empty fields into recipientOptions for the Select dropdown
            const options = nonEmptyFields.map((field) => ({
                value: field.value,
                label: field.value,
            }));
            
            setRecipientOptions(options);
            
            const isRecipientValid = options.some(option => option.label === selectedRecipient.label);
            
            let updateRecipient = isRecipientValid ? selectedRecipient : null || options[0] || null;
            
            // Automatically select the first recipient
            setSelectedRecipient(updateRecipient);
        }

        // Close the add fields section
        handleCancel();
    };

    const handleCancel = () => {
        setIsAddingFields(false);
    };

    const handleRecipientChange = (e) => {
        setSelectedRecipient(e);
    }

    const handleContentBlockShow = (data) => {
        setContentBlockId(data.ContentBlockId);
        setBlockName(data.BlockName);
        setBlockContent(data.BlockContent);
        setActive(data.Active);
        setContentBlockModelShow(true);
    }

    const handleContentBlockHide = () => {
        setContentBlockModelShow(false);
    }

    return (
        <Modal
            {...props}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="custom-backdrop"
            centered
            id="create-template-modal"
            className="full-modal"
            scrollable="false"
        >
            {!isEdit && namingModalShow && (
                <div
                    style={{
                        position: 'absolute',
                        top: buttonPosition.bottom + window.scrollY,
                        left: buttonPosition.left + window.scrollX - 280
                    }}
                >
                    <NamingModal
                        finalizeTemplate={handleSaveAndNextBtnClick}
                        onHide={() => setNamingModalShow(false)}
                    />
                </div>
            )}
            <Modal.Header style={{ paddingBottom: 0 }}>
                <Row className="button-row flex-row-center-space">
                    <Col md={6} className="d-flex justify-content-start">
                        <p className="display-6" style={{ height: "fit-content", paddingLeft: "-3rem", marginBottom: 0, width: "fit-content" }}>
                            {localStorage.getItem('letter_file_name') == "EngagementLetter.pdf" ? "Create" : "Edit"} { editType === "Template" ? "Template" : "Engagement Letter"}
                        </p>
                    </Col>
                    <Col md={6} className="d-flex flex-row-center-end">
                        <div style={{ marginLeft: "1rem" }}>
                            <Button
                                ref={saveTemplateButtonRef}
                                onClick={() => isEdit ? handleSaveAndNextBtnClick(localStorage.getItem('doc_original_file_name')) : setNamingModalShow(true)}
                                className="custom-button"
                            >
                                {nextStepStatus ? (
                                    <>
                                        <div style={{ marginRight: 6 }}>
                                            <ThreeCircles
                                                height="15"
                                                width="15"
                                                color="#4fa94d"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel="three-circles-rotating"
                                                outerCircleColor="#4fa94d"
                                                innerCircleColor="#4fa94d"
                                                middleCircleColor="#4fa94d"
                                            />
                                        </div>
                                        <p style={{ marginBottom: 0, marginTop: 0 }}>
                                            Finalizing {editType === "EngagementLetter" ? "Letter" : "Template"}...
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p style={{ marginBottom: 0, marginTop: 0 }}>Finalize {editType === "EngagementLetter" ? "Letter" : "Template"}</p>
                                    </>
                                )}
                            </Button>
                        </div>
                        <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                            <Button
                                onClick={handleClose}
                                className="custom-button"
                            >
                                <p style={{ marginBottom: 0, marginTop: 0 }}>Close</p>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body className="preview-modal">
                <>
                    {!refreshGrid ? (
                        <>
                            {contentBlockModelShow && (
                                <ContentBlockCRUDModel
                                    mode={"view"}
                                    contentBlockId={contentBlockId}
                                    blockName={blockName}
                                    blockContent={blockContent}
                                    active={active}
                                    onHide={handleContentBlockHide}
                                />
                            )}
                            {addRulesModalShow && (
                                <RulesModal
                                    ruleName={ruleName}
                                    defaultValue={defaultValue}
                                    selectedField={selectedField}
                                    setTemplateFieldRulesRowData={setTemplateFieldRulesRowData}
                                    templateFieldRulesFilteredRowData={templateFieldRulesFilteredRowData}
                                    selectedFieldsList={selectedFieldsList}
                                    templateFieldRulesList={templateFieldRulesList}
                                    show={addRulesModalShow}
                                    onHide={onHandleRulesModalClose}
                                />
                            )}
                            {/* {documentSigningSetupModalShow && (
                                <DocumentSigningSetupModal
                                    isEdit={isEdit}
                                    signatureFieldTypes={signatureFieldTypes}
                                    docFileName={props.docFileName}
                                    letterPdfFileUrl={letterPdfFileUrl}
                                    show={documentSigningSetupModalShow}
                                    onBack={() => setDocumentSigningSetupModalShow(false)}
                                    onClose={() => {
                                        handleClose();
                                        // setDocumentSigningSetupModalShow(false);
                                    }}
                                />
                            )} */}
                            <Row className="template-editor no-gutter">
                                {editType === "Template" ?
                                    <div id="contentblocks" class="contentblocks" className={contentBlocksOpen ? "contentblocks fields-section-active" : "contentblocks"}>
                                        <div className="contentblocks-content">
                                            <div className="contentblocks-column">
                                            {contentBlocksData.map((block, index) => (
                                                <DraggableField handleBtnClick={() => handleContentBlockShow(block)} key={index} label={block.BlockName} value={block.BlockName} type={"Content Block"} />
                                            ))}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {editType === "Template" ?
                                    <div id="myrules" className={rulesOpen ? "myrules fields-section-active" : "myrules"}>
                                        <div className="flex-row-center-center myrules-add-rule">
                                            <h6 style={{ marginTop: 10, marginBottom: 5 }}>Add Rule</h6>
                                            <Icon.PlusCircleFill className="icon-add-field"
                                                onClick={() => handleRulesFieldClick("", null)}
                                            />
                                        </div>
                                        <div className="myrules-content-wrapper">
                                            <div className="myrules-content">
                                                {!refreshRules ? (
                                                        <>
                                                            {/* Render draggable field elements */}
                                                            <div className="rule-fields-column">
                                                                {templateFieldRulesList.map((name, index) => (
                                                                    <DraggableField handleBtnClick={handleRulesFieldClick} key={index} label={name} value={name} type={"Rule"}/>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )
                                                    : (
                                                        <div className="flex-row-center-center" style={{ marginTop: "20%" }}>
                                                            <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                                                                <Bars
                                                                    height="80"
                                                                    width="80"
                                                                    color="var(--theme1_primary)"
                                                                    ariaLabel="bars-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>  
                                    :
                                    null
                                }
                                <div id={"editor"}>
                                    {editType === "Template" ? 
                                        <div className={contentBlocksOpen ? "contentblocks-button-wrapper contentblocks-active" : "contentblocks-button-wrapper"}>
                                            <Button className="flex-column-center-center contentblocks-button" onClick={!contentBlocksOpen ? () => openContentBlocksNav() : () => closeContentBlocksNav()}>
                                                <Icon.BuildingFillGear style={{ color: "gray", fontSize: 16, marginBottom: 0}} />
                                                <p>
                                                    Content Blocks
                                                </p>
                                            </Button>
                                        </div>
                                        :
                                        null
                                    }
                                    {editType === "Template" ? 
                                        <div className={rulesOpen ? "myrules-button-wrapper rules-active" : "myrules-button-wrapper"}>
                                            <Button className="flex-column-center-center myrules-button" onClick={!rulesOpen ? () => openRulesNav() : () => closeRulesNav()}>
                                                <Icon.BuildingFillGear style={{ color: "gray", fontSize: 16, marginBottom: 10, marginTop: 5 }} />
                                                <p>
                                                    Rules
                                                </p>
                                            </Button>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className="editor">
                                        <DocumentEditorContainerComponent
                                            id="container"
                                            ref={(scope) => { setContainer(scope) }}
                                            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
                                            enableToolbar={true}
                                            enableTrackChanges={false}
                                            showPropertiesPane={false}
                                            showRevisions={false}
                                            created={onCreate}  />
                                    </div>
                                    {editType === "Template" ? 
                                        <div className={selectionsOpen ? "myselections-button-wrapper selections-active" : "myselections-button-wrapper"}>
                                            <Button className="flex-column-center-center myselections-button" onClick={!selectionsOpen ? () => openSelectionsNav() : () => closeSelectionsNav()}>
                                                <Icon.UiChecks style={{ color: "gray", fontSize: 16, marginBottom: 0 }} />
                                                <p>Selected Fields</p>
                                            </Button>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {editType === "Template" ?
                                    <div id="myselections" className={selectionsOpen ? "myselections fields-section-active" : "myselections"}>
                                        <div className="fields-column">
                                            {selectedFieldsList.map((obj, index) => (
                                                <DraggableField key={index} label={obj.label} value={obj.value} type={"Selected Field"}/>
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {editType === "Template" ?
                                    <>
                                        <div className={signaturesOpen ? "signatures-button-wrapper signatures-active" : "signatures-button-wrapper"}>
                                            <Button className="flex-column-center-center signatures-button" onClick={!signaturesOpen ? () => openSignatureNav() : () => closeSignatureNav()}>
                                                <Icon.UiChecks style={{ color: "gray", fontSize: 16, marginBottom: 0 }} />
                                                <p>Signatures Fields</p>
                                            </Button>
                                        </div>
                                        <div id="signatures" className={signaturesOpen ? "signatures fields-section-active" : "signatures"}>
                                            <div className="flex-row-center-start">
                                                <h6>Recipient(s)</h6>
                                                <Icon.PlusCircleFill className="icon-add-field"
                                                    onClick={handleAddField}
                                                />
                                            </div>
                                            {isAddingFields ? (
                                                // Render draggable input fields instead of signature fields
                                                <div className="recipient-input-fields-wrapper">
                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                        <Droppable droppableId="fields-column">
                                                            {(provided) => (
                                                                <div
                                                                    className="fields-column"
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {inputFields.map((field, index) => (
                                                                        <Draggable
                                                                            key={field.id}
                                                                            draggableId={String(field.id)}
                                                                            index={index}
                                                                        >
                                                                            {(provided) => (
                                                                                <div
                                                                                    className="draggable-field"
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <Icon.GripVertical className="drag-icon" />
                                                                                    <input
                                                                                        type="text"
                                                                                        value={field.value}
                                                                                        onChange={(e) => handleFieldChange(field.id, e.target.value)}
                                                                                        placeholder="Enter recipient"
                                                                                        className="form-control"
                                                                                    />
                                                                                    <Icon.XCircleFill
                                                                                        className="icon-delete-field"
                                                                                        onClick={() => handleDeleteField(field.id)}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                    {/* Ok and Cancel Buttons */}
                                                    <div className='flex-row-space-center button-group'>
                                                        <Button
                                                            onClick={handleSaveFields}
                                                            className="custom-button"
                                                        >
                                                            <span>Ok</span>
                                                        </Button>
                                                        <Button
                                                            onClick={handleCancel}
                                                            className="custom-button"
                                                        >
                                                            <span>Cancel</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div>
                                                        <Select
                                                            value={selectedRecipient}
                                                            onChange={handleRecipientChange}
                                                            options={recipientOptions}
                                                            className="form-select custom-select"
                                                            aria-label="Default"
                                                            closeMenuOnSelect={true}
                                                            autosize={true}
                                                            placeholder="Select recipient"
                                                            menuPosition="fixed"
                                                            classNamePrefix="react-select"
                                                        />
                                                    </div>
                                                    <div className='signatures-heading-subheading'>
                                                        <h6>Signature Fields</h6>
                                                        <p>Drag &amp; drop to place signature.</p>
                                                    </div>
                                                    <div className="signature-column">
                                                        {signaturesList.map((obj, index) => (
                                                            <DraggableField key={index} label={obj.label} value={obj.value} type={"Signature"} />
                                                        ))}
                                                    </div>
                                                </>
                                            )
                                        }
                                        </div>
                                    </>
                                    :
                                    null
                                }
                            </Row>
                        </>
                    ) : (
                        <div className="flex-row-center-center" style={{ marginTop: "20%" }}>
                            <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                                <Bars
                                    height="80"
                                    width="80"
                                    color="var(--theme1_primary)"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />

                                Loading your data
                            </div>
                        </div>
                    )}
                </>
                <ToastContainer />
            </Modal.Body>
        </Modal>
    );
};

export default CreateTemplateModal;