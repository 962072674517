import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    Button,
    Modal
} from "react-bootstrap";
import Select from 'react-select';
import * as Icon from "react-bootstrap-icons";
import { logout } from "../../utils";
import { toast } from "react-toastify";
import CustomButton from "../reusable-components/CustomButtonComponent/button";

export const ApprovalSettingsModal = (props) => {
    const [allUsers, setAllUsers] = useState([])
    const [allApprovers, setAllApprovers] = useState([{ "name": "", "email": "" }])
    const [allApprovalOptions, setAllApprovalOptions] = useState()
    const [approvalCheck, setApprovalCheck] = useState(false)
    const [formId, setFormId] = useState(props.formid)
    const [update, setUpdate] = useState(false)
    const [approvalSettingApiProgress, setApprovalSettingApiProgress] = useState(false)
    useEffect(() => {
        setFormId(props.formid)
    }, [props.formid])

    useEffect(() => {
        console.log("ALL APPROVERS: ", allApprovers)
    }, [allApprovers])


    useEffect(() => {
        const approvers = []
        const roles = ["SuperAdmin", "FirmAdmin", "Client Data Manager", "Partner", "User"]
        for (let user of allUsers) {
            if (roles.includes(user["Role"])) {
                approvers.push({ "label": user["UserName"], "value": user["Email"] })
            }
        }
        setAllApprovalOptions(approvers)
    }, [allUsers])


    useEffect(() => {
        let token = localStorage.getItem("token");
        const getRequestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        fetch(
            `${process.env.REACT_APP_BASE_URI}/workflow/subscriptions/roles/users/`,
            getRequestOptions
        )
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((result) => setAllUsers(result["data"]))
    }, [])

    useEffect(() => {
        let token = localStorage.getItem("token");
        const getRequestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        if (formId !== undefined) {
            fetch(
                `${process.env.REACT_APP_BASE_URI}/workflow/form/approvalsetting/${formId}`,
                getRequestOptions
            )
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((result) => {
                    if (result["data"].length > 0) {
                        console.log("result: ", JSON.parse(result["data"][0]["ApprovalConfiguration"]))
                        setAllApprovers(JSON.parse(result["data"][0]["ApprovalConfiguration"])["Approvers"])
                        setApprovalCheck(result["data"][0]["ApprovalNeeded"]==="True"?true:false)
                        setUpdate(true)
                    }
                }
                )
        }
    }, [formId])


    const addApprover = () => {
        const newApprovers = [...allApprovers]
        newApprovers.push({ "name": "", "email": "" })
        setAllApprovers(newApprovers)
    }

    const removeApprover = (index) => {
        const newApprovers = [...allApprovers]
        newApprovers.splice(index, 1)
        setAllApprovers(newApprovers)
    }

    const saveApprovalSetting = () => {
        setApprovalSettingApiProgress(true)
        let token = localStorage.getItem("token");
        if (!update) {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    "ApprovalConfiguration": allApprovers,
                    "ApprovalNeeded": approvalCheck
                }),
            };
            console.log("PAYLOAD: ", requestOptions["body"])
            fetch(
                `${process.env.REACT_APP_BASE_URI}/workflow/form/approvalsetting/${formId}`,
                requestOptions
            )
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setApprovalSettingApiProgress(false)
                        console.log("SAVED DATA: ", data.data)
                        props.onHide()
                    } else {
                        setApprovalSettingApiProgress(false)
                        toast.error(
                            "Error while fetching data: " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
                .catch((err) => console.log("Err: ", err));
        } else {
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    "ApprovalConfiguration": allApprovers,
                    "ApprovalNeeded": approvalCheck
                }),
            };
            console.log("PAYLOAD: ", requestOptions["body"])
            fetch(
                `${process.env.REACT_APP_BASE_URI}/workflow/form/approvalsetting/${formId}`,
                requestOptions
            )
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setApprovalSettingApiProgress(false)
                        console.log("SAVED DATA: ", data.data)
                        props.onHide()
                    } else {
                        setApprovalSettingApiProgress(false)
                        toast.error(
                            "Error while fetching data: " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
                .catch((err) => console.log("Err: ", err));
        }
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            scrollable="false"
            style={{
                maxHeight: 500,
                overflow: "auto",
                top: "20%"
            }}
        >
            <Modal.Header closeButton>
                <h5 style={{ marginBottom: 0, marginRight: 20, marginLeft: 10 }}>Approval Setting</h5>
            </Modal.Header>
            <Modal.Body className="flex-column-start-center">
                <Row>
                    <div className={"pivot-check-holder"}>
                        <label style={{ margin: "0px 5px" }}>Please select this if you want to skip approval process for this form.</label>
                        <input type="checkbox" className={"form-check-input move-left custom-check-input pivot-check"}
                            style={{ margin: "0px 0px" }}
                            checked={approvalCheck}
                            onChange={event => setApprovalCheck(event.target.checked)}
                            autoComplete="new-password"
                        />
                    </div>
                </Row>
                <br />
                {
                    !approvalCheck && allApprovers.length >= 1 ?
                        <>
                            <Row style={{ width: "100%", marginBottom: 5 }} className="flex-row-center-start">
                                <h5 style={{ marginBottom: 0, marginLeft: 10, width: "fit-content" }}>Approvers</h5>
                                {
                                    !approvalCheck ?
                                        <Icon.PlusCircleFill
                                            style={{
                                                color: "var(--theme1_primary)",
                                                fontSize: 20,
                                                width: "fit-content",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => addApprover()}
                                        /> :
                                        <></>
                                }
                            </Row>
                            {
                                allApprovers.map((approver, index) =>
                                    <Row className="flex-row-center-center"
                                        style={{
                                            margin: "10px 0px 10px 0px",
                                            width: "100%"
                                        }}>
                                        <Col md={3}><h6 style={{ marginBottom: 0 }}>Approver {index + 1} </h6></Col>
                                        <Col md={8}>
                                            <Select
                                                value={{ "label": approver["name"], "value": approver["email"] }}
                                                onChange={(e) => {
                                                    const apps = [...allApprovers]
                                                    apps[index]["name"] = e.label
                                                    apps[index]["email"] = e.value
                                                    setAllApprovers(apps)
                                                }}
                                                options={allApprovalOptions}
                                                className="form-select custom-select"
                                                aria-label="Default"
                                                closeMenuOnSelect={true}
                                                autosize={true}
                                                placeholder="Select approver"
                                                menuPosition="fixed"
                                                classNamePrefix="react-select"
                                            /></Col>
                                        <Col md={1}>
                                            {
                                                allApprovers.length > 1 ?
                                                    <Icon.DashCircleFill
                                                        style={{
                                                            color: "var(--theme1_primary)",
                                                            fontSize: 20,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => removeApprover(index)} />
                                                    :
                                                    <></>
                                            }
                                        </Col>
                                    </Row>
                                )
                            }
                        </>
                        :
                        <></>
                }
            </Modal.Body>
            <Modal.Footer className="action-footer">
                <CustomButton
                    progress={approvalSettingApiProgress}
                    onclick={() => saveApprovalSetting()}
                    content={"Save"}
                />
            </Modal.Footer>
        </Modal>
    )
}