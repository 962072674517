import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const gridParamsMapper = (result) => {
    if (result.length != 0) {
      const keys = Object.keys(result[0]) // You need to change this based on API response format in every report
      let jsonColDefs = keys.map(key => {
        return {
          field: key, headerName: GridColumnTitleRenderer(key)
        //   , cellRenderer: CustomCellRenderer,
        //   cellRendererParams: {
        //     modalstate: setModalState
        // }
        }
      });
      return jsonColDefs;
    }
    // setRowData(result);
  }
  const GridColumnTitleRenderer = (defaultName) => {
    const splitWords = defaultName.split("_")
    for (let word in splitWords) {
      splitWords[word] = splitWords[word].charAt(0).toUpperCase() + splitWords[word].slice(1)
    }
    return splitWords.join(" ")
  }

export const ActionGrid = (props) => {
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%" }),
    []
    );
    const gridRef = useRef();
    const onFirstDataRendered = useCallback((params) => {
        // gridRef.current.api.sizeColumnsToFit();
        if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.sizeColumnsToFit();
        }
    }, []);
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );
    const rowData = useMemo(() => {
      return props.data.reduce((acc, office) => {
        const officeRow = { office: office.office, clientName: undefined, clientType: undefined, city: undefined, zip: undefined };
        const clientRows = office.clients.map(client => ({
          office: undefined,
          clientName: client.clientName,
          clientType: client.clientType,
          city: client.city,
          zip: client.zip
        }));
        if (office.clients.length > 0) {
          acc.push(officeRow, ...clientRows);
        }
        return acc;
      }, []);
    }, [props.data]);
    

  // AG Grid column definitions
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Office',
      field: 'office'
    },
    {
      headerName: 'Client Name',
      field: 'clientName'
    },
    {
      headerName: 'Client Type',
      field: 'clientType'
    },
    {
      headerName: 'City',
      field: 'city'
    },
    {
      headerName: 'ZipCode',
      field: 'zip'
    }
  ]);

  return (
    <div className="ag-theme-balham" style={gridStyle}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowHeight={25}
        // autoGroupColumnDef={{ minWidth: 250 }}
        // groupDefaultExpanded={1}
        // suppressAggFuncInHeader={true}
        // animateRows={true}
        onGridReady={props.onGridReady}
        alwaysShowHorizontalScroll={true}
        alwaysShowVerticalScroll={true}
        sideBar={"filters"}     
        rowSelection={'multiple'}
        rowMultiSelectWithClick={true}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
};