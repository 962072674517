import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    Button
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import Select, { components } from "react-select";
import "./clientonboarding.css";
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import initialData from './initial-data';
import { Column } from "./column";
import { toast } from "react-toastify";
import { getFromLS } from "../../utils/storage";
import { logout } from "../../utils";
import differenceBy from 'lodash/differenceBy';
import { Bars } from "react-loader-spinner";
import CustomButton from "../reusable-components/CustomButtonComponent/button";

const Container = styled.div`
  display: flex;
`;

const applogo = {
    "source_fields": require("../../pages/vendorimages/undefined.png")
}

const ClientDataManagementConfig = (props) => {
    console.log("CLIENT DATA MANAGEMENT CONFIG PROPS: ", props)
    const [allFieldsData, setAllFieldsData] = useState({})
    const [selectedFieldsData, setSelectedFieldsData] = useState({})
    const [targetFields, setTargetFields] = useState({})
    const [updateSelection, setUpdateSelection] = useState()
    const [filterOptions, setFilterOptions] = useState([
        { "label": "Client Information", "value": "Client information" },
        { "label": "Billing Information", "value": "Billing information" },
        { "label": "Tax Information", "value": "Tax information" },
        { "label": "Partner Information", "value": "Partner information" },
        { "label": "Marketing Information", "value": "Marketing information" },
        { "label": "Accounting & Auditing", "value": "Accounting & Auditing" },
    ])
    const [selectedFilter, setSelectedFilter] = useState(filterOptions[0])
    const [fieldsMap, setFieldsMap] = useState({})
    const [selectedType, setSelectedType] = useState({
        "label": "Individual",
        "value": "Individual",
        "default_fields": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    })
    const [fieldsToShow, setFieldsToShow] = useState([{}])
    const [state, setState] = useState(initialData)
    const [updateFieldsApiProgress, setUpdateFieldsApiProgress] = useState(false)

    const FetchAllFieldsData = () => {
        const token = getFromLS("token")
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/onboarding/all_available_fields/`, getOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    setAllFieldsData(data.data)
                } else {
                    toast.error(
                        "Saving failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    const FetchSelectedFieldsData = () => {
        const token = getFromLS("token")
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/onboarding/firm_selected_fields/`, getOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    setSelectedFieldsData(data.data)
                    if (data.data.length > 0) {
                        setUpdateSelection(true)
                    } else {
                        setUpdateSelection(false)
                    }
                } else {
                    toast.error(
                        "Saving failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    const UpdateSelectedFields = (targetfields) => {
        setUpdateFieldsApiProgress(true)
        const token = getFromLS("token")
        const fieldIds = []
        for (let [category, fields] of Object.entries(targetfields)) {
            for (let field of fields) {
                fieldIds.push(field.id)
            }
        }
        const payload = {
            "FieldIds": fieldIds,
            "Update": updateSelection,
            "ClientType": selectedType.value
        }
        // console.log("PAYLOAD: ", payload)
        // navigate("/client-onboarding-form", { state: { targetFields: targetFields } })
        const postOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/onboarding/firm_selected_fields/`, postOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    setUpdateFieldsApiProgress(false)
                    // console.log(data.data)
                    // setAllFieldsData(data.data)
                    // navigate("/client-field-mapping", { state: { targetFields: targetFields } })
                    props.transporter("field_mapping", {
                        "mapping_option": { targetFields: targetFields }
                    })
                } else {
                    setUpdateFieldsApiProgress(false)
                    toast.error(
                        "Saving failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    const UpdateTargetFields = (e) => {
        setSelectedType(e)
        if (selectedFieldsData === undefined || selectedFieldsData === null) {
            const allFields = []
            for (let field of allFieldsData) {
                const updatedField = Object.assign(field, { "sourceimg": applogo["source_fields"], "selected": false })
                allFields.push(updatedField)
            }
            const toRemove = e.default_fields
            const filtered = allFields.filter(function (obj) {
                // return true if the obj.id is not in the toRemove array
                return !toRemove.includes(parseInt(obj.id));
            });
            const groupedFields = groupBy(filtered, "category")
            // console.log("groupedFields: ", groupedFields)
            setFieldsMap(groupedFields)
            // setting the target mapper
            const targetMap = {}
            for (let key of Object.keys(groupedFields)) {
                targetMap[key] = []
            }
            const target = differenceBy(allFields, filtered, 'id')
            for (let field of target) {
                Object.assign(field, { "selected": true })
                targetMap[field.category].push(field)
            }
            setTargetFields(targetMap)
        } else {

        }
    }

    const removeFromTarget = (field) => {
        const newTargetFields = Object.assign({}, targetFields)
        const index = newTargetFields[field.category].map(e => e.id).indexOf(field.id)
        const removedField = newTargetFields[field.category].splice(index, 1)
        const newSourceFields = Object.assign(fieldsMap, {})
        Object.assign(removedField[0], { "selected": false })
        newSourceFields[field.category].push(removedField[0])
        const newFieldsMap = Object.assign(fieldsMap, newSourceFields)
        setFieldsMap(newFieldsMap)
        setTargetFields(newTargetFields)
    }

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
    };

    useEffect(() => {
        setState(initialData)
    }, [])

    useEffect(() => {
        // console.log("FIEL DSDATA: ", allFieldsData)
        if (Object.keys(allFieldsData).length > 0) {
            if (Object.keys(selectedFieldsData).length > 0) {
                console.log("SELECTED FIELD IDS: ", selectedFieldsData)
                const allFields = []
                for (let field of allFieldsData) {
                    const updatedField = Object.assign(field, { "sourceimg": applogo["source_fields"], "selected": false })
                    allFields.push(updatedField)
                }
                const clientType = selectedType.value
                let toRemove = selectedType.default_fields
                const filteredFieldData = selectedFieldsData.filter(
                    obj => Object.keys(obj).some(key => obj[key] === clientType)
                )
                if (filteredFieldData.length > 0) {
                    toRemove = filteredFieldData[0]["FieldIds"].split(', ').map(Number)
                }
                const filtered = allFields.filter(function (obj) {
                    // return true if the obj.id is not in the toRemove array
                    return !toRemove.includes(parseInt(obj.id));
                });
                const groupedFields = groupBy(filtered, "category")
                // console.log("groupedFields: ", groupedFields)
                setFieldsMap(groupedFields)
                // setting the target mapper
                const targetMap = {}
                for (let key of Object.keys(groupedFields)) {
                    targetMap[key] = []
                }
                const target = differenceBy(allFields, filtered, 'id')
                for (let field of target) {
                    Object.assign(field, { "selected": true })
                    targetMap[field.category].push(field)
                }
                setTargetFields(targetMap)
            } else {
                const allFields = []
                for (let field of allFieldsData) {
                    const updatedField = Object.assign(field, { "sourceimg": applogo["source_fields"], "selected": false })
                    allFields.push(updatedField)
                }
                const toRemove = selectedType.default_fields  //Remove default selected fields from fieldsource
                const filtered = allFields.filter(function (obj) {
                    // return true if the obj.id is not in the toRemove array
                    return !toRemove.includes(parseInt(obj.id));
                });
                const groupedFields = groupBy(filtered, "category")
                // console.log("groupedFields: ", groupedFields)
                setFieldsMap(groupedFields)
                // setting the target mapper
                const targetMap = {}
                for (let key of Object.keys(groupedFields)) {
                    targetMap[key] = []
                }
                const target = differenceBy(allFields, filtered, 'id')
                for (let field of target) {
                    Object.assign(field, { "selected": true })
                    targetMap[field.category].push(field)
                }
                setTargetFields(targetMap)
            }
        }
    }, [allFieldsData, selectedFieldsData, selectedType])

    useEffect(() => {
        // console.log("FIELDSMAP: ", fieldsMap)
        if (Object.keys(fieldsMap).length > 0) {
            // console.log("SELECTED FILTER: ", selectedFilter)
            // console.log("FIELDS TO SHOW: ", fieldsMap[selectedFilter.value])
            setFieldsToShow(fieldsMap[selectedFilter.value])
        }
    }, [selectedFilter, fieldsMap])

    useEffect(() => {
        FetchAllFieldsData()
        FetchSelectedFieldsData()
    }, [])

    const transporter = (pill) => {
        props.transporter(pill);
    };

    const onDragEnd = (result) => {
        // console.log("RESULT ON DRAG END: ", result)
        const { destination, source, draggableId } = result;
        // console.log("DESTINATION: ", destination)
        // console.log("SOURCE: ", source)
        // console.log("DRAGGABLEID: ", draggableId)
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const start = state.columns[source.droppableId];
        // console.log("START: ", start)
        const finish = state.columns[destination.droppableId];
        // console.log("FINISH: ", finish)

        if (start === finish) {
            return;
        }
        else {
            // Moving from one list to another
            const sourceFields = Object.assign(fieldsMap, {})[selectedFilter.value];
            const splicedField = sourceFields.splice(source.index, 1);
            Object.assign(splicedField[0], 1)
            // console.log("SPLICED FIELD: ", splicedField)

            Object.assign(fieldsMap, { [selectedFilter.value]: sourceFields })

            const destinationFields = Object.assign(targetFields, {});
            const fieldToBeAddedInTarget = splicedField[0]
            Object.assign(fieldToBeAddedInTarget, { "selected": true })
            destinationFields[selectedFilter.value].splice(destination.index, 0, splicedField[0]);
            setTargetFields(destinationFields)

            const newFieldsMap = {
                ...fieldsMap,
                [selectedFilter.value]: sourceFields
            }
            setFieldsMap(newFieldsMap);
        }
    };

    return (
        <>
            <Row className='no-gutter flex-row-space-center'>
                <p className="display-6" style={{ height: "fit-content", width: "fit-content", marginBottom: 0 }}>Client Data Management Configuration</p>
                {/* <div className="navigator" style={{ marginBottom: "0rem", width: "fit-content", float: "right" }}>
                    <Button className="nav-direction" onClick={() => transporter("my_forms")}>My forms <Icon.CaretRightFill /></Button>
                </div> */}
            </Row>
            <Row className="no-gutter flex-row-center-center" style={{ margin: "0px 0px 10px 0px" }}>
                <Col md={11}>
                    <div className="form-select-wrapper" style={{ width: 300, marginLeft: 20, marginRight: 10, fontSize: 14, float: "right" }}>
                        <p style={{ width: 150, fontWeight: 600, marginBottom: 0 }}>Client type:</p>
                        <Select
                            value={selectedType}
                            onChange={(event) => UpdateTargetFields(event)}
                            options={[
                                {
                                    "label": "Individual",
                                    "value": "Individual",
                                    "default_fields": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                                },
                                {
                                    "label": "Entity",
                                    "value": "Entity",
                                    "default_fields": [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]
                                }
                            ]}
                            className="form-select custom-select"
                            aria-label="Default"
                            closeMenuOnSelect={true}
                            autosize={true}
                            placeholder="Select type of client"
                            menuPosition="fixed"
                            classNamePrefix="react-select"
                        />
                    </div>
                </Col>
                <Col md={1}>
                    <CustomButton
                        progress={updateFieldsApiProgress}
                        onclick={() => UpdateSelectedFields(targetFields)}
                        content={"Save"}
                    />
                </Col>
            </Row>
            <Row className="no-gutter flex-row-center-center">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Col md={6} className="flex-column-center-center config-left">
                        <Row className="no-gutter" style={{ width: "100%" }}>
                            <div className="config-select filter-select-container">
                                <h5 style={{ marginBottom: "0px" }}>All available fields </h5>
                                <div className={"filter-select-wrapper"} style={{ width: 300 }}>
                                    <p style={{ marginBottom: 0, width: 75, fontWeight: 500 }}>Filter by: </p>
                                    <Select
                                        value={selectedFilter}
                                        onChange={(e) => setSelectedFilter(e)}
                                        options={filterOptions}
                                        className="form-select custom-select"
                                        aria-label="Default"
                                        closeMenuOnSelect={true}
                                        autosize={true}
                                        placeholder="Filter by form"
                                        menuPosition="fixed"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                            </div>
                            <div className="draggable-container-source">
                                <Column key={"source-column"} column={state.columns["source-column"]} fields={fieldsToShow} />
                            </div>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <div className="config-select" style={{ height: 58, alignItems: "center" }}>
                            <h5 style={{ marginBottom: "0px" }}> Selected fields </h5>
                        </div>
                        <div className="draggable-container-target" >
                            <Column
                                key={"target-column"}
                                column={state.columns["target-column"]}
                                fields={targetFields}
                                onremove={(field) => removeFromTarget(field)}
                                style={Object.keys(selectedFieldsData).length > 0 ? {} : { cursor: "not-allowed" }}
                            />
                        </div>
                    </Col>
                </DragDropContext>
            </Row>
        </>
    )
}

export default ClientDataManagementConfig;