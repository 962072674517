import React from "react";
import { useRef, useState, useEffect, useContext } from "react";
import { useAuth } from "../context/AuthProvider.js";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { parseJwt } from "../utils/index.js";
import { getFromLS, setToLS } from "../utils/storage";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import './login.css';

function NewLogin() {
  let navigate = useNavigate();

  const auth = useAuth();
  const [username, setUser] = useState("");
  const [password, setPwd] = useState("");
  let signin = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_BASE_URI}/workflow/authenticate/`;

    const hashedPassword = CryptoJS.SHA256(password).toString();
    

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        Username: username,
        Password: hashedPassword,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("LOGIN DATA: ", data)
        if (data.success === false) {
          toast.error(data.message, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "toastify-color-error",
          })
          return;
        }
        localStorage.setItem("token", data.Token);
        let firmId = parseJwt(data.Token).FirmId;
        localStorage.setItem("firmId", firmId);
        localStorage.setItem("username", data.Username);
        localStorage.setItem("usertype", data.UserType);
        localStorage.setItem("firmname", data.FirmName);
        localStorage.setItem("sendto", data.SendTo);
        localStorage.setItem("defaultActiveKey", "dashboard")

        const payload2 = { Username: username };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload2),
        };

        fetch(
          `${process.env.REACT_APP_BASE_URI}/workflow/subscriptions/features/`,
          requestOptions
        )
          .then(result => result.json())
          .then(result => {
            setToLS("active-features", result.data.FeatureCode);
            setTimeout(() => {
              if (getFromLS('active-features').length > 0) {
                const transfer = localStorage.getItem("transfer")
                if (transfer !== undefined && transfer !== null) {
                  navigate(`/${transfer}`)
                } else {
                  navigate("/dashboard", { replace: true })
                }
              }
            }, 2000);
          })
          .catch(error => console.error("Error fetching features:", error));



      }

      )
      .catch((error) => console.error("Error:", error))
  };

  var [i, j, k] = [0, 0, 0];

  var txt = `CYGNUS\nCLIENT DATA\nMANAGEMENT TOOL\n`;
  var txt2 = `Integrations made easy \n`

  var txt3 = `For best experience please use Chrome, Firefox or Edge browser.`
  var speed = 100;
  var speed2 = 200;
  var speed3 = 200;

  const typeWriter = () => {
    if (window.location.pathname === "/login" && i < txt.length) {
      let text = txt.charAt(i);
      document.getElementById("text1").innerHTML += text === "\n" ? "<br/>" : text;
      i++;
      setTimeout(typeWriter, speed);
    }
    if (window.location.pathname === "/login" && i === txt.length && j < txt2.length) {
      let text2 = txt2.charAt(j);
      document.getElementById("text2").innerHTML += text2 === "\n" ? "<br/>" : text2;
      j++;
      setTimeout(typeWriter, speed2);
    }
    if (window.location.pathname === "/login" && j === txt2.length && k < txt3.length) {
      let text3 = txt3.charAt(k);
      document.getElementById("text3").innerHTML += text3 === "\n" ? "<br/>" : text3;
      k++;
      setTimeout(typeWriter, speed3);
    }
  }
  useEffect(() => {
    if (window.location.pathname === "/login") {
      typeWriter();
    }
  }, [window.location.pathname])

  return (
    <Row className="no-gutter" style={{ background: "var(--theme1_primary)", height: "100vh" }}>
      {/* <div class="coins-container">
        <div class="coin">$</div>
        <div class="coin">$</div>
        <div class="coin">$</div>
        <div class="coin">$</div>
        <div class="coin">$</div>
        <div class="coin">$</div>
        <div class="coin">$</div>
        <div class="coin">$</div>
        <div class="coin">$</div>
      </div> */}
      <div class="icons-container">
        <Icon.FileEarmarkText className={"icon"} />
        <Icon.Calculator className={"icon"} />
        <Icon.GraphUpArrow className={"icon"} />
        <Icon.Coin className={"icon"} />
        <Icon.FileEarmarkSpreadsheet className={"icon"} />
      </div>

      {/* <div class="icons-container">
        <Icon.FileEarmarkBarGraph className={"icon"} />
      </div> */}
      {/* <div class="ripple-container">
        <div class="ripple" style={{left: "50%", top: "50%"}}></div>
        <div class="ripple" style={{left: "40%", top: "60%", animationDelay: "1s"}}></div>
        <div class="ripple" style={{left: "60%", top: "40%", animationDelay: "2s"}}></div>
      </div> */}
      {/* <div class="file">
        <div class="ripple" style={{ left: "50%", top: "50%" }}><h3 style={{color: "white"}}>Powered by AI</h3></div>
        <div class="ripple" style={{ left: "40%", top: "60%", animationDelay: "1s" }}></div>
        <div class="ripple" style={{ left: "60%", top: "40%", animationDelay: "2s" }}></div>
      </div> */}
      <div className="login-left-container">
        {/* <LoginCarousel /> */}
        <span id='text1'></span>
        <span id='text2'></span>
        <span id='text3'></span>
        {/* <h1 style={{ color: "#fff" }}>CYGNUS ACCOUNTING HUB</h1><br />
        <h5 style={{ color: "#fff" }}>Integrations made easy</h5> */}
      </div>
      <div className="login-form-container">
        <div className="flex-row-center-start go-login" style={{ marginBottom: 10 }}>
          <img className="login-dashboard-logo" src={require("../images/dashboard_logo.png")} />
          <h5 className="gradient-text">Cygnus Accounting Hub</h5>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <div className="flex-column-start-start" style={{ color: "var(--theme1_primary)" }}><div><h3>Welcome</h3></div><div><p>Please login to enter <Icon.DoorOpenFill /></p></div></div>
        </div>
        <Form onSubmit={ signin}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ fontSize: 8, fontWeight: 500, marginBottom: 0 }}>User name</Form.Label>
            <Form.Control
              type="text"
              className="custom-input"
              placeholder="username"
              name="user"
              value={username}
              onChange={(e) => setUser(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: 8, fontWeight: 500, marginBottom: 0 }}>Password</Form.Label>
            <Form.Control
              type="password"
              className="custom-input"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPwd(e.target.value)}
            />
          </Form.Group>

          <div className="flex-row-space-center" style={{ marginTop: 25 }}>
            <Button variant="primary" type="submit" className="custom-button">
              {"Sign In"}
            </Button>
          </div>
        </Form>
      </div>
    </Row>
  );
}

export default NewLogin;

