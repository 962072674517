import React, {
    useState,
    useEffect
} from "react";
import {
    Row
} from "react-bootstrap";
import { toast } from "react-toastify";
import { getFromLS } from "../../utils/storage";
import { logout } from "../../utils";
import FormLayout from "../OnboardingForms/layout";
import { useLocation } from "react-router-dom";

const ClientOnboardingFormConfig = (props) => {
    console.log("PROPSYYY: ", props)
    const [clientType, setClientType] = useState(props.configoption.clientType)
    const [targetFields, setTargetFields] = useState(props.configoption.targetFields)
    const [formId, setFormId] = useState(props.configoption.formId)
    const [createFormOption, setCreateFormOption] = useState(props.configoption.createform_option)
    return (
        <>
        {
            createFormOption !== undefined
            ?
            <Row className="no-gutter flex-row-center-center">
                {/* JUGAAD WITH FIELDS TO NOT SEND EMPTY */}
                <FormLayout fields={[]} createformoption={createFormOption} />
            </Row>
            :
            <Row className="no-gutter flex-row-center-center">
                <FormLayout clienttype={clientType} fields={targetFields} createformoption={{}} />
            </Row>
        }
        </>
    )
}
export default ClientOnboardingFormConfig