import React, {
    useRef,
    useCallback,
    useMemo,
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    Button,
    Image,
    Card
} from "react-bootstrap";
import { logout } from "../../utils";
import { toast } from "react-toastify";
import { getFromLS } from "../../utils/storage";
import * as Icon from "react-bootstrap-icons";
import { Bars } from "react-loader-spinner";
import { AgGridReact } from "ag-grid-react";
import CustomButton from "../reusable-components/CustomButtonComponent/button";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const ApprovalConfig = (props) => {
    const [data, setData] = useState([])
    const [cygnusIndividual, setCygnusIndividual] = useState("No")
    const [cygnusEntity, setCygnusEntity] = useState("No")
    const [externalIndividual, setExternalIndividual] = useState("No")
    const [externalEntity, setExternalEntity] = useState("No")
    const [update, setUpdate] = useState(false)
    const [dataSource, setDataSource] = useState();
    const [editedRows, setEditedRows] = useState([]);
    const [approvalConfigurationApiProgress, setApprovalConfigurationApiProgress] = useState(false)
    useEffect(() => {
        FetchApprovalConfiguration()
    }, [])
    
    useEffect(() => {
        if (data.length !== 0) {
            setUpdate(true)
            const source_data = [{
                "Category": "Clients created in MDM",
                "Client Type": "Individual Client",
                "Approval": data[0].MdmIndividual ? data[0].MdmIndividual : "No"
            },
            {
                "Category": "Clients created in MDM",
                "Client Type": "Entity Client",
                "Approval": data[0].MdmEntity ? data[0].MdmEntity : "No"
            },
            {
                "Category": "Clients created externally",
                "Client Type": "Individual Client",
                "Approval": data[0].ExternalIndividual ? data[0].ExternalIndividual : "No"
            },
            {
                "Category": "Clients created externally",
                "Client Type": "Entity Client",
                "Approval": data[0].ExternalEntity ? data[0].ExternalEntity : "No"
            }
            ]
            setTimeout(() => setDataSource(source_data), 2000)
        } else {
            const source_data = [{
                "Category": "Clients created in MDM",
                "Client Type": "Individual Client",
                "Approval": "No"
            },
            {
                "Category": "Clients created in MDM",
                "Client Type": "Entity Client",
                "Approval": "No"
            },
            {
                "Category": "Clients created externally",
                "Client Type": "Individual Client",
                "Approval": "No"
            },
            {
                "Category": "Clients created externally",
                "Client Type": "Entity Client",
                "Approval": "No"
            }
            ]
            setTimeout(() => setDataSource(source_data), 2000)
        }
    }, [data])

    const Switch = ({ isToggled, onClick }) => {
        return (
            <label className="switch">
                <input type="checkbox" checked={isToggled} onChange={onClick} style={{ appearance: "none" }} />
                <span className="super-admin-slider super-admin-round"></span>
            </label>
        );
    };


    const saveApprovalConfiguration = () => {
        setApprovalConfigurationApiProgress(true)
         if (update) {
            const putOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    "MDM_Individual": cygnusIndividual,
                    "MDM_Entity": cygnusEntity,
                    "External_Individual": externalIndividual,
                    "External_Entity": externalEntity
                }),
            };
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/approval_config/`, putOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setApprovalConfigurationApiProgress(false)
                        toast.success(
                            "Approval configuration successfully updated!",
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-success",
                            }
                        );
                    } else {
                        setApprovalConfigurationApiProgress(false)
                        toast.error(
                            "Saving failed, " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
        } else {
            const postOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    "MDM_Individual": cygnusIndividual,
                    "MDM_Entity": cygnusEntity,
                    "External_Individual": externalIndividual,
                    "External_Entity": externalEntity
                }),
            };
            // console.log("POST OPTIONS: ", postOptions)
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/approval_config/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setApprovalConfigurationApiProgress(false)
                        toast.success(
                            "Approval configuration successfully saved!",
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-success",
                            }
                        );
                    } else {
                        setApprovalConfigurationApiProgress(false)
                        toast.error(
                            "Saving failed, " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                });
        }
    }

    const FetchApprovalConfiguration = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/approval_config/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setData(data.data)
            });
    }
    const approvalConfigGridRef = useRef();
    const containerStyle = useMemo(() => ({ width: "100%", height: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );


    const simpleComp = (p) => {
        if (p.data.FieldName == "Password" || p.data.FieldName == "ClientSecret") {
            // console.log(p.value)
            // console.log(p.value.length)
            return (
                <>{"*".repeat(p.value.length)}</>
            );
        }
        else {
            return (
                <>{p.value}</>
            );
        }
    };

    const onFirstDataRendered = useCallback((params) => {
        approvalConfigGridRef.current.api.sizeColumnsToFit();
    }, []);

    const toggleRenderer = (props) => {
        // console.log("TOGGLE RENDERER: ", props.data)
        let toggler = ""
        if (dataSource !== undefined && props.data !== undefined) {
            for (let property of dataSource) {
                if (property["Category"] === props.data["Category"] && property["Client Type"] === props.data["Client Type"]) {
                    toggler = property.Approval
                }
            }
        }
        const updateToggleValue = (value) => {
            toggler = value
            // console.log("UPDATING DATA SOURCE!")
            const ds = [...dataSource]
            for (let property of ds) {
                if (property.Category === props.data.Category && property["Client Type"] === props.data["Client Type"]) {
                    property.Approval = value
                }
            }
            // console.log("UPDATED DATA SOURCE: ", ds)
            setDataSource(ds)
        }

        return (
            props.value !== undefined &&
            <div style={{ width: "fit-content", display: "flex", justifyContent: "center", alignItems: "center" }}>
                No &nbsp;
                <Switch
                    isToggled={toggler === "Yes" ? true : false}
                    onClick={() => updateToggleValue(toggler === "Yes" ? "No" : "Yes")}
                ></Switch>
                &nbsp;&nbsp; Yes
            </div>
        )
    }
    const [columnDefs, setColumnDefs] = useState([]);

    useEffect(() => {
        if (dataSource !== undefined) {
            for (let property of dataSource){
                if (property.Category === "Clients created in MDM" && property["Client Type"] === "Individual Client") {
                    setCygnusIndividual(property.Approval)
                } else if (property.Category === "Clients created in MDM" && property["Client Type"] === "Entity Client") {
                    setCygnusEntity(property.Approval)
                } else if (property.Category === "Clients created externally" && property["Client Type"] === "Individual Client") {
                    setExternalIndividual(property.Approval)
                } else if (property.Category === "Clients created externally" && property["Client Type"] === "Entity Client") {
                    setExternalEntity(property.Approval)
                }
            }
            setColumnDefs([
                { field: "Category", sizeColumnsToFit: true, rowGroup: true, hide: true },
                //    { field: "Client Type", cellRenderer: simpleComp, editable: true, sizeColumnsToFit: true },
                { field: "Client Type", sizeColumnsToFit: true },
                {
                    field: "Approval",
                    cellEditor: "agSelectCellEditor",
                    // editable: true,
                    // cellEditorParams: {
                    //     values: ["Yes", "No"],
                    // },
                    cellRenderer: toggleRenderer,
                    suppressSizeToFit: true
                }])
        }
    }, [dataSource])


    const sortable = true;
    const filter = true;
    const resizable = true;

    const onCellValueChanged = (event) => {
        setEditedRows([...editedRows, event.rowIndex]);
        const source_data = [...dataSource]
        for (let field of source_data) {
            if (event.data.Category === field.Category && event.data["Client Type"] === field["Client Type"]) {
                field["Approval"] = event.data.Approval
            }
        }
        setDataSource(source_data)
        // console.log("UPDATED DATA SOURCE: ", source_data)
    };

    const onCellEditingStopped = (event) => {
        // console.log("Event AFTER editing", event, [...editedRows, event.rowIndex]);
        // setEditedRows([...editedRows, event.rowIndex]);
    };

    return (
        <>
            <Row className='no-gutter page-wrapper'>
                <Col md={6}>
                    <p className="display-6" style={{ height: "fit-content", marginBottom: 0, width: "fit-content" }}>Approval Configuration</p>
                </Col>
                <Col md={6}>
                    <div style={{ float: "right", marginBottom: -10, marginTop: 10 }}>
                        <CustomButton
                            progress={approvalConfigurationApiProgress}
                            onclick={() => saveApprovalConfiguration()}
                            content={update?"Update":"Save"}
                        />
                    </div>
                </Col>
            </Row>
            {/* <Row className='no-gutter'>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={6} className="flex-row-space-center">
                                    <div className="mdm-logo-container">
                                        <Image src={require("../../pages/vendorimages/MDM.png")} className="approval-mdm-logo" />
                                    </div>
                                    <div className="flex-column-center-center">
                                        <div id="arrowAnim">
                                            <div class="arrowSliding">
                                                <div class="arrow"></div>
                                            </div>
                                            <div class="arrowSliding delay1">
                                                <div class="arrow"></div>
                                            </div>
                                            <div class="arrowSliding delay2">
                                                <div class="arrow"></div>
                                            </div>
                                            <div class="arrowSliding delay3">
                                                <div class="arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vendors-logos-container">
                                        <Image src={require("../../pages/vendorimages/SFSND.png")} className="approval-vendor-logo" style={{ marginLeft: -30 }} />
                                        <Image src={require("../../pages/vendorimages/CCHPM.png")} className="approval-vendor-logo" />
                                        <Image src={require("../../pages/vendorimages/HBSPT.png")} className="approval-vendor-logo" style={{ marginLeft: -30 }} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Card.Title>Clients created in  Cygnus MDM</Card.Title>
                                    <p>Do you need the approval process for these clients?</p>
                                    <div className="flex-row-space-center" style={{ padding: "20px 10px 10px 0px" }}>
                                        <div style={{ width: "fit-content" }}>
                                            <h6 className="approval-header">
                                                Individual Client
                                            </h6>
                                        </div>
                                        <div style={{ width: "fit-content", fontWeight: 700 }}>
                                            No &nbsp;
                                            <Switch
                                                isToggled={cygnusIndividual}
                                                onClick={() => setCygnusIndividual(!cygnusIndividual)}
                                            ></Switch>
                                            &nbsp;&nbsp; Yes
                                        </div>
                                    </div>
                                    <div className="flex-row-space-center" style={{ padding: "20px 10px 10px 0px" }}>
                                        <div style={{ width: "fit-content" }}>
                                            <h6 className="approval-header">
                                                Entity Client
                                            </h6>
                                        </div>
                                        <div style={{ width: "fit-content", fontWeight: 700 }}>
                                            No &nbsp;
                                            <Switch
                                                isToggled={cygnusEntity}
                                                onClick={() => setCygnusEntity(!cygnusEntity)}
                                            ></Switch>
                                            &nbsp;&nbsp; Yes
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={6} className="flex-row-space-center">
                                    <div className="vendors-logos-container">
                                        <Image src={require("../../pages/vendorimages/SFSND.png")} className="approval-vendor-logo" style={{ marginRight: -30 }} />
                                        <Image src={require("../../pages/vendorimages/CCHPM.png")} className="approval-vendor-logo" />
                                        <Image src={require("../../pages/vendorimages/HBSPT.png")} className="approval-vendor-logo" style={{ marginRight: -30 }} />
                                    </div>
                                    <div className="flex-column-center-center">
                                      <div id="arrowAnim">
                                            <div class="arrowSliding">
                                                <div class="arrow"></div>
                                            </div>
                                            <div class="arrowSliding delay1">
                                                <div class="arrow"></div>
                                            </div>
                                            <div class="arrowSliding delay2">
                                                <div class="arrow"></div>
                                            </div>
                                            <div class="arrowSliding delay3">
                                                <div class="arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mdm-logo-container">
                                        <Image src={require("../../pages/vendorimages/MDM.png")} className="approval-mdm-logo" />
                                    </div>

                                </Col>
                                <Col md={6}>
                                    <Card.Title>Clients created externally</Card.Title>
                                    <p>Do you need the approval process for these clients?</p>
                                    <div className="flex-row-space-center" style={{ padding: "20px 10px 10px 0px" }}>
                                        <div style={{ width: "fit-content" }}>
                                            <h6 className="approval-header">Individual Client</h6>
                                        </div>
                                        <div style={{ width: "fit-content", fontWeight: 700 }}>
                                            No &nbsp;
                                            <Switch
                                                isToggled={externalIndividual}
                                                onClick={() => setExternalIndividual(!externalIndividual)}
                                            ></Switch>
                                            &nbsp;&nbsp; Yes
                                        </div>
                                    </div>
                                    <div className="flex-row-space-center" style={{ padding: "20px 10px 10px 0px" }}>
                                        <div style={{ width: "fit-content" }}>
                                            <h6 className="approval-header">
                                                Entity Client
                                            </h6>
                                        </div>
                                        <div style={{ width: "fit-content", fontWeight: 700 }}>
                                            No &nbsp;
                                            <Switch
                                                isToggled={externalEntity}
                                                onClick={() => setExternalEntity(!externalEntity)}
                                            ></Switch>
                                            &nbsp;&nbsp; Yes
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row > */}
            <Row className="no-gutter">
                {dataSource !== undefined ?
                    <div style={containerStyle} className="vendor-details-grid">
                        <div className="ag-theme-balham" style={gridStyle}>
                            <AgGridReact
                                ref={approvalConfigGridRef}
                                rowData={dataSource}
                                columnDefs={columnDefs}
                                alwaysShowHorizontalScroll={false}
                                alwaysShowVerticalScroll={false}
                                sortable={sortable}
                                filter={filter}
                                resizable={resizable}
                                onFirstDataRendered={onFirstDataRendered}
                                onCellValueChanged={(item) => onCellValueChanged(item)}
                                onCellEditingStopped={(event) => onCellEditingStopped(event)}
                                rowHeight={25}
                                suppressRowHoverHighlight={true}
                                singleClickEdit={true}
                                domLayout='autoHeight'
                                groupDefaultExpanded={1}
                            />
                        </div>
                    </div>
                    :
                    <div
                        className="flex-row-center-center"
                        style={{ marginTop: "20%" }}
                    >
                        <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                            <Bars
                                height="80"
                                width="80"
                                color="var(--theme1_primary)"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />

                            Loading your data
                        </div>
                    </div>
                }
            </Row>
        </>
    )
}

export default ApprovalConfig;