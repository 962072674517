import React, { useEffect, useState, useCallback } from 'react';
import Highcharts from 'highcharts';
import './chartTable.css';
import HighchartsReact from "highcharts-react-official";
import { Card, Image, Container, Row, Col, Button } from "react-bootstrap";
import { Tabs, Tab } from 'react-bootstrap';
import _ from "lodash";
import * as Icon from "react-bootstrap-icons";
import { ThreeCircles, Bars } from "react-loader-spinner";
import { CSSTransition } from 'react-transition-group';
import { ActionGrid } from './Grid';
import { useNavigate } from 'react-router-dom';

const SingleVsJointFiling = () => {
  const [isLoading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [originalTableData, setOriginalTableData] = useState(null);
  const [clickedSeriesData, setClickedSeriesData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [slices, setSlices] = useState([]);
  const [activeTab, setActiveTab] = useState('chart');
  const [tableHeader, setTableHeader] = useState('');
  const navigate = useNavigate();
  // const [counts, setCounts] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/cust_join_type/`, {
        // const response = await fetch(`http://127.0.0.1:8000/cust_join_type/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            alert("Session ended, Please login back");
          } else {
            throw new Error("Request failed.");
          }
        }

        const data = await response.json();
        console.log(data);
        setOriginalData(data);
        setChartData(data);

        if (!data || !data.Success || !data.Data || data.Data.length === 0) {
          setNoDataMessage('No data currently available.');
        } else {
          setNoDataMessage('');
        }

        const responseTableData = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/workflow/get_default_join`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        });
  
        if (!responseTableData.ok) {
          throw new Error('Request failed.');
        }
  
        const seriesData = await responseTableData.json();
        if(seriesData) {
          setClickedSeriesData(seriesData.Data);
          setOriginalTableData(seriesData.Data);
          setShowTable(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the function to fetch data
    fetchData();

    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []); 

  let gridApi;
  const onGridReady = useCallback((params) => {
    gridApi = params.api;
    gridApi.closeToolPanel();
  }, []);

  const handlePieSliceClick = async (event) => {
    const fileType = event.point.name;

    // Make a POST call to get series data
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/cust_join_type/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({ fileType: fileType }),
      });

      if (!response.ok) {
        throw new Error('Request failed.');
      }
      console.log(fileType);
      const seriesData = await response.json();
      setClickedSeriesData(seriesData.Data);
      setActiveTab('table');
      setShowTable(true);
      setTableHeader(fileType);
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };

// const handleSeriesClick = async (event) => {
//     // Extract the custType from the clicked point
//       };
  // Render the chart when data is available
  useEffect(() => {
    if (chartData && chartData.Success && chartData.Data && chartData.Data.length > 0) {
      setSlices(chartData.Data.map(item => ({
        name: item.joinType,
        y: item.count
    })))
    }
  }, [chartData]);

  const updateChart = async => {
    console.log('update chart called');
    const options = {
      ...primaryOptions,
      series: [{
        data: slices,
        point: {
          events: {
            click: handlePieSliceClick,
          },
        },
      }],
    };
        return (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        );
  }

// Render the table when clickedSeriesData is available
const renderTable = () => {
  if (clickedSeriesData && clickedSeriesData.length > 0 && showTable) {

    return (
    <>
      <h5>
      {tableHeader === null || tableHeader === '' ? 'Client Details' : ('Client Type ' + ' ' + tableHeader )}</h5>
      <ActionGrid data = {clickedSeriesData} onGridReady={onGridReady} />
    </>);
  }
};
const handleBackClick = () => {
  navigate('/dashboard'); 
};
const resetData = () => {
  if (originalData) {
    setChartData(originalData);
    setSlices(chartData.Data.map(item => ({
      name: item.custType,
      y: item.count
  })));
  if(originalTableData) {
    setClickedSeriesData(originalTableData);
    setShowTable(true);
    setTableHeader(null);
  }
  updateChart();
  setActiveTab('chart');
  }
};
    

  const primaryOptions = {
    chart: {
        type: 'pie'  // Change the chart type to 'pie'
    },
    title: {
        text: 'Client Tax Filing Distribution'
    },
    plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false
          },
          showInLegend: true,
          style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
          }
      }
  },
  legend: {
    enabled: true,
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 0
},

      tooltip: {
          pointFormat: '<b>Clients</b>: {point.y}'
        },

    series: [{
        name: 'Number of Clients',
        data: slices,
        legend: {
          enabled: false
       },
        point: {
            events: {
                click: handlePieSliceClick,
            },
        },
    }]
};

const renderChart = () => {
  if (!chartData || !chartData.Success || !chartData.Data || chartData.Data.length === 0) {
    return <p>No data currently available.</p>;
  }

  const slices = chartData.Data.map((item) => ({
    name: item.joinType,
    y: item.count,
  }));

  const options = {
    ...primaryOptions,
    series: [{
      data: slices,
      point: {
        events: {
          click: handlePieSliceClick,
        },
      },
    }],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

  return (
    <div>
      {isLoading && (
        <div className="loading-container" style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
        <Row className="dashboard-row">
          <div className="spinner-container" style={{ margin: "0 auto", textAlign: "center" }}>
            <div className="spinner">
              <Bars
                height="80"
                width="80"
                color="var(--theme1_primary)"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <h5 style={{ display: "inline"}}>Loading Join Type Report</h5>
            </div>
          </div>
        </Row>
      </div> )} 
      {!isLoading && (
        <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-1rem', float: 'right' }}>
          {/* <button className='btn-mini-card' onClick={handleBackClick}>
            <Icon.ArrowLeftCircleFill className="report-transporter" />
          </button> */}
          <button className='btn-mini-card' onClick={resetData}>
            <Icon.ArrowClockwise className="report-transporter" />
          </button>
        </div>
          <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
          <Tab eventKey="chart" title="Chart View">
            {renderChart()}
          </Tab>
          <Tab eventKey="table" title="Table View">
            <Row style ={{height: "80vh"}}>
              {renderTable()}
            </Row>
          </Tab>
        </Tabs>
        </>
      )}
      </div>
      );
};

export default SingleVsJointFiling;
