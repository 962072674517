import React, { useState, useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { Col } from "react-bootstrap";
import { v4 } from "uuid";
import Form from 'react-bootstrap/Form';
import "./inputbox.css";
import Select from "react-select";
import { Spacer } from './spacer';

const CheckboxOptionRenderer = (props) => {
    // console.log("PROPS: ", props)
    const [fieldSchema, setFieldSchema] = useState(props.field)
    /**
     * Update primary checkbox field schema
     */
    useEffect(() => {
        setFieldSchema(props.field)
    }, [props.field])

    const [selectedFields, setSelectedFields] = useState([])
    /**
     * Update primary checkbox field schema
     */
    useEffect(() => {
        console.log("RCPROPS: ", props.rcprops)
        if(props.rcprops.selectedfields !== undefined){
            setSelectedFields(props.rcprops.selectedfields)
        }
    }, [props.rcprops.selectedfields])
    const [selectedField, setSelectedField] = useState()
    const [checkboxList, setCheckboxList] = useState()
    const [inputValue, setInputValue] = useState(fieldSchema.label)
    const [requiredToggle, setRequiredToggle] = useState(fieldSchema.mandatory);
    // Setting default WIDTH select options
    const [widthOptions, setWidthOptions] = useState([
        {
            value: "12",
            label: "100%",
            id: "12"
        },
        {
            value: "9",
            label: "75%",
            id: "9"
        },
        {
            value: "8",
            label: "66%",
            id: "8"
        },
        {
            value: "6",
            label: "50%",
            id: "6"
        },
        {
            value: "4",
            label: "33%",
            id: "4"
        },
        {
            value: "3",
            label: "25%",
            id: "3"
        }
    ])

    const [selectedWidth, setSelectedWidth] = useState(widthOptions.find((obj) => obj.id === `${fieldSchema.width}`))
    const [topMargin, setTopMargin] = useState((fieldSchema.margins !== undefined ? fieldSchema.margins["top"] : 5))
    const [rightMargin, setRightMargin] = useState((fieldSchema.margins !== undefined ? fieldSchema.margins["right"] : 0))
    const [bottomMargin, setBottomMargin] = useState((fieldSchema.margins !== undefined ? fieldSchema.margins["bottom"] : 5))
    const [leftMargin, setLeftMargin] = useState((fieldSchema.margins !== undefined ? fieldSchema.margins["left"] : 0))
    
    /**
     * Update the property of field component inside the current schema with provided value
     * @param {*} field: Field component 
     * @param {*} key: The property to be changed
     * @param {*} value: The entered value in input field
     */
    const UpdateMarginToPreview = (field, key, side, value) => {
        const updatedComponents = Object.assign(field)
        if (Object.keys(updatedComponents).includes("margins")) {
            updatedComponents[key][side] = value;
        } else {
            Object.assign(updatedComponents, { "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 } })
            Object.assign(updatedComponents[key], { [side]: value });
        }
        setFieldSchema(updatedComponents)
        props.rcprops.updatecomponent(
            field, "margins", field["margins"]
        )
    }

    /**
     * Update current checkboxlist when parent checkboxlist is updated
     */
    useEffect(() => {
        setCheckboxList(props.checkboxlist)
    }, [props.checkboxlist])

    /**
     * Updating margins for the field
     * @param {*} event: Number input trigger event
     */
    const updateTopMargin = (event) => {
        setTopMargin(event.target.value)
        UpdateMarginToPreview(fieldSchema, "margins", "top", parseInt(event.target.value))
    }

    /**
     * Updating margins for the field
     * @param {*} event: Number input trigger event
     */
    const updateRightMargin = (event) => {
        setRightMargin(event.target.value)
        UpdateMarginToPreview(fieldSchema, "margins", "right", parseInt(event.target.value))
    }

    /**
     * Updating margins for the field
     * @param {*} event: Number input trigger event
     */
    const updateBottomMargin = (event) => {
        setBottomMargin(event.target.value)
        UpdateMarginToPreview(fieldSchema, "margins", "bottom", parseInt(event.target.value))
    }

    /**
     * Updating margins for the field
     * @param {*} event: Number input trigger event
     */
    const updateLeftMargin = (event) => {
        setLeftMargin(event.target.value)
        UpdateMarginToPreview(fieldSchema, "margins", "left", parseInt(event.target.value))
    }

    /**
     * Updating the width for the given field
     * @param {*} event: Width select trigger event
     * @param {*} field: Field component
     */
    const updateFieldWidth = (event, field) => {
        const index = checkboxList.findIndex((obj) => obj.id === fieldSchema.id)
        const updatedField = checkboxList[index]
        updatedField["width"] = parseInt(event.value)
        checkboxList.splice(index, 1, updatedField)
        props.rcprops.updatecomponent(
            field, "width", parseInt(event.value)
        )
    }

    /**
     * Update the label property of field component inside the field schema with provided value
     * @param {*} event: Width select trigger event 
     * @param {*} field: Field component
     */
    const updateFieldLabel = (event, field) => {
        const index = checkboxList.findIndex((obj) => obj.id === fieldSchema.id)
        const updatedField = checkboxList[index]
        updatedField["label"] = event.value
        checkboxList.splice(index, 1, updatedField)
        props.rcprops.updatecomponent(
            field, "label", event.label
        )
    }

    /**
     * Remove the provided component from schema
     * @param {*} field: Field component
     */
    const removeOption = (field) => {
        const newList = [...checkboxList]
        const index = newList.findIndex((obj) => obj.id === field.id)
        if (index !== -1) {
            newList.splice(index, 1)
        }
        setCheckboxList(newList)
        props.setcheckboxlist(newList)
        props.rcprops.removecomponent(field)
    }

    // The required toggle swith
    const Switch = ({ isToggled, onClick }) => {
        return (
            <label className="switch">
                <input type="checkbox" checked={isToggled} onChange={onClick} style={{ marginLeft: 10 }} />
                <span className="super-admin-slider super-admin-round"></span>
            </label>
        );
    };

    /**
     * Updating the field required for any INPUT
     * @param {*} field: field to be updated
     */
    const updateRequired = (field) => {
        setRequiredToggle(!requiredToggle)
        const index = checkboxList.findIndex((obj) => obj.id === fieldSchema.id)
        const updatedField = checkboxList[index]
        updatedField["mandatory"] = !requiredToggle
        checkboxList.splice(index, 1, updatedField)
        props.rcprops.updatecomponent(
            field, "mandatory", !requiredToggle
        )
    }

    return (
        <>
            <div className='flex-row-center-start' style={{ margin: "0px 0px 10px 0px", paddingTop: 10, borderTop: "1px solid lightgray" }}>
                <Col md={1} className='flex-row-center-center'>
                    <Icon.Square style={{ fontSize: 20, marginTop: 10, color: "var(--theme1_primary)", cursor: "pointer" }} />
                </Col>
                <Col md={5} style={{ margin: "0px 10px" }}>
                    <label style={{ fontWeight: 500, fontSize: 8 }}>Label</label>                
                    <Select
                        options={selectedFields}
                        value={selectedField}
                        onChange={(event) => {
                            setSelectedField(event)
                            updateFieldLabel(event, fieldSchema)
                        }}
                        placeholder={"Select field"}
                        aria-label="Default"
                        autosize={true}
                        className='custom-select '
                        classNamePrefix="react-select"
                    />
                </Col>
                <Col md={3}>
                    <label style={{ fontWeight: 500, fontSize: 8 }}>Width</label>
                    <Select
                        options={widthOptions}
                        value={selectedWidth}
                        onChange={(event) => {
                            setSelectedWidth(event)
                            updateFieldWidth(event, fieldSchema)
                        }}
                        placeholder={"100%"}
                        aria-label="Default"
                        autosize={true}
                        className='custom-select '
                        classNamePrefix="react-select"
                    />
                </Col>
                <Col md={2} className='flex-row-center-center'>
                    <div style={{ width: "fit-content", margin: "-10px 0px 10px 0px"}} >
                        <label style={{ fontWeight: 500, fontSize: 8, margin: "10px 0px 10px 5px"}}>Required</label>
                        <Switch
                            isToggled={requiredToggle}
                            onClick={() => updateRequired(fieldSchema)}
                        />
                    </div>
                </Col>
                <Col md={1} className='flex-row-center-center'>{
                    props.templatestatus !== "Final" ?
                    <Icon.DashCircleFill style={{ fontSize: 20, marginTop: 10, color: "var(--theme1_primary)", cursor: "pointer" }} onClick={() => removeOption(fieldSchema)} />
                    :<></>
                    }
                </Col>
            </div>
            <div className='flex-row-center-center' style={{padding:"0px 0px 10px 0px", margin: "0px 0px 10px 0px" }}>
                <Spacer
                    topMargin={topMargin}
                    rightMargin={rightMargin}
                    bottomMargin={bottomMargin}
                    leftMargin={leftMargin}
                    updateTopMargin={(event) => updateTopMargin(event)}
                    updateRightMargin={(event) => updateRightMargin(event)}
                    updateBottomMargin={(event) => updateBottomMargin(event)}
                    updateLeftMargin={(event) => updateLeftMargin(event)}
                />
            </div>
        </>
    )
}

export const RequiredCheckboxes = (rcprops) => {
    // console.log("RC PROPS: ", rcprops)
    const [baseId, setBaseId] = useState(rcprops.baseid)
    const [fieldSchema, setFieldSchema] = useState(rcprops.fieldschema)
    const [checkboxList, setCheckboxList] = useState([...fieldSchema])
    const [templateStatus, setTemplateStatus] = useState(rcprops.templatestatus)

    /**
     * Every time the parent base id is updated update it in child
     */
    useEffect(() => {
        setBaseId(rcprops.baseid)
    }, [rcprops.baseid])

    /**
     * Create a new checkbox field and add it to the parent initial data
     */
    const addOption = () => {
        const newId = v4()
        setCheckboxList([...checkboxList, {
            "id": newId,
            "label": null,
            "value": "",
            "approval": false,
            "mandatory": false,
            "hidden": false,
        }])
        rcprops.addnewcomponent({
            "id": newId,
            "label": "",
            "approval": false,
            "mandatory": false,
            "hidden": false,
            "disabled": false,
            "type": "checkbox",
            "example": null,
            "pivot": true,
            "pivotreverse": false,
            "value": "",
            "width": 12,
            "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
        })
    }

    return (
        <Form className='no-padding'>
            <div className='flex-row-center-start' style={{ padding: "10px 0px" }}>
                <p style={{ marginRight: 20, marginBottom: 0 }}>Checkbox options </p>
                {
                    templateStatus !== "Final" ?
                        <Icon.PlusCircleFill style={{ fontSize: 20, color: "var(--theme1_primary)", cursor: "pointer" }} onClick={() => addOption()} />
                        :
                        <></>
                }
            </div>
            {
                checkboxList.map((field, i) =>
                (<CheckboxOptionRenderer
                    key={field.id}
                    index={i}
                    field={field}
                    rcprops={rcprops}
                    checkboxlist={checkboxList}
                    setcheckboxlist={(checkboxlist) => setCheckboxList(checkboxlist)}
                    templatestatus={templateStatus}
                />)
                )
            }
        </Form>
    )
}