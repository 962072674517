import React, { useEffect, useState } from 'react';
// import Highcharts from 'highcharts';
import * as Highcharts from 'highcharts/highmaps';
// import HighchartsReact from 'highcharts-react-official';

import {  Row,  } from "react-bootstrap";
import _ from "lodash";
import {  Bars } from "react-loader-spinner";

import markerClusters from "highcharts/modules/marker-clusters";

markerClusters(Highcharts);


// import 'highcharts/modules/cluster';

// Highcharts.setOptions(Highcharts.merge(Highcharts.getOptions(), Highcharts));


// import HighchartsReact from 'highcharts-react-official';
// import HighchartsReact from 'highcharts-react-official';
// import highchartsMap from 'highcharts/modules/map';
// import mapDataUS from '@highcharts/map-collection/countries/us/us-all.geo.json';
const ZipCodeMap = () => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const createChart = async () => {
            try {
(async () => {

  
    const topology = await fetch(
        'https://code.highcharts.com/mapdata/countries/us/us-all.topo.json'
    ).then(response => response.json());


    data.forEach(p => {
        p.z = p.value;
    });

    
    const H = Highcharts;

    const chart = Highcharts.mapChart('container', {

        chart: {
            map: topology,
            // height: (9 / 16 * 100) + '%'
        },
            title: {
                text: 'Client distribution by geographic location'
            },
            // mapNavigation: {
            //     enabled: true
            // },
            mapNavigation: {
                enabled: true,
                buttonOptions: {
                  verticalAlign: 'bottom'
                }
              },
              
              colorAxis: {
                visible:false,
                start: 1,
                min: 1,
                max: 40,
                minColor: "#ffcccc",
                maxColor: "#cc0000"
              },
              
              legend:{
                enabled: true,
                // align: 'right', 
                verticalAlign: 'bottom', 
                layout: 'vertical',
              },

                tooltip: {
                    headerFormat: '',
                    pointFormat: '<b>{point.city}</b><br>Clients: {point.value}'
                  },

            plotOptions: {
                mappoint: {
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      if (typeof(this.point.clusterPointsAmount) === "undefined") {
                        return '';
                      } else {
                        return ('Num Zipcodes: ' , this.point.clusterPointsAmount);
                      }
                    },
                  },
                  cluster: {
                    enabled: true,
                    allowOverlap: false,
                    animation: {
                      duration: 450
                    },
                    layoutAlgorithm: {
                      type: 'grid',
                      gridSize: 70
                    },
                    // showInLegend: true,
                    zones: [{
                      from: 1,
                      to: 2,
                      marker: {
                        radius: 15,
                        fillOpacity: 0.5,  
                        fillColor: H.getOptions().colors[0]
                      }
                    }, {
                      from: 2,
                      to: 4,
                      marker: {
                        radius: 15,
                        fillOpacity: 0.5,  
                        fillColor: H.getOptions().colors[0]
                      }
                    }, {
                      from: 4,
                      to: 6,
                      marker: {
                        radius: 15,
                        fillOpacity: 0.5,  
                        fillColor: H.getOptions().colors[0]
                      }
                    }, {
                      from: 6,
                      to: 200,
                      marker: {
                        radius: 15,
                        fillOpacity: 0.5,  
                        fillColor: H.getOptions().colors[0]
                      }
                    }]
                  }
                }
              },
        
        series: [{
                    name: 'Basemap',
                    borderColor: '#606060',
                    nullColor: 'rgba(200, 200, 200, 0.2)',
                }, {
                    type: 'mappoint',
                    showInLegend: true,
                    // colorKey: 'clusterPointsAmount',
                    name: 'Client Location',
                    data: data,
                    maxSize: '8%',
                    color: H.getOptions().colors[0],
                    colorKey: 'clusterPointsAmount',
                    marker: {
                        lineWidth: 1,
                        lineColor: '#fff',
                        symbol: 'mapmarker',
                        radius: 8
                    },
                }],

        // plotOptions: {
        //     mappoint: {
        //         bubble: {
        //             lineWidth: 1,
        //             borderColor: '#000',
        //         },
        //         events: {
        //             click: function (event) {
        //                 if (event.point.isClustered) {
        //                     chart.zoomIn(event.point.clusterPoints); 
        //                 }
        //             }
        //         }
        //     },
        // }

        //     mappoint: {
        //         cluster: {
        //             enabled: true,
        //             allowOverlap: false,
        //             animation: {
        //                 duration: 450
        //             },
        //             layoutAlgorithm: {
        //                 type: 'grid',
        //                 gridSize: 40
        //                 // distance: 10
        //             },        
        //         }
        //     }
        // },        

        //     series: [{
        //         name: 'Basemap',
        //         // mapData: topology,
        //         accessibility: {
        //             exposeAsGroupOnly: true
        //         },
        //         borderColor: '#606060',
        //         nullColor: 'rgba(200, 200, 200, 0.2)',
        //         showInLegend: false
        //     }, {
        //         type: 'mappoint',
        //         // accessibility: {
        //         //     point: {
        //         //         valueDescriptionFormat: '{point.zipcode}. Zipcode {point.zipcode}. Count offices: {point.value}.'
        //         //     }
        //         // },
        //         colorKey: 'clusterPointsAmount',
        //         name: 'Client distribution',
        //         data: data,
        //         // maxSize: '8%',
        //         color: H.getOptions().colors[0]
        //     }]
        });

    })();

                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

    useEffect(() => {
       const fetchdata = async () => {
        try {
                const dataResponse = await fetch(
                    `${process.env.REACT_APP_BASE_URI_REPORTS}/cust_geo_dist/`, {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                      },
                    }
                ); 
                const jsonData = await dataResponse.json();
                
                const useData = jsonData.Data.map(item => ({
                  zipcode: item.zipcode,
                  city: item.city,
                  lat: item.latitude,
                  lon: item.longitude,
                  value: item.countZip,
                  country: "US",
                //   isClustered: false 
              }));

             
              setData(useData);

            }
                 catch(error) {
                    console.log(error);
                }
            }

        fetchdata();

        setTimeout(() => {
            setLoading(false);
          }, 6000);
    }, []);
    
    
    useEffect(() => {    
        if(Object.keys(data).length >0 ) {

            createChart();
        }
    }, [data]);

    return (
        <div>
          {isLoading ? (
            <div className="loading-container" style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
            <Row className="dashboard-row">
              <div className="spinner-container" style={{ margin: "0 auto", textAlign: "center" }}>
                <div className="spinner">
                  <Bars
                    height="80"
                    width="80"
                    color="var(--theme1_primary)"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                  <h5 style={{ display: "inline" }}>Loading Client Distribution Report</h5>
                </div>
              </div>
            </Row>
           </div> 
        ) :(
            <div id="container" style={{ width: '100%', height: '600px' }}></div>
          )}
    </div>
    );
};

export default ZipCodeMap;
