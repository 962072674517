import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    Form
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

export const ApprovalMessageModal = (props) => {
    const [message, setMessage] = useState()
    const continueApi = () => {
        props.approvalcontinuation(message)
        console.log("CONFIRMED AND CONTINUED.")
        props.onHide()
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="custom-backdrop"
            centered
            scrollable="false"
        >
            <Modal.Header className="row no-gutter" style={{ padding: "10px 0px 0px 0px" }}>
                <Col md={8}>
                    <h6>Message:</h6>
                </Col>
                <Col md={4}>
                    <div className="flex-row-center-start" style={{ float: 'right', width: '100%' }}>
                        <Button className="custom-button" style={{
                            width: "fit-content",
                            display: "inline-block",
                            padding: "10px 20px",
                            margin: "10px 0px 15px 15px"
                        }} onClick={() => continueApi()} >
                            Continue
                        </Button>
                        <Button className="custom-button" style={{
                            width: "fit-content",
                            display: "inline-block",
                            padding: "10px 20px",
                            margin: "10px 0px 15px 25px"
                        }} onClick={() => props.onHide()} >
                            Go back
                        </Button>
                    </div>
                </Col>

            </Modal.Header>
            <Modal.Body style={{ paddingTop: 0, paddingBottom: 0 }} >
                <Row className="no-gutter flex-row-start-center">
                    <textarea rows={5} className="form-control move-left custom-input" id="message" aria-describedby="emailHelp"
                        placeholder={"Please enter a concerning message"}
                        value={message}
                        onChange={event => {
                            setMessage(event.target.value)
                        }}
                    />
                </Row>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}