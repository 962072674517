import React, { useEffect, useState, useCallback } from 'react';
// import Highcharts from 'highcharts';
import * as Highcharts from 'highcharts/highmaps';
// import HighchartsReact from 'highcharts-react-official';

import { Row, } from "react-bootstrap";
import _ from "lodash";
import { Bars } from "react-loader-spinner";
import { Tabs, Tab } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { ActionGrid } from './GridGrouped';
import { useNavigate } from 'react-router-dom';

const GeoDistMap = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [activeTab, setActiveTab] = useState('chart');
  const navigate = useNavigate();

  const createChart = async () => {
    try {
      (async () => {
        const topology = await fetch(
          'https://code.highcharts.com/mapdata/countries/us/us-all.topo.json'
        ).then(response => response.json());

        data.forEach(p => {
          p.z = p.clients.length;
        });

        const H = Highcharts;

        const chart = Highcharts.mapChart('container', {

          chart: {
            map: topology,
          },
          title: {
            text: 'Client Geographical Distribution'
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              verticalAlign: 'bottom'
            }
          },

          colorAxis: {
            visible: false,
            start: 1,
            min: 1,
            max: 40,
            minColor: "#ffcccc",
            maxColor: "#cc0000"
          },

          legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: 0
          },

          // tooltip: {
          //     headerFormat: '',
          //     pointFormat: '<b>{point.office}</b>'
          //   },

          plotOptions: {
            mappoint: {
              dataLabels: {
                enabled: false,
                // format: '{point.name}', 
              },
            }
          },

          tooltip: {
            formatter: function () {
              if (this.series.name.endsWith('Clients')) {
                return '<b>' + this.point.type + '</b>';
              } else {
                return '<b>' + this.point.name + '</b><br>Total Clients: ' + this.point.z;
              }
            },
          },

          series: [{
            name: 'Basemap',
            borderColor: '#606060',
            nullColor: 'rgba(200, 200, 200, 0.2)',
          }],
        });

        data.forEach(office => {

          const officeData = {
            type: 'mappoint',
            name: `${office.office} Office`,
            color: Highcharts.getOptions().colors[data.indexOf(office)],
            data: [{
              name: office.office,
              lat: office.lat,
              lon: office.lon,
              z: office.clients.length,
            }],
            maxSize: '8%',
            marker: {
              lineWidth: 1,
              lineColor: '#fff',
              symbol: 'mapmarker',
              radius: 8,
            },
            showInLegend: true,
            //   dataLabels: {
            //     enabled: true,
            //     format: '<b>{point.name}</b><br>Clients: {point.z}',
            //   },
          };

          const clientData = {
            type: 'mappoint',
            name: `${office.office} Clients`,
            color: Highcharts.getOptions().colors[data.indexOf(office)],
            data: office.clients.map(client => ({
              name: client.clientName,
              type: client.clientType,
              lat: client.lat,
              lon: client.lon,
              z: 5,
            })),
            maxSize: '8%',
            marker: {
              lineWidth: 1,
              lineColor: '#fff',
              radius: 5,
            },
            showInLegend: true,
          };

          chart.addSeries(officeData);
          chart.addSeries(clientData);


          setActiveTab('chart');
        });
      })();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const dataResponse = await fetch(
          `${process.env.REACT_APP_BASE_URI_REPORTS}/cust_office_client_dist/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        }
        );
        const jsonData = await dataResponse.json();
        const useData = jsonData.Data;


        setData(useData);
        setShowTable(true);
      }
      catch (error) {
        console.log(error);
      }
    }

    fetchdata();

    setTimeout(() => {
      setLoading(false);
    }, 8000);
  }, []);


  useEffect(() => {
    if (Object.keys(data).length > 0) {
      createChart();
    }
  }, [data]);

  let gridApi;
  const onGridReady = useCallback((params) => {
    gridApi = params.api;
    params.api.closeToolPanel();
  }, []);

  const renderTable = () => {
    if (data && data.length > 0 && showTable) {

      return (
        <>
          <ActionGrid data={data} onGridReady={onGridReady} />
        </>);
    }
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  }


  return (
    <div>
      {isLoading ? (
        <div className="loading-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <Row className="dashboard-row">
            <div className="spinner-container" style={{ margin: "0 auto", textAlign: "center" }}>
              <div className="spinner">
                <Bars
                  height="80"
                  width="80"
                  color="var(--theme1_primary)"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
                <h5 style={{ display: "inline" }}>Loading Client Distribution Report</h5>
              </div>
            </div>
          </Row>
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-1rem', float: 'right' }}>
            {/* <button className='btn-mini-card' onClick={handleBackClick}>
                          <Icon.ArrowLeftCircleFill className="report-transporter" />
                        </button> */}
            <button className='btn-mini-card' onClick={createChart}>
              <Icon.ArrowClockwise className="report-transporter" />
            </button>
          </div>
          <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
            <Tab eventKey="chart" title="Chart View">
              <div id="container" style={{ width: '100%', height: '600px' }}></div>
            </Tab>
            <Tab eventKey="table" title="Table View">
              <Row style={{ height: "80vh" }}>
                {renderTable()}
              </Row>
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default GeoDistMap;
