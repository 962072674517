import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    Form
} from "react-bootstrap";
import { logout } from "../../../utils/index.js";
import { ThreeCircles, Bars } from "react-loader-spinner";
import * as Icon from "react-bootstrap-icons";


const MyClientsModal = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [clientsDetails, setClientsDetails] = useState([]);
    
    // Column definitions for AgGrid
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "No.", 
            valueGetter: "node.rowIndex + 1",
            maxWidth: 100,
            minWidth: 100,
            sortable: false,
            pinned: "left",
            checkboxSelection: true,
            headerCheckboxSelection: true
        }
    ]);

    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "75vh", width: "100%" }), []);
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    let gridApi;
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            resizable: true,
            animateRows: true,
            sizeColumnsToFit: true,
            minWidth: 150
        }),
        []
    );

    const fetchMDMClientsPageData = useCallback(() => {
        // Reset state
        resetState();

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };
        
        setRefreshGrid(true);
    
        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/mdm_clients/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                const details = data.data;
                console.log(details);

                setClientsDetails(details);
            })
            .finally(() => {
                setRefreshGrid(false);
            });
    }, []);

    useEffect(() => {
        // Fetch data when modal is opened
        if (props.show) {
            fetchMDMClientsPageData();
        } else {
            // reset state when modal is closed
            resetState();
        }
    }, [props.show]);

    useEffect(() => {
        if (clientsDetails.length > 0) {
            
            // Check if keys are not already present in columnDefs
            const keysToAdd = Object.keys(clientsDetails[0]).filter(key => !columnDefs.find(column => column.field === key));

            // Generate dynamic column definitions only for keys that are not already present
            const dynamicColumnDefs = keysToAdd.map((key) => ({
                headerName: key,
                field: key,
            }));

            // Update columnDefs with dynamic column definitions
            setColumnDefs(prevDefs => [...prevDefs, ...dynamicColumnDefs]);
        }
    }, [clientsDetails]);
   
    const resetState = () => {
        setRefreshGrid(false);
        setClientsDetails([]);
        setColumnDefs([
            {
                headerName: "No.", 
                valueGetter: "node.rowIndex + 1",
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                pinned: "left",
                checkboxSelection: true,
                headerCheckboxSelection: true
            }
        ]);
    }

    const handleClose = () => {
        props.setClientsSelecting(false);
        props.onHide();
    }

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => `No data available` } }, []);

    const mail_merge = () => {
        // Get the AgGridReact instance from the ref
        const gridApi = gridRef.current.api;
    
        // Get selected rows
        const selectedRows = gridApi.getSelectedRows();

        console.log(selectedRows);
    };

    return (
        <Modal
            {...props}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="custom-backdrop"
            centered
            className="full-modal"
            scrollable="false"
        >
            <Modal.Header className="preview-modal-header" closeButton>
            </Modal.Header>
            <Modal.Body className="preview-modal">
                <>
                {!refreshGrid
                    ?
                    <>
                        <Row className='no-gutter'>
                            <Col md={12} className="d-flex justify-content-end" style={{ float: "right" }}>
                                <div className="form-select-wrapper" style={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}>
                                    <Button className="custom-button" onClick={() => mail_merge()}>
                                        Proceed to Mail Merge
                                    </Button>
                                </div>
                                <div className="navigator" style={{ marginBottom: "0.5rem" }}>
                                    {
                                        refreshGrid ?
                                            <div>
                                                <ThreeCircles
                                                    height="20"
                                                    width="20"
                                                    color="#4fa94d"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel="three-circles-rotating"
                                                    outerCircleColor="var(--theme1_tertiary)"
                                                    innerCircleColor="var(--theme1_secondary)"
                                                    middleCircleColor="var(--theme1_primary)"
                                                />
                                            </div>
                                            :
                                            <Icon.ArrowCounterclockwise onClick={() => fetchMDMClientsPageData()}
                                                style={{
                                                    fontSize: 20,
                                                    float: "right",
                                                    border: "0px",
                                                    cursor: "pointer"
                                                }} 
                                            />
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className='no-gutter'>
                            <Col md={12}>
                                <div className="ag-theme-balham" style={gridStyle}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={clientsDetails}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        onGridReady={onGridReady}
                                        alwaysShowHorizontalScroll={false}
                                        alwaysShowVerticalScroll={false}
                                        rowHeight={25}
                                        rowSelection={"multiple"}
                                        suppressContextMenu={true}
                                        onFirstDataRendered={onFirstDataRendered}
                                        noRowsOverlayComponent={noRowsOverlayComponent}
                                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                    :
                    <div
                        className="flex-row-center-center"
                        style={{ marginTop: "20%" }}
                    >
                        <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                            <Bars
                                height="80"
                                width="80"
                                color="var(--theme1_primary)"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />

                            Loading your data
                        </div>
                    </div>
                }
                </>
            </Modal.Body>
            <Modal.Footer className="action-footer">
            </Modal.Footer>
        </Modal>
    )
}

export default MyClientsModal;