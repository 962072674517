import React, { useState, useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { Col } from "react-bootstrap";
import { v4 } from "uuid";
import Form from 'react-bootstrap/Form';
import "./inputbox.css";
import Select from "react-select";

const CheckboxOptionRenderer = (props) => {
    console.log("PROPS: ", props)
    const [checkboxList, setCheckboxList] = useState()
    const [inputValue, setInputValue] = useState(props.field.label)
    const [approvalToggle, setApprovalToggle] = useState(props.field.approval);
    const [requiredToggle, setRequiredToggle] = useState(props.field.mandatory);

    // Setting default WIDTH select options
    const [widthOptions, setWidthOptions] = useState([
        {
            value: "12",
            label: "100%",
            id: "12"
        },
        {
            value: "9",
            label: "75%",
            id: "9"
        },
        {
            value: "8",
            label: "66%",
            id: "8"
        },
        {
            value: "6",
            label: "50%",
            id: "6"
        },
        {
            value: "4",
            label: "33%",
            id: "4"
        },
        {
            value: "3",
            label: "25%",
            id: "3"
        }
    ])

    const [selectedWidth, setSelectedWidth] = useState(widthOptions.find((obj) => obj.id === `${props.field.width}`))

    /**
     * Update current checkboxlist when parent checkboxlist is updated
     */
    useEffect(() => {
        setCheckboxList(props.checkboxlist)
    }, [props.checkboxlist])

    /**
     * Updating the width for the given field
     * @param {*} event: Width select trigger event
     * @param {*} field: Field component
     */
    const updateFieldWidth = (event, field) => {
        const index = checkboxList.findIndex((obj) => obj.id === props.field.id)
        const updatedField = checkboxList[index]
        updatedField["width"] = parseInt(event.value)
        checkboxList.splice(index, 1, updatedField)
        props.rcprops.updatecomponent(
            field, "width", parseInt(event.value)
        )
    }

    /**
     * Update the label property of field component inside the field schema with provided value
     * @param {*} event: Width select trigger event 
     * @param {*} field: Field component
     */
    const updateFieldLabel = (event, field) => {
        const index = checkboxList.findIndex((obj) => obj.id === props.field.id)
        const updatedField = checkboxList[index]
        updatedField["label"] = event.target.value
        checkboxList.splice(index, 1, updatedField)
        props.rcprops.updatecomponent(
            field, "label", event.target.value
        )
    }

    /**
     * Remove the provided component from schema
     * @param {*} field: Field component
     */
    const removeOption = (field) => {
        const newList = [...checkboxList]
        const index = newList.findIndex((obj) => obj.id === field.id)
        if (index !== -1) {
            newList.splice(index, 1)
        }
        setCheckboxList(newList)
        props.setcheckboxlist(newList)
        props.rcprops.removecomponent(field)
    }

    // The required toggle swith
    const Switch = ({ isToggled, onClick }) => {
        return (
            <label className="switch">
                <input type="checkbox" checked={isToggled} onChange={onClick} style={{ marginLeft: 10 }} />
                <span className="super-admin-slider super-admin-round"></span>
            </label>
        );
    };

    /**
     * Updating the field required for any INPUT
     * @param {*} field: field to be updated
     */
    const updateForApproval = (field) => {
        setApprovalToggle(!approvalToggle)
        const index = checkboxList.findIndex((obj) => obj.id === props.field.id)
        const updatedField = checkboxList[index]
        updatedField["approval"] = !approvalToggle
        checkboxList.splice(index, 1, updatedField)
        props.rcprops.updatecomponent(
            field, "approval", !approvalToggle
        )
    }

    /**
     * Updating the field required for any INPUT
     * @param {*} field: field to be updated
     */
    const updateRequired = (field) => {
        setRequiredToggle(!requiredToggle)
        const index = checkboxList.findIndex((obj) => obj.id === props.field.id)
        const updatedField = checkboxList[index]
        updatedField["mandatory"] = !requiredToggle
        checkboxList.splice(index, 1, updatedField)
        props.rcprops.updatecomponent(
            field, "mandatory", !requiredToggle
        )
    }

    return (
        <div className='flex-row-center-start' style={{ margin: "0px 0px 10px 0px" }}>
            <Col md={1} className='flex-row-center-center'>
                <Icon.Square style={{ fontSize: 20, color: "var(--theme1_primary)", cursor: "pointer" }} />
            </Col>
            <Col md={3} style={{ margin: "0px 10px" }}>
                <input type="text" className="form-control move-left custom-input"
                    placeholder={'Enter custom data'}
                    value={inputValue}
                    onChange={(event) => {
                        setInputValue(event.target.value)
                        updateFieldLabel(event, props.field)
                    }}
                    // onBlur={(event) => updateFieldLabel(event, props.field)}
                    autoComplete="nope"
                />
            </Col>
            <Col md={3}>
                <Select
                    options={widthOptions}
                    value={selectedWidth}
                    onChange={(event) => {
                        setSelectedWidth(event)
                        updateFieldWidth(event, props.field)
                    }}
                    placeholder={"100%"}
                    aria-label="Default"
                    autosize={true}
                    className='custom-select '
                    classNamePrefix="react-select"
                />
            </Col>
            <Col md={2} className='flex-row-center-center'>
                <div style={{ width: "fit-content" }}>
                    <Switch
                        isToggled={approvalToggle}
                        onClick={() => updateForApproval(props.field)}
                    />
                </div>
            </Col>
            <Col md={2} className='flex-row-center-center'>
                <div style={{ width: "fit-content" }}>
                    <Switch
                        isToggled={requiredToggle}
                        onClick={() => updateRequired(props.field)}
                    />
                </div>
            </Col>
            <Col md={1} className='flex-row-center-center'>
                <Icon.DashCircleFill style={{ fontSize: 20, color: "var(--theme1_primary)", cursor: "pointer" }} onClick={() => removeOption(props.field)} />
            </Col>
        </div>
    )
}

export const RequiredCheckboxes = (rcprops) => {
    console.log("RC PROPS: ", rcprops)
    const [baseId, setBaseId] = useState(rcprops.baseid)
    const [fieldSchema, setFieldSchema] = useState(rcprops.fieldschema)
    const [checkboxList, setCheckboxList] = useState([...fieldSchema])

    /**
     * Every time the parent base id is updated update it in child
     */
    useEffect(() => {
        setBaseId(rcprops.baseid)
    }, [rcprops.baseid])

    useEffect(() => {
        console.log("CHECKBOXES FIELD SCEMA: ", fieldSchema)
    }, [fieldSchema])


    /**
     * Create a new checkbox field and add it to the parent initial data
     */
    const addOption = () => {
        const newId = v4()
        setCheckboxList([...checkboxList, {
            "id": newId,
            "label": null,
            "value": "Untitled",
            "approval": false,
            "mandatory": false,
            "hidden": false,
        }])
        rcprops.addnewcomponent({
            "id": newId,
            "label": "Untitled",
            "approval": false,
            "mandatory": false,
            "hidden": false,
            "disabled": false,
            "type": "checkbox",
            "example": null,
            "pivot": true,
            "pivotreverse": false,
            "value": "",
            "width": 12,
            "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
        })
    }

    return (
        <Form className='no-padding'>
            <div className='flex-row-center-start' style={{ padding: "10px 0px" }}>
                <p style={{ marginRight: 20, marginBottom: 0 }}>Checkbox options </p>
                <Icon.PlusCircleFill style={{ fontSize: 20, color: "var(--theme1_primary)", cursor: "pointer" }} onClick={() => addOption()} />
                <div style={{ width: "fit-content", marginLeft: 100 }}>Approval &nbsp;</div>
                <div style={{ width: "fit-content", marginLeft: 10 }}>Required &nbsp;</div>
            </div>
            {
                checkboxList.map((field, i) =>
                (<CheckboxOptionRenderer
                    key={field.id}
                    index={i}
                    field={field}
                    rcprops={rcprops}
                    checkboxlist={checkboxList}
                    setcheckboxlist={(checkboxlist) => setCheckboxList(checkboxlist)}
                />)
                )
            }
        </Form>
    )
}