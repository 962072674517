import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import _ from 'lodash';
import { useTheme } from '../useTheme';
import { useChartTheme } from '../useChartTheme';
import { generate } from 'shortid';
import { getFromLS, setToLS } from '../../utils/storage';
import { Row, Col, Button } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Navigate } from 'react-router-dom';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import './ThemeSelector.css'
import Select from "react-select";
import { v4 } from "uuid";
require("highcharts/modules/accessibility")(Highcharts);

export default (props) => {
    const themesFromStore = getFromLS('all-themes');
    const chartThemesFromStore = getFromLS('chart-themes');
    const activeTheme = getFromLS('theme')
    const activeChartTheme = getFromLS('chart-theme')

    const [data, setData] = useState(themesFromStore);
    const [chartData, setChartData] = useState(chartThemesFromStore);

    const [themes, setThemes] = useState(_.keys(data));
    const [availableThemes, setAvailableThemes] = useState([]);
    const [chartThemes, setChartThemes] = useState(_.keys(chartData));
    const [availableChartThemes, setAvailableChartThemes] = useState(_.keys(chartData));

    const { setMode } = useTheme();
    const { setChartMode } = useChartTheme();
    const [firm_id, setFirmId] = useState(getFromLS("firmId"));
    const [create_theme, setCreateTheme] = useState(false)
    const [activeTab, setActiveTab] = useState(getFromLS("activeTab"))

    useEffect(() => {
      console.log("AVAILABLE THEMES: ", availableThemes)

    }, [availableThemes])

    // useEffect(() => {
    //     console.log("THEMES: ", themes)
    //   }, [themes])
    
    const setActiveThemes = (chart, activeTheme) => {
        const parameter_value_1 = JSON.stringify(activeTheme);
        const parameter_name = chart ? "activeChartTheme" : "activeTheme";
        const payload = { firm_id, parameter_name, parameter_value_1 };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        };
        fetch(
            `${process.env.REACT_APP_BASE_URI}/workflow/personalization/set_params/`,
            requestOptions
        ).then((result) => result.json());
    };

    useEffect(() => {
        setToLS("activeTab", "select")
        setAvailableThemes(_.keys(data))
        setThemes(_.keys(data));
        setChartThemes(_.keys(chartData));
        setAvailableChartThemes(_.keys(chartData))
    }, [])

    const themeSwitcher = useCallback((selectedTheme) => {
        setActiveThemes(false, selectedTheme)
        setMode(selectedTheme);
        setToLS("activeTab", "select")
        props.setter(selectedTheme);
        themeRefresh()
    });

    const chartThemeSwitcher = useCallback((selectedChartTheme) => {
        setActiveThemes(true, selectedChartTheme)
        setChartMode(selectedChartTheme);
        setToLS("activeTab", "select chart")
        props.tether(selectedChartTheme);
        chartThemeRefresh()
    });

    const themeRefresh = useCallback(() => {
        console.log("THEME SWITCHER CALLED.")
        let arr = [...themes]
        console.log("PRE PROCESSED AVAILABLE THEMES: ", arr)
        console.log("ACTIVE THEME: ", JSON.parse(localStorage.getItem("theme")).name.toLowerCase())
        const index = arr.findIndex((element) => element === JSON.parse(localStorage.getItem("theme")).name.toLowerCase())
        console.log("index theme:", index)
        if (index !== -1) {
            arr.splice(index, 1);
        }
        console.log("POST PROCESSED AVAILABLE THEMES: ", arr)
        setAvailableThemes(arr)
    }, [])

    const chartThemeRefresh = useCallback(() => {
        let arry = [...chartThemes]
        const chartindex = arry.findIndex((element) => element === JSON.parse(localStorage.getItem("chart-theme")).name.toLowerCase())
        if (chartindex !== -1) {
            arry.splice(chartindex, 1);
        }
        setAvailableChartThemes(arry)
    }, [])

    useEffect(() => {
        props.newTheme &&
            updateThemeCard(props.newTheme);
    }, [props.newTheme])

    const updateThemeCard = theme => {
        const key = _.keys(theme)[0];
        const updated = { ...data, [key]: theme[key] };
        setData(updated);
    }

    const ThemeCard = props => {
        // console.log("THEME CARD PROPS: ", props)
        return (

            <Col onClick={(theme) => themeSwitcher(props.theme)} className="theme-wrapper" md={2}>
                <div style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.primary}` }}
                    className="theme-layer1">
                    <span className="theme-val2" style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.quarternary}` }}></span>
                </div>
                <div style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.secondary}` }}
                    className="theme-layer2">
                    <span className="theme-check" style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.quarternary}` }}></span>
                    <span className="theme-val" style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.quarternary}` }}></span>
                </div>
                <div style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.secondary}` }}
                    className="theme-layer3">
                    <span className="theme-check" style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.quarternary}` }}></span>
                    <span className="theme-val" style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.quarternary}` }}></span>
                </div>
                <div style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.tertiary}` }}
                    className="theme-layer4">
                    <span className="theme-check" style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.quarternary}` }}></span>
                    <span className="theme-val" style={{ backgroundColor: `${data[_.camelCase(props.theme.name)].colors.quarternary}` }}></span>
                </div>
                <div style={{
                    backgroundColor: `${data[_.camelCase(props.theme.name)].colors.secondary}`,
                    color: `${data[_.camelCase(props.theme.name)].colors.body}`
                }}
                    className="theme-button">
                    {props.theme.name}
                </div>
            </Col>

        )
    }

    const ChartThemeCard = props => {
        const primaryOptions = {
            chart: {
                type: "pie",
                innerWidth: 80,
                height: 200,
                width: 200,
                backgroundColor: "var(--card_body_color)",
                color: "var(--text_color)"
            },
            marginRight: -100,
            colors: props.chartTheme.colors,

            title: {
                align: "center",
                text: props.chartTheme.name,
                style: {
                    fontSize: 'var(--default)',
                    textOutline: false,
                    color: "var(--text_color)"
                }
            },
            xAxis: {
                type: "category",
            },
            plotOptions: {
                series: {
                    borderWidth: 1,
                    dataLabels: {
                        enabled: true,
                        distance: -10,
                    },
                    cursor: "pointer",
                },
            },
            series: [{
                name: 'Theme',
                colorByPoint: true,
                data: [{ name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }
                ]
            }],
            exporting: {
                enabled: false
            }
        };
        return (

            <Col onClick={(chartTheme) => chartThemeSwitcher(props.chartTheme)} className="theme-wrapper" md={2}>
                <div className="chart-container"><HighchartsReact highcharts={Highcharts} options={primaryOptions} />
                </div>
            </Col>

        )
    }

    const defaultTheme = {
        themeName: "",
        priColor: "#350d36",
        secColor: "#3f0e40",
        terColor: "#1164a3",
        quarColor: "#9f869f",
        bgColor: "#FFFFFF",
        txtColor: "#000000",
        font: "Arial"
    };
    const [state, setState] = useState(defaultTheme);
    const [newTheme, setNewTheme] = useState({});
    const getThemeObj = () => {
        const themeObj = {};
        themeObj[_.camelCase(state.themeName)] = {
            "id": generate(),
            "name": state.themeName,
            "colors": {
                "primary": state.priColor,
                "secondary": state.secColor,
                "tertiary": state.terColor,
                "quarternary": state.quarColor,
                "body": state.bgColor,
                "text": state.txtColor,
                "button": {
                    "text": state.bgColor,
                    "background": state.priColor
                },
                "link": {
                    "text": state.txtColor,
                    "opacity": 1
                }
            },
            "font": state.font
        };
        return themeObj;
    }

    useEffect(() => {
        const updated = getThemeObj();
        setNewTheme({ ...updated });
    }, [state]);

    const handleChange = (evt) => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    const createTheme = () => {
        // setState({ ...defaultTheme });
        Object.assign(themesFromStore, newTheme)
        // console.log("THEMES FROM STORE @ CREATE THEME: ", themesFromStore)
        setToLS('all-themes', themesFromStore)
        setUserThemes(themesFromStore)
        setCreateTheme(true)
    }

    const setUserThemes = (themes) => {
        // console.log("ADDING THEMES: ", themes)
        const parameter_value_1 = JSON.stringify(themes);
        const parameter_name = "themes";
        const payload = { firm_id, parameter_name, parameter_value_1 };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        };
        // console.log(requestOptions)
        fetch(
            `${process.env.REACT_APP_BASE_URI}/workflow/personalization/set_params/`,
            requestOptions
        ).then((result) => result.json());
    };

    const updateThemes = useCallback(() => {
        let refreshed_themes = getFromLS('all-themes')
        setData(refreshed_themes)
        setThemes(_.keys(data))
    }, [])

    const activeChartOptions = {
        chart: {
            type: "pie",
            innerWidth: 80,
            height: 200,
            width: 200,
            backgroundColor: "var(--card_body_color)",
            color: "var(--text_color)"
        },
        marginRight: -100,
        colors: activeChartTheme.colors,

        title: {
            align: "center",
            text: activeChartTheme.name,
            style: {
                fontSize: '12px',
                textOutline: false,
                color: "var(--text_color)"
            }
        },
        xAxis: {
            type: "category",
        },
        plotOptions: {
            series: {
                borderWidth: 1,
                dataLabels: {
                    enabled: true,
                    distance: -10,
                },
                cursor: "pointer",
            },
        },
        series: [{
            name: 'Theme',
            colorByPoint: true,
            data: [{ name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }, { name: '', y: 10 }]
        }],
        exporting: {
            enabled: false
        }
    };
    const [filteredChartThemes, setFilteredChartThemes] = useState([]);

    useEffect(() => {
        let filteredCT = []
        for (let key in chartThemesFromStore) {
            const val = chartThemesFromStore[key]
            if (val['category'] == selectedOption) {
                filteredCT.push(val.name)
            }
        }
        setFilteredChartThemes(filteredCT)
    }, [])


    const [selectedOption, setSelectedOption] = useState("Default");
    const handleSelect = (selected) => {
        let value = selected.value;
        setSelectedOption(value);
        filterChartThemes(value)
    };
    const filterChartThemes = (value) => {
        let filteredCT = []
        for (let key in chartThemesFromStore) {
            const val = chartThemesFromStore[key]
            if (val['category'] == value) {
                filteredCT.push(val.name)
            }
        }
        setFilteredChartThemes(filteredCT)
    }
    const optionsData = [
        { id: "Default", Name: "Default" },
        { id: "PowerBI", Name: "PowerBI" },
        { id: "Tableu", Name: "Tableu" },
    ]
    const options = optionsData.map((item) => ({ value: item.id, label: item.Name }))

    if (create_theme) {
        return (
            <Navigate to={window.location.pathname} />
        )
    }
    return (
        <div className="themes-modal-wrapper">
            <Tabs
                defaultActiveKey={activeTab}
                id="uncontrolled-tab-example"
                className="mb-6"
                onSelect={updateThemes}
            >
                <Tab eventKey="select" title="Themes" className="tabs" >
                    <Row className="row">
                        <Col className="theme-wrapper" md={2}>
                            <div className="active-theme">
                                <div style={{ backgroundColor: activeTheme.colors.primary }}
                                    className="theme-layer1">
                                    <span className="theme-val2" style={{ backgroundColor: activeTheme.colors.quarternary }}></span>
                                </div>
                                <div style={{ backgroundColor: activeTheme.colors.secondary }}
                                    className="theme-layer2">
                                    <span className="theme-check" style={{ backgroundColor: activeTheme.colors.quarternary }}></span>
                                    <span className="theme-val" style={{ backgroundColor: activeTheme.colors.quarternary }}></span>
                                </div>
                                <div style={{ backgroundColor: activeTheme.colors.secondary }}
                                    className="theme-layer3">
                                    <span className="theme-check" style={{ backgroundColor: activeTheme.colors.quarternary }}></span>
                                    <span className="theme-val" style={{ backgroundColor: activeTheme.colors.quarternary }}></span>
                                </div>
                                <div style={{ backgroundColor: activeTheme.colors.tertiary }}
                                    className="theme-layer4">
                                    <span className="theme-check" style={{ backgroundColor: activeTheme.colors.quarternary }}></span>
                                    <span className="theme-val" style={{ backgroundColor: activeTheme.colors.quarternary }}></span>
                                </div>
                                <div style={{
                                    backgroundColor: activeTheme.colors.secondary,
                                    color: activeTheme.colors.body
                                }}
                                    className="theme-button">
                                    {activeTheme.name}
                                </div>
                            </div>
                        </Col>
                        {
                            availableThemes.length > 0 &&
                            availableThemes.map(theme => (
                                <ThemeCard theme={data[theme]} key={v4()} />
                            ))
                        }
                    </Row>

                </Tab>
                <Tab eventKey="create" title="Create theme" className="tabs">
                    <Row className="row create-theme">
                        <Col md="3">
                            <Row className="name-inputs">
                                <label htmlFor="th_name">Theme Name </label> {' '}
                            </Row>
                            <Row className="color-inputs">
                                <label htmlFor="pri_color">Primary Color </label> {' '}
                            </Row>
                            <Row className="color-inputs">
                                <label htmlFor="sec_color">Secondary Color </label> {' '}
                            </Row>
                            <Row className="color-inputs">
                                <label htmlFor="ter_color">Tertiary Color </label> {' '}
                            </Row>
                            <Row className="color-inputs">
                                <label htmlFor="quar_color">Quarternary Color </label> {' '}
                            </Row>
                            <Row className="color-inputs">
                                <label htmlFor="bg_color">Background Color </label> {' '}
                            </Row>
                            <Row className="color-inputs">
                                <label htmlFor="txt_color">Text Color </label> {' '}
                            </Row>
                        </Col>
                        <Col md="3">
                            <Row className="name-inputs">
                                <input
                                    type="text"
                                    id="themeName"
                                    name="themeName"
                                    value={state.themeName}
                                    placeholder="Specify a name"
                                    onChange={handleChange} />
                            </Row>
                            <Row className="color-inputs">
                                <input type="color" id="pri_color" name="priColor" value={state.priColor} onChange={handleChange} />
                            </Row>
                            <Row className="color-inputs">
                                <input type="color" id="sec_color" name="secColor" value={state.secColor} onChange={handleChange} />
                            </Row>
                            <Row className="color-inputs">
                                <input type="color" id="ter_color" name="terColor" value={state.terColor} onChange={handleChange} />
                            </Row>
                            <Row className="color-inputs">
                                <input type="color" id="quar_color" name="quarColor" value={state.quarColor} onChange={handleChange} />
                            </Row>
                            <Row className="color-inputs">
                                <input type="color" id="bg_color" name="bgColor" value={state.bgColor} onChange={handleChange} />
                            </Row>
                            <Row className="color-inputs">
                                <input type="color" id="txt_color" name="txtColor" value={state.txtColor} onChange={handleChange} />
                            </Row>
                        </Col>

                        <Col md="6">
                            <Row className="no-gutter">
                                <Button
                                    style={{ margin: "1rem 0rem", padding: "10px 20px", display: "inline-block", float: "right", backgroundColor: state.priColor, color: state.bgColor, borderRadius: "10px" }}
                                    onClick={() => createTheme()}
                                    disabled={state.themeName.trim().length === 0}>
                                    Create theme
                                </Button>
                                {create_theme ?
                                    <div className="create-theme-message">
                                        <p>New theme created!</p>
                                    </div> : ""
                                }
                            </Row>
                            <span><b>Preview</b></span>

                            <div style={{ backgroundColor: state.priColor }}
                                className="theme-layer1">
                                <span className="theme-val2" style={{ backgroundColor: state.quarColor }}></span>
                            </div>
                            <div style={{ backgroundColor: state.secColor }}
                                className="theme-layer2">
                                <span className="theme-check" style={{ backgroundColor: state.quarColor }}></span><span className="theme-val" style={{ backgroundColor: state.quarColor }}></span>
                            </div>
                            <div style={{ backgroundColor: state.secColor }}
                                className="theme-layer3">
                                <span className="theme-check" style={{ backgroundColor: state.quarColor }}></span><span className="theme-val" style={{ backgroundColor: state.quarColor }}></span>
                            </div>
                            <div style={{ backgroundColor: state.terColor }}
                                className="theme-layer4">
                                <span className="theme-check" style={{ backgroundColor: state.quarColor }}></span><span className="theme-val" style={{ backgroundColor: state.quarColor }}></span>
                            </div>
                            <div style={{
                                backgroundColor: state.priColor,
                                color: state.bgColor
                            }}
                                className="theme-button">
                                {state.themeName}
                            </div>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="select chart" title="Chart themes" className="tabs" >
                    <div className="select-holder">
                        <Select
                            options={options}
                            aria-label="Default"
                            closeMenuOnSelect={true}
                            autosize={true}
                            defaultValue={options[0]}
                            menuPosition="fixed"
                            className='cat-selector'
                            onChange={(e) => handleSelect(e)} />
                    </div>
                    <Row className="row">
                        <Col className="theme-wrapper" md={2}>
                            <div className="active-chart-theme">
                                <div className="chart-container">
                                    <HighchartsReact highcharts={Highcharts} options={activeChartOptions} />
                                </div>
                            </div></Col>
                        {
                            filteredChartThemes.length > 0 &&
                            filteredChartThemes.map(chartTheme => (
                                <ChartThemeCard chartTheme={chartData[chartTheme]} key={chartData[chartTheme].id} />
                            ))
                        }
                    </Row>

                </Tab>

            </Tabs>
        </div>

    )
}