import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    Form
} from "react-bootstrap";
import { getFromLS } from "../../../utils/storage";
import { FormContext } from "../../reusable-components/InputFields/FormContext";
import Element from '../../reusable-components/InputFields/input';
import * as Icon from "react-bootstrap-icons";
import { logout } from "../../../utils";
import { toast } from "react-toastify";


export const PreviewFullWindowModal = (props) => {
    console.log("PROPS: ", props)
    const [previewComponents, setPreviewComponents] = useState([{}])
    const [formTitle, setFormTitle] = useState("")
    const [formUrl, setFormUrl] = useState("")
    useEffect(() => {
        const newComponents = [...props.previewcomponents]
        for (let component of newComponents) {
            if (component["approval"]) {
                Object.assign(component, { "show": false })
            }
        }
        setPreviewComponents(newComponents)
    }, [props.previewcomponents])
    useEffect(() => {
        setFormTitle(props.formtitle)
    }, [props.formtitle])
    useEffect(() => {
        setFormUrl(props.formurl)
    }, [props.formurl])

    useEffect(() => {
        console.log("PREVIEW COMPONENTS: ", previewComponents)
    }, [previewComponents])


    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const handleChange = (field_id, event) => {
        const newList = [...previewComponents]
        newList.forEach(field => {
            const { type, id } = field;
            if (field_id === id) {
                switch (type) {
                    case 'checkbox':
                        field['value'] = event.target.checked;
                        break;

                    case 'select':
                        field['value'] = event.value;
                        break;

                    case 'file':
                        field['value'] = event[0];
                        break;

                    case 'date':
                        field['value'] = event;
                        break;

                    default:
                        field['value'] = event.target.value;
                        break;
                }
            }
            const newListTwo = [...newList]
            setPreviewComponents(newListTwo)
        })
    }

    const uploadData = (formUrl) => {
        let payloadFormat;
        payloadFormat = {
            "Response": previewComponents
        }
        const token = getFromLS("token")
        const postOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payloadFormat),
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/${formUrl}/`, postOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    props.onHide()
                    toast.success(
                        "Data successfully saved!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                } else {
                    toast.error(
                        "Saving failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="custom-backdrop"
            centered
            className="full-modal"
            scrollable="false"
        >
            <Modal.Header className="preview-modal-header" closeButton>
            </Modal.Header>
            <Modal.Body className="preview-modal">
                <Row className='no-gutter'>
                    <Col md={1}></Col>
                    <Col md={10}>
                        <h4 style={{ margin: "10px 0px 20px 0px", textAlign: "center" }}>{formTitle}</h4>
                        <FormContext.Provider value={{ handleChange }}>
                            <Form className='no-padding'>
                                <Row className='no-gutter'>
                                    {
                                        previewComponents &&
                                        previewComponents.map((field, i) =>
                                            <Element
                                                key={i}
                                                field={field}
                                            />
                                        )
                                    }
                                </Row>
                            </Form>
                        </FormContext.Provider>
                    </Col>
                    <Col md={1}></Col>
                </Row>
                <Row className="no-gutter flex-row-center-center">
                    <Button className="custom-button" style={{
                        width: "fit-content",
                        display: "inline-block",
                        padding: "10px 20px",
                        marginTop: 25
                    }} onClick={() => uploadData(formUrl)} >
                        <Icon.Upload style={{ fontSize: 20, margin: "0px 10px 0px 0px" }} />
                        Save
                    </Button>
                </Row>
            </Modal.Body>
            <Modal.Footer className="action-footer">
            </Modal.Footer>
        </Modal>
    )
}