const initialData = {
    columns: {
      'hubspot-source-column': {
        id: 'hubspot-source-column',
        source: 'Hubspot',
        title: 'Hubspot Source',
      },
      'safesend-source-column': {
        id: 'safesend-source-column',
        source: 'SafeSend',
        title: 'SafeSend Source',
      },
      'source-column': {
        id: 'source-column',
        title: 'Source'
      },
      'target-column': {
        id: 'target-column',
        title: 'Target'
      }
    },
    // Facilitate reordering of the columns
    columnOrder: ['hubspot-source-column', 'safesend-source-column', 'target-column'],
  };
  
  export default initialData;