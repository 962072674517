import React, {
    useState,
    useMemo,
    useCallback, 
    useRef,
    useEffect
} from "react";

import {
    Row,
    Col,
    Button,
    Modal
} from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact } from "ag-grid-react";
import { logout } from "../../../utils";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import '../DiffToolMainClientGridPage/DiffToolMainClientGridPage.css';
import * as Icon from "react-bootstrap-icons";

/**
 * Modal component for displaying the Diff Tool Reconciliation By Field Page.
 * @param {Object} props - Props for the modal.
 * @returns component for displaying the Diff Tool Reconciliation By Field Page.
 */
const ClientReconciliationByFieldPage = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);

    const [currFieldIndex, setCurrFieldIndex] = useState(1);
    const [fieldName, setFieldName] = useState("");
    const [sourceOfTruth, setSourceOfTruth] = useState("");
    const [totalFieldCount, setTotalFieldCount] = useState(0);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const [rowData, setRowData] = useState([]);
    const [clientReconciliationDetailsByField, setClientReconciliationDetailsByField] = useState([]);
    
    // Column definitions for AgGrid
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "No.", 
            field: "srno",
            maxWidth: 100,
            minWidth: 100,
            sortable: false,
            pinned: "left",
            hide: true
        },
        {
            headerName: "Select", 
            field: "select",
            maxWidth: 80,
            minWidth: 80,
            sortable: false,
            pinned: "left",
            filter: "agSetColumnFilter",
            filterParams: {
                values: [true, false],
            },
            cellStyle: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            cellRenderer: (params) => CheckboxRenderer(params, handleCellClick, selectedCheckboxes)
        }
    ]);

    const resetState = () => {
        setRefreshGrid(false);
        setCurrFieldIndex(0);
        setTotalFieldCount(0);
        setSelectedCheckboxes([]);
        setRowData([]);
        setClientReconciliationDetailsByField([]);
        setColumnDefs([
            {
                headerName: "No.", 
                field: "srno",
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                pinned: "left",
                hide: true
            },
            {
                headerName: "Select", 
                field: "select",
                maxWidth: 80,
                minWidth: 80,
                sortable: false,
                pinned: "left",
                filter: "agSetColumnFilter",
                filterParams: {
                    values: [true, false],
                },
                cellStyle: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
                cellRenderer: (params) => CheckboxRenderer(params, handleCellClick, selectedCheckboxes)
            }
        ]);
    };    

    const fetchClientReconciliationByFieldData = () => {
        // Reset state
        resetState();
        
        const currFieldIndex = localStorage.getItem("CurrFieldIndex");
        setCurrFieldIndex(currFieldIndex);
        
        const fieldName = JSON.parse(localStorage.getItem("FieldNames"))[currFieldIndex];
        setFieldName(fieldName);
        
        const sourceOfTruth = JSON.parse(localStorage.getItem("SourceOfTruths"))[currFieldIndex];
        setSourceOfTruth(sourceOfTruth);
        
        setTotalFieldCount(localStorage.getItem("TotalFieldCount"));

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        
        setRefreshGrid(true);

        const clientType = localStorage.getItem("ClientType") || "Individual";

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/diff-tool/client_reconciliation_details_by_field/?SourceFieldName=${fieldName}&ClientType=${clientType}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setClientReconciliationDetailsByField(data.data);
            })
            .finally(() => {
                setRefreshGrid(false);
            });
    };

    useEffect(() => {
        // Fetch data when modal is opened
        if (props.show) {
            fetchClientReconciliationByFieldData();
        } else {
            // reset state when modal is closed
            resetState();
        }
    }, [props.show]);


    // Reference to the AgGrid component
    const gridRef = useRef();

    /**
     * Callback to size columns to fit after the first data rendering.
     * @param {Object} params - Parameters for the callback.
     */
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    // Style for the AgGrid
    const gridStyle = useMemo(() => ({ height: "51vh", width: "100%" }), []);

    // State and callback for the grid API
    const [gridApi, setGridApi] = useState(undefined);
    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.closeToolPanel();
    }, []);
    
    useEffect(() => {
        if (clientReconciliationDetailsByField.length > 0) {

            const defaultSelectValue = false;
            const temp_rowData = clientReconciliationDetailsByField.map((row, index) => { 
                return {...row, srno: index + 1, select: defaultSelectValue };
            });

            setRowData(temp_rowData);

            // Check if keys are not already present in columnDefs
            const keysToAdd = Object.keys(clientReconciliationDetailsByField[0]).filter(key => !columnDefs.find(column => column.field === key));
            
            // Generate dynamic column definitions
            const dynamicColumnDefs = keysToAdd.map((key) => ({
                headerName: key,
                field: key,
                // Add cellRenderer only if the key is not in the excluded fields
                ...(["Client Name", "Cygnus Master Data", "Client Id"].includes(key)
                    ? {} // Don't add cellRenderer
                    : { cellRenderer: (params) => CheckboxRenderer(params, handleCellClick, selectedCheckboxes) })
            }));

            // Update columnDefs with dynamic column definitions
            setColumnDefs(prevDefs => [...prevDefs, ...dynamicColumnDefs]);

            // Initial state for checkbox selection
            const initialCheckboxesState = temp_rowData.reduce((acc, row, rowIndex) => {
                acc[row.srno] = {};
                keysToAdd.map((key) => {
                    acc[row.srno][key] = false;
                });

                acc[row.srno]["select"] = false;
                return acc;
            }, {});
            
            setSelectedCheckboxes(initialCheckboxesState);
        }
    }, [clientReconciliationDetailsByField]);


    // Use useEffect hook to watch for changes in selectedCheckboxes
    useEffect(() => {
        // Update columnDefs when selectedCheckboxes changes
        setColumnDefs(prevDefs => {
            // Map over the existing columnDefs and update the cellRenderer function
            return prevDefs.map(colDef => {
                // Check if the field is not one of the excluded fields and if it already doesn't have a cellRenderer
                if (colDef.cellRenderer) {
                    return {
                        ...colDef,
                        cellRenderer: (params) => CheckboxRenderer(params, handleCellClick, selectedCheckboxes) // Pass selectedCheckboxes here
                    };
                } else {
                    return colDef;
                }
            });
        });
        
    }, [selectedCheckboxes]); // Watch for changes in selectedCheckboxes

    
    // Default column definition for AgGrid
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),     
        []
    );

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => `No data available` } }, []);

    /**
     * Callback to handle cell click.
     * @param {Object} params - Parameters for the cell click event.
     */
    const handleCellClick = (params) => {
        const srno = params.data.srno;

        if(params.colDef.cellRenderer && !params.colDef.pinned) {
            const field = params.colDef.field;

            // Toggle the checkbox
            handleCheckboxChange(srno, field);
        }
    };

    /**
     * Callback to handle checkbox change.
     * @param {number} srno - srno of the row.
     * @param {string} field - Field of the checkbox.
     */
    const handleCheckboxChange = (srno, field) => {
        
        setSelectedCheckboxes(prevState => {
            const updatedState = { ...prevState };
            
            Object.keys(updatedState[srno]).forEach((key) => {
                updatedState[srno][key] = key === field ? updatedState[srno][key] == true ? false : true : false;
            });

            let isChecked = false;
            Object.keys(selectedCheckboxes[srno]).forEach((key) => {
                if(key != "select")
                    isChecked ||= selectedCheckboxes[srno][key]
            });
            
            updatedState[srno]["select"] = isChecked;
            
            // Create a temporary array to accumulate changes
            let updatedRowData = [...rowData];
            
            // Update the select property for the corresponding row in the temporary array
            updatedRowData = updatedRowData.map(row => 
                row.srno === srno ? { 
                    ...row, select: isChecked
                } : row
            );

            // Update the rowData state after iterating over all nodes
            setRowData(updatedRowData);
            
            return updatedState;
        });
    };

    const previousClient = () => {
        const new_currFieldIndex = parseInt(currFieldIndex) - 1;
        if(new_currFieldIndex >= 0) {
            localStorage.setItem("CurrFieldIndex", new_currFieldIndex);
            resetState();
            fetchClientReconciliationByFieldData();
        }
    };
    
    const nextClient = () => {
        const new_currFieldIndex = parseInt(currFieldIndex) + 1;
        if(new_currFieldIndex < totalFieldCount) {
            localStorage.setItem("CurrFieldIndex", new_currFieldIndex);
            resetState();
            fetchClientReconciliationByFieldData();
        }
    };

    const reconcile = () => {
        // Initialize empty object to store reconciled data
        const reconciledData = [];
        
        // Iterate over rowData to extract reconciled data
        rowData.forEach(row => {
            const rowData = {};
            Object.keys(row).forEach(key => {
                // Check if the checkbox is checked and it's not the "select" column
                if (selectedCheckboxes[row.srno][key] && key !== "select") {
                    // Get the label of the selected checkbox
                    const label = row[key];
                    
                    // Add the pair to the reconciled data object
                    rowData[fieldName] = label;

                    rowData["ClientMasterId"] = row["Client Id"];
                }
            });

            if (Object.keys(rowData).length > 0)
                reconciledData.push(rowData)
        });
        
        if (Object.keys(reconciledData).length > 0) {
            const clientType = localStorage.getItem("ClientType") || "Individual";
            
            const requestBody = {
                "ClientType": clientType,
                "data": reconciledData
            }

            // Reset state
            resetState();

            // Make the PUT request
            fetch(`${process.env.REACT_APP_BASE_URI}/mdm/diff-tool/client_reconciliation_details/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    // Handle success

                    // Fetch updated data after reconciliation
                    fetchClientReconciliationByFieldData();
                    
                    toast.success(
                        "Reconciliation successful!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                } else {
                    toast.error(
                        `Failed to reconcile: ${data.message}`,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch(error => {
                // Handle error
                console.error('Error reconciling data:', error);
            });
        } else {
            // Show a toast when the condition is not met
            toast.error(
                "No data to reconcile!",
                {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: "toastify-color-error",
                }
            );
        }
    };

    // JSX for the page component
    return (
        <>
            {!refreshGrid
                ? 
                <>
                    <Row className='no-gutter'>
                        <Col md={12} className="d-flex justify-content-end" style={{ float: "right", marginTop: "-5rem" }}>
                            <div className="form-select-wrapper">
                                <Button className="custom-button" onClick={() => reconcile()}>
                                    Reconcile
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className='no-gutter'>
                        <Col md={9} className="flex-row-space-center">
                            <p className="display-6" style={{ height: "fit-content", marginBottom: 0, width: "fit-content" }}>
                                Field: {fieldName}
                            </p>
                        </Col>
                        <Col md={3} className="d-flex justify-content-end">
                            <div className="navigator" style={{ marginBottom: "-3rem" }}>
                                <Button className="nav-direction" onClick={() => previousClient()}><Icon.CaretLeftFill /> </Button>
                                <span className="paging-panel"> Field {parseInt(currFieldIndex) + 1} of {totalFieldCount} </span>
                                <Button className="nav-direction" onClick={() => nextClient()}> <Icon.CaretRightFill /></Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className='no-gutter'>
                        <Col md={12}>
                            <div className="ag-theme-balham" style={gridStyle}>
                                <AgGridReact
                                    key={refreshGrid}
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    alwaysShowHorizontalScroll={false}
                                    alwaysShowVerticalScroll={false}
                                    rowHeight={25}
                                    rowSelection={"none"}
                                    suppressContextMenu={true}
                                    suppressCellFocus={true}
                                    onFirstDataRendered={onFirstDataRendered}
                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="note-message">
                            <div className="note-header">
                                <Icon.InfoCircleFill className="note-icon" />
                                <p className="note-text">Data synchronization with vendor tables may take around 5 minutes to complete after the reconciliation process.</p>
                            </div>
                        </div>
                    </Row>
                    <ToastContainer />
                </>
                :
                <div
                    className="flex-row-center-center"
                    style={{ marginTop: "11%" }}
                >
                    <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                        <Bars
                            height="80"
                            width="80"
                            color="var(--theme1_primary)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                        Loading your data
                    </div>
                </div>
            }
        </>
    )
};

 /**
 * Checkbox renderer component for AgGrid.
 * @param {Object} params - Props for the checkbox renderer.
 * @param {Object} selectedCheckboxes - Object containing the selected checkboxes state.
 * @returns Checkbox input element with optional label.
 */
const CheckboxRenderer = (params, handleCellClick, selectedCheckboxes) => {
    const field = params.colDef.field;
    const srno = params.data.srno;
    const isSelectField = field === "select";
    const isChecked = selectedCheckboxes[srno] ? selectedCheckboxes[srno][field] : false;

    return (
        <div>
            {(params.value || isSelectField) && (
                <div
                    className="checkbox-container"
                    onClick={() => handleCellClick(params)}
                >
                    <input 
                        type="checkbox" 
                        checked={isChecked}
                        disabled={isSelectField} // Add disabled attribute based on the condition
                    />
                    {/* Render span only if the field is not "Select"  */}
                    {!isSelectField && <span style={{ marginLeft: 10 }}>{params.value}</span>}
                </div>
            )}
        </div>
    );
};
export default ClientReconciliationByFieldPage;