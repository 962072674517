import { AgGridReact } from "ag-grid-react";
import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import {
    Image,
    Row,
    Col,
    Button,
    Modal,
    Table,
    Alert,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Select from "react-select";
import { logout } from "../../../utils/index.js";
import { getValuesAsList, getKeyByValue, OptionRenderer } from "../../../utils/index.js"
import { ThreeCircles, Bars } from "react-loader-spinner";

import { v4 } from "uuid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import './RulesModal.css';

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { toast } from "react-toastify";
import isEqual from 'lodash/isEqual';

const RulesModal = (props) => {
    const [rowHeight, setRowHeight] = useState(25);
    const [selectedField, setSelectedField] = useState((props.selectedField ? { value: props.selectedField, label: props.selectedField } : null));
    const [operator, setOperator] = useState({ value: '=', label: '=' });
    const [conditionValue, setConditionValue] = useState("");
    const [defaultValue, setDefaultValue] = useState((props.defaultValue ? { value: props.defaultValue, label: props.defaultValue } : { value: 'Blank', label: 'Blank' }));
    const [substitutionValue, setSubstitutionValue] = useState("");
    const [ruleName, setRuleName] = useState(props.ruleName);
    const [apiInProgress, setApiInProgress] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [rowData, setRowData] = useState(props.templateFieldRulesFilteredRowData);
    const [fieldRulesRowData, setFieldRulesRowData] = useState([]);
    const [selectedFieldError, setSelectedFieldError] = useState('');
    const [operatorError, setOperatorError] = useState('');
    const [conditionValueError, setConditionValueError] = useState('');
    const [defaultValueError, setDefaultValueError] = useState('');
    const [substitutionValueError, setSubstitutionValueError] = useState('');
    const [ruleNameError, setRuleNameError] = useState('');
    const [deletedRowData, setDeletedRowData] = useState([]);
    const [isSelectedFieldDisabled, setIsSelectedFieldDisabled] = useState(false);

    console.log(rowData)

    const [operatorsObject, setOperatorsObject] = useState({
        0: "=",
        1: "!="
    });
    
    const [defaultValuesObject, setDefaultValuesObject] = useState({
        0: "Blank",
        1: "Data Source"
    });

    const popover_1 = (
        <Popover id="popover-basic">
            <Popover.Header as="h6">Configurable Rules</Popover.Header>
            <Popover.Body>
                Select field, specify conditions, and set values to define how data should be processed.
            </Popover.Body>
        </Popover>
    );

    // Reference to the AgGrid component
    const gridRef = useRef();

    // Style for the AgGrid
    const gridStyle = useMemo(() => ({ height: "25vh", width: "100%" }), []);

    // Callback to handle sort change
    const onSortChanged = (e) => {
        e.api.refreshCells();
    };

    const saveTemplateRules = () => {
        let errorFlag = false;
        const empty_error_message = 'Field cannot be empty';

        if (!ruleName || !ruleName.trim()) {
            errorFlag = true;
            setRuleNameError(empty_error_message);
        }
        if (!selectedField || !selectedField.label.trim()) {
            errorFlag = true;
            setSelectedFieldError(empty_error_message);
        }
        if (!operator || !operator.label.trim()) {
            errorFlag = true;
            setOperatorError(empty_error_message);
        }
        if (!conditionValue || !conditionValue.trim()) {
            errorFlag = true;
            setConditionValueError(empty_error_message);
        }
        if (!defaultValue || !defaultValue.label.trim()) {
            errorFlag = true;
            setDefaultValueError(empty_error_message);
        }
        if (!substitutionValue || !substitutionValue.trim()) {
            errorFlag = true;
            setSubstitutionValueError(empty_error_message);
        }

        if (ruleName) {
            // Convert ruleName to lowercase
            const lowerCaseRuleName = ruleName.toLowerCase();
        
            // Convert selectedFieldsList items to lowercase and check for a match
            const isSelectedFieldNameMatch = props.selectedFieldsList
                .map(field => field.label.toLowerCase())
                .includes(lowerCaseRuleName);
            
            // Convert templateFieldRulesList items to lowercase and check for a match
            const isRuleFieldNameMatch = props.templateFieldRulesList
                .map(field => field.toLowerCase())
                .includes(lowerCaseRuleName);

            // Convert ruleName to lowercase
            const lowerCaseConditionValue = conditionValue.toLowerCase();

            // Convert row data condition values to lowercase and check for a match
            const isRuleConditionNameMatch = rowData
                .map(row => row.ConditionValue.toLowerCase())
                .includes(lowerCaseConditionValue);

            if (isSelectedFieldNameMatch) {
                errorFlag = true;
                setRuleNameError('The rule name must be distinct from the selected field names.');
            }
            if (isRuleFieldNameMatch && (props.ruleName != ruleName && rowData.length === 0)) {
                errorFlag = true;
                setRuleNameError('The rule name already exists. Please choose a different name.');
            }
            if (isRuleConditionNameMatch) {
                errorFlag = true;
                setConditionValueError('The condition value already exists.');
            }
        }
        

        if(errorFlag) {
            setTimeout(() => {
                setSelectedFieldError('');
                setOperatorError('');
                setConditionValueError('');
                setDefaultValueError('');
                setSubstitutionValueError('');
                setRuleNameError('');
            }, 5000);

            return;
        }

        setApiInProgress(true);
        setTimeout(() => {
            setApiInProgress(false);
        }, 2000);

        addRowData();
    }

    const addRowData = () => {
        setRefreshGrid(true);

        const templateId = localStorage.getItem("template_id");

        // Collect data from fields
        const newRow = {
            TemplateId: templateId,
            TemplateRuleId: 0,
            TemplateRuleDetailId: 0,
            RuleName: ruleName ? ruleName : "",
            SelectedFieldLabel: selectedField ? selectedField.label : "",
            SelectedFieldValue: selectedField ? selectedField.value : "",
            Operator: operator ? operator.label : "",
            ConditionValue: conditionValue,
            DefaultValue: defaultValue ? defaultValue.label : "",
            SubstitutionValue: substitutionValue,
            Active: 'Y',
        };

        setFieldRulesRowData(prevRowData => [...prevRowData, newRow]);

        // Append new row data to existing rowData
        setRowData(prevRowData => [...prevRowData, newRow]);
        
        setOperator({ value: '=', label: '=' });
        setConditionValue("");
        setSubstitutionValue("");

        setTimeout(() => {
            toast.success(
                "Added field rule successfully!",
                {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: "toastify-color-success",
                }
            );
            setRefreshGrid(false);
        }, 2000);

    }

    /**
     * Button cell renderer for the grid.
     * @param {Object} actionBtnProps - Props for the button cell renderer.
     * @returns Button for editing the row in the grid.
     */
    const ActionBtnsCellRenderer = (actionBtnProps) => {
        const deleteClick = () => {
            const { data } = actionBtnProps;
            const updatedRowData = { ...data, Active: 'N' };
            
            if(fieldRulesRowData.length) {
                setFieldRulesRowData(prevRowData =>
                    prevRowData.map(row =>
                        isEqual(row, data) ? updatedRowData : row
                    )
                );
            }
            else {
                setFieldRulesRowData(prevRowData => [...prevRowData, updatedRowData]);
            }

            setRowData(prevRowData => prevRowData.filter(row => row !== data));
            setDeletedRowData(prevData => [...prevData, data]);
        }
        return (
            <div>
                <Button className="icon-button" style={{
                        pointerEvents: "all"
                    }} 
                    onClick={() => deleteClick()}>
                    <Icon.Trash style={{ color: 'red' }} />
                </Button>
            </div>
        );
    }

    // Column definitions for AgGrid
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Selected Field", 
            field: "SelectedFieldLabel",
            minWidth: 150,
            sortable: false,
        },
        {
            headerName: "Operator", 
            field: "Operator",
            minWidth: 150,
            sortable: false
        },
        {
            headerName: "Condition Value", 
            field: "ConditionValue",
            minWidth: 150,
            sortable: false
        },
        {
            headerName: "Substitution Value", 
            field: "SubstitutionValue",
            minWidth: 200,
            sortable: false
        },
        {
            headerName: "Action", 
            field: "",
            cellRenderer: ActionBtnsCellRenderer,
            pinned: "right",
            maxWidth: 80,
            minWidth: 80,
            sortable: false
        }
    ]);

    // Default column definition for AgGrid
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),     
        []
    );

    let gridApi;
    // Callback when the grid is ready
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => `No data available` } }, []);

    const handleOkayBtnClick = () => {
        const key = localStorage.getItem("key");

        // Initialize the key if it's not present and add the new row
        const getUpdatedFieldRulesRowData = (parsedData, key, newRow) => ({
            ...parsedData,
            [key]: (parsedData[key] || []).concat(newRow)
        });
        
        let updatedFieldRulesRowData = null;

        const savedData = localStorage.getItem('FieldRulesRowData');
        const parsedData = savedData ? JSON.parse(savedData) : {};

        if(rowData && rowData.length) {
            let newDefaultValueFieldRulesRowData = fieldRulesRowData.map(row => ({ ...row, DefaultValue: defaultValue.value }));
            if(!newDefaultValueFieldRulesRowData.length) {
                rowData[0].DefaultValue = defaultValue.value;
                if(!parsedData[key]) {
                    newDefaultValueFieldRulesRowData = [rowData[0]]
                }
                else {
                    let ruleExists = false;
                    
                    // Update the object if RuleName exists
                    parsedData[key] = parsedData[key].map(row => {
                        if (row.RuleName === ruleName) {
                            ruleExists = true;
                            return { ...row, DefaultValue: defaultValue.value };
                        }
                        return row;
                    });

                    // If no object with RuleName exists, create a new one
                    if (!ruleExists)
                        parsedData[key].push(rowData[0]);
                }
            }
            console.log(newDefaultValueFieldRulesRowData);
            updatedFieldRulesRowData = getUpdatedFieldRulesRowData(parsedData, key, newDefaultValueFieldRulesRowData);
            updatedFieldRulesRowData = getUpdatedFieldRulesRowData(parsedData, key, newDefaultValueFieldRulesRowData);
        }
        else {
            updatedFieldRulesRowData = getUpdatedFieldRulesRowData(parsedData, key, fieldRulesRowData);
        }

        console.log("updatedFieldRulesRowData", updatedFieldRulesRowData)
        console.log("deletedRowData", deletedRowData)

        // Save the updated rowData to local storage
        localStorage.setItem('FieldRulesRowData', JSON.stringify(updatedFieldRulesRowData));
        props.onHide("okay", fieldRulesRowData, deletedRowData, ruleName, defaultValue.value);
    }

    useEffect(() => {
        // Function to disable fields
        const disableFields = () => {
            const ruleNameField = document.getElementById('rule-name');

            if (rowData.length > 0) {
                if (ruleNameField) ruleNameField.disabled = true;
                setIsSelectedFieldDisabled(true);
            } else {
                if (ruleNameField) ruleNameField.disabled = false;
                setIsSelectedFieldDisabled(false);
            }
        };

        // Call the function to disable or enable fields
        disableFields();
    }, [rowData]); // Depend on rowData

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="rules-modal"
            scrollable="false"
            style={{ marginTop: "20px" }}
        >
            <Modal.Header className="rules-modal-header" closeButton>
                <h4>Configurable Rules&nbsp;&nbsp;
                    <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        show={showTooltip}
                        overlay={popover_1}
                    >
                        <Icon.PatchQuestionFill style={{ fontSize: 14, marginTop: -2, cursor: "pointer" }}
                            onClick={() => {
                                setShowTooltip(true);
        
                                // Hide tooltip after 3 seconds
                                setTimeout(() => {
                                    setShowTooltip(false);
                                }, 2000);
                            }}
                        />
                    </OverlayTrigger>
                </h4> 
            </Modal.Header>
            <Modal.Body className="rules-modal-body">
                <Row className="no-gutter" style={{ padding: "0px", marginBottom: "15px" }}>
                    <Col md={4} style={{ padding: "0px 15px 0px 0px" }}>
                        <div className="flex-row-space-center">
                            <span className="rules-select-label">Rule Name</span>
                        </div>
                        <input 
                            id="rule-name"
                            type="text" 
                            className="form-control move-left custom-input"
                            placeholder={'Enter rule name'}
                            value={ruleName}
                            onChange={(event) => setRuleName(event.target.value)}
                            autoComplete="nope"
                        />
                      {ruleNameError && <span className="input-error">{ruleNameError}</span>}
                    </Col>
                    <Col md={4} style={{ padding: "0px 15px 0px 0px" }}>
                        <div className="flex-row-space-center">
                            <span className="rules-select-label">Default Value</span>
                        </div>
                        <Select
                            options={OptionRenderer(defaultValuesObject)}
                            value={defaultValue}
                            aria-label="Default"
                            closeMenuOnSelect={true}
                            autosize={true}
                            placeholder="Select default"
                            menuPosition="fixed"
                            defaultValue="Blank"
                            onChange={setDefaultValue}
                            className='custom-select'
                            classNamePrefix="react-select"
                        />
                        {defaultValueError && <span className="input-error">{defaultValueError}</span>}
                    </Col>
                    <Col md={4} style={{ padding: "0px" }}>
                    </Col>
                </Row>
                <Row className="no-gutter" style={{ padding: "0px" }}>
                    <Col md={4} style={{ padding: "0px 15px 0px 0px" }}>
                        <div className="flex-row-space-center">
                            <span className="rules-select-label">Selected Field</span>
                        </div>
                        <Select
                            options={props.selectedFieldsList}
                            value={selectedField}
                            aria-label="Default"
                            closeMenuOnSelect={true}
                            autosize={true}
                            placeholder="Select field"
                            menuPosition="fixed"
                            onChange={setSelectedField}
                            className='custom-select'
                            classNamePrefix="react-select"
                            isDisabled={isSelectedFieldDisabled}
                        />
                        {selectedFieldError && <span className="input-error">{selectedFieldError}</span>}
                    </Col>
                    <Col md={4} style={{ padding: "0px 15px 0px 0px" }}>
                        <div className="flex-row-space-center">
                            <span className="rules-select-label">Operator</span>
                        </div>
                        <Select
                            options={OptionRenderer(operatorsObject)}
                            value={operator}
                            aria-label="Default"
                            closeMenuOnSelect={true}
                            autosize={true}
                            placeholder="Select operator"
                            menuPosition="fixed"
                            onChange={setOperator}
                            className='custom-select'
                            classNamePrefix="react-select"
                        />
                        {operatorError && <span className="input-error">{operatorError}</span>}
                    </Col>
                    <Col md={4} style={{ padding: "0px" }}>
                        <div className="flex-row-space-center">
                            <span className="rules-select-label">Condition Value</span>
                        </div>
                        <input type="text" className="form-control move-left custom-input"
                            placeholder={'Enter condition value'}
                            value={conditionValue}
                            onChange={(event) => setConditionValue(event.target.value)}
                            autoComplete="nope"
                        />
                        {conditionValueError && <span className="input-error">{conditionValueError}</span>}
                    </Col>
                </Row>
                <Row className="no-gutter" style={{ padding: "15px 0px 0px 0px" }}>
                    <Col md={10} style={{ padding: "0px 15px 0px 0px" }}>
                        <div className="flex-row-space-center">
                            <span className="rules-select-label">Substitution Value</span>
                        </div>
                        <textarea type="text" className="form-control move-left custom-input"
                            placeholder={'Enter substitution value'}
                            value={substitutionValue}
                            onChange={(event) => setSubstitutionValue(event.target.value)}
                            autoComplete="nope"
                            style={{ minHeight: "100px", width: "107.5%" }}
                        />
                        {substitutionValueError && <span className="input-error">{substitutionValueError}</span>}
                    </Col>
                    <Col md={2} style={{ 
                        padding: "0px", 
                        display: "flex", 
                        flexDirection: "column", 
                        justifyContent: "flex-end", 
                        alignItems: "flex-end" 
                    }}>
                        <Button 
                            onClick={() => saveTemplateRules(props)} 
                            className={!apiInProgress ? "custom-button" : "api-progress"}
                            style={{ width: "60%" }}>
                            {
                                !apiInProgress ? 
                                <p className="icon-wrapper" style={{ marginBottom: 0, marginTop: 0 }}>
                                    Add
                                </p>
                                :
                                <>
                                    <ThreeCircles
                                        height="20"
                                        width="20"
                                        color="#4fa94d"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="three-circles-rotating"
                                        outerCircleColor="var(--theme1_primary)"
                                        innerCircleColor="var(--theme1_secondary)"
                                        middleCircleColor="var(--theme1_tertiary)"
                                    />
                                </>
                            }
                        </Button>
                    </Col>
                </Row>
                <Row className='no-gutter' style={{ padding: "15px 0px 0px 0px" }}>
                    <Col md="12" style={{ padding: "0px" }}>
                        {refreshGrid ? (
                            <div className="flex-row-center-center" style={{ marginTop: "3%" }}>
                                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                                    <Bars
                                        height="80"
                                        width="80"
                                        color="var(--theme1_primary)"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                    Loading your data
                                </div>
                            </div>
                        ) : (
                            <div className="ag-theme-balham" style={gridStyle}>
                                {console.log(rowData)}
                                <AgGridReact
                                    key={refreshGrid}
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    alwaysShowHorizontalScroll={false}
                                    alwaysShowVerticalScroll={false}
                                    rowHeight={rowHeight}
                                    rowSelection={"none"}
                                    suppressContextMenu={true}
                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="action-footer" style={{ paddingTop: 0, marginTop: 0 }}>
                <Row className='no-gutter' style={{ padding: 0, margin: 0 }}>
                    <Col md={6}>
                        <Button
                            onClick={handleOkayBtnClick}
                            className="custom-button"
                        >   
                            <p className="icon-wrapper" style={{ marginBottom: 0, marginTop: 0 }}>
                                OK
                            </p>
                        </Button>
                    </Col>
                    <Col md={6} style={{ padding: "0px 10px 0px 0px" }}>
                        <Button
                            onClick={() => props.onHide("cancel")}
                            className="custom-button"
                        >
                            <p className="icon-wrapper" style={{ marginBottom: 0, marginTop: 0 }}>
                                Cancel
                            </p>
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default RulesModal;