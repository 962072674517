import React, { useEffect, useState, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Row, Button, Tabs, Tab } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { ArrowClockwise } from 'react-bootstrap-icons';
import { Bars } from 'react-loader-spinner';
import { ActionGrid } from './Grid';
import { useNavigate } from 'react-router-dom';
import './chartTable.css';

const TopCustomers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [clickedSeriesData, setClickedSeriesData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [activeTab, setActiveTab] = useState('chart');
  const [tableHeader, setTableHeader] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/top_10_cust/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            alert('Session ended, please login again');
          } else {
            throw new Error('Request failed');
          }
        }

        const data = await response.json();
        setOriginalData(data);
        setChartData(data);

        if (!data?.Success || !data.Data || data.Data.length === 0) {
          setNoDataMessage('No data currently available');
        } else {
          setNoDataMessage('');
        }

        const responseTableData = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/top_10_cust`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!responseTableData.ok) {
          throw new Error('Request failed');
        }

        const seriesData = await responseTableData.json();
        const formattedData = seriesData.Data.map(item => ({
          ...item,
          ClientRevenue: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(item.ClientRevenue)
        }));
        
        setClickedSeriesData(formattedData);
        setTableData(formattedData);
        setShowTable(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onGridReady = useCallback((params) => {
    const gridApi = params.api;
    gridApi.closeToolPanel();
  }, []);

  const handlePieSliceClick = async (event) => {
    const clientName = event.point.category;

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/top_10_cust/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const seriesData = await response.json();
      setClickedSeriesData(seriesData.Data);
      setActiveTab('table');
      setShowTable(true);
      setTableHeader(clientName);
    } catch (error) {
      console.error('Error fetching series data:', error);
    }
  };

  const updateChart = () => {
    const options = {
      ...primaryOptions,
      series: [{
        name: 'Client Name',
        data: chartData.Data.map(item => ({
          y: item.ClientRevenue,
          // color: Highcharts.getOptions().colors[chartData.Data.indexOf(item) % Highcharts.getOptions().colors.length]
        })),
      }],
      plotOptions: {
        series: {
          point: {
            events: {
              click: handlePieSliceClick,
            },
          },
        },
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };

  const renderTable = () => {
    if (clickedSeriesData && clickedSeriesData.length > 0 && showTable) {
      return (
        <>
          <h5>Top 10 Clients</h5>
          <ActionGrid data={clickedSeriesData} onGridReady={onGridReady} />
        </>
      );
    }
  };

  const resetData = () => {
    if (originalData) {
      setChartData(originalData);
      if (tableData) {
        setClickedSeriesData(tableData);
        setShowTable(true);
        setTableHeader('');
      }
      updateChart();
      setActiveTab('chart');
    }
  };

  if (!chartData?.Success || !chartData.Data || chartData.Data.length === 0) {
    return <p>{noDataMessage}</p>;
  }

  const primaryOptions = {
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Top 10 Clients based on Revenue',
    },
    xAxis: {
      title: {
        text: 'Client Name',
      },
      categories: chartData.Data.map(item => item.ClientName),
    },
    yAxis: {
      title: {
        text: 'Client Revenue', // Change the title to 'Client Revenue'
      },
      gridLineWidth: 0
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 0,
    },
    series: [{
      name: 'Client Revenue', // Change series name
      data: chartData.Data.map((item, index) => ({
        y: item.ClientRevenue,
        name: item.ClientName,
      })),
    }],
    tooltip: {
      formatter: function () {
        return `Revenue: ${this.y}`;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            click: handlePieSliceClick,
          },
        },
      },
    },
  };
  
  const renderChart = () => (
    <HighchartsReact
      highcharts={Highcharts}
      options={primaryOptions}
    />
  );

  return (
    <div>
      {isLoading ? (
        <div className="loading-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Row className="dashboard-row">
            <div className="spinner-container" style={{ margin: '0 auto', textAlign: 'center' }}>
              <div className="spinner">
                <Bars height="80" width="80" color="var(--theme1_primary)" ariaLabel="bars-loading" visible />
                <h5>Loading Top 10 Client Details Report</h5>
              </div>
            </div>
          </Row>
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-1rem', float: 'right' }}>
          {/* <button className='btn-mini-card' onClick={handleBackClick}>
            <Icon.ArrowLeftCircleFill className="report-transporter" />
          </button> */}
          <button className='btn-mini-card' onClick={resetData}>
            <Icon.ArrowClockwise className="report-transporter" />
          </button>
        </div>
        
          <Tabs activeKey={activeTab} onSelect={setActiveTab}>
            <Tab eventKey="chart" title="Chart View">
              {renderChart()}
            </Tab>
            <Tab eventKey="table" title="Table View">
              <Row style={{ height: '80vh' }}>
                {renderTable()}
              </Row>
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default TopCustomers;
