export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("firmId");
  localStorage.removeItem("user");
  localStorage.removeItem("username");
  localStorage.removeItem("firmname");
  localStorage.removeItem("transfer")
  window.location.href = window.location.origin + "/login";
}

export function capitalizeFirstLetter(str) {
  return str.toString().charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Takes dictionary as input and returns values as list.
 * @param {*} dict: Dictionary input.
 * @returns: List of values.
 */
export const getValuesAsList = (dict) => {
  const values = []
  for (const key in dict) { values.push(dict[key]) }
  return values
}

/**
 * Takes dictionary as input and returns the key for provided value.
 * @param {*} dict: Dictionary and value.
 * @returns: key.
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

/**
 * 
 * @param {*} list: Takes list of data points and converts it into list of React Select options.
 * @returns List of React Select options
 */
export const OptionRenderer = (list) => {
  const options = []
  for (const feature in list) {
      const dict = {}
      dict['id'] = list[feature]
      dict['label'] = list[feature]
      dict['value'] = list[feature]
      options.push(dict)
  }
  return options
}

/**
 * 
 * @param {*} array: List of objects.
 * @param {*} key: Key to group by.
 * @returns: Grouped listy of objects based on key selected.
 */
export const groupBy = (array, key) => {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};



/**
 * 
 * @param {*} currentList: Original list of objects
 * @param {*} updatedList: Final list of objects
 * @param {*} key: Unique key of object
 * @returns This function will return list of newly added objects, updated objects from updated list and remaining original objects as it is from current list.
 */
export const updateList = (currentList, updatedList, key) => {
  updatedList.forEach(updatedObject => {
    const index = currentList.findIndex(currentObject => currentObject[key] === updatedObject[key]);
    if (index !== -1) {
      currentList[index] = updatedObject;
    } else {
      currentList.push(updatedObject);
    }
  });
  return currentList
}

/**
 * 
 * @param {*} originalList: Original list of objects
 * @param {*} updatedList: Final list of objects
 * @param {*} key: Unique key of object
 * @returns This function will return lists of newly added objects, updated objects, and deleted objects by comparing original list and updated list.
 */
export const compareLists = (originalList, updatedList, key) => {
  const updated = [];
  const removed = [];
  const added = [];

  for (const obj1 of originalList) {
    const obj2 = updatedList.find((o) => o[key] === obj1[key]);
    if (obj2) {
      if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        updated.push(obj2);
      }
    } else {
      removed.push(obj1);
    }
  }

  for (const obj2 of updatedList) {
    const obj1 = originalList.find((o) => o[key] === obj2[key]);

    if (!obj1) {
      added.push(obj2);
    }
  }

  return { updated, removed, added };
}


export function sortObj(list, key) {
  function compare(a, b) {
      a = a[key];
      b = b[key];
      var type = (typeof(a) === 'string' ||
                  typeof(b) === 'string') ? 'string' : 'number';
      var result;
      if (type === 'string') result = a.localeCompare(b);
      else result = a - b;
      return result;
  }
  return list.sort(compare);
}