import React, { useContext } from 'react'
import { FormContext } from '../FormContext';
import { Col, Image } from "react-bootstrap";
import Select from "react-select";
import "./customelements.css"

const CustomSelect = ({ id, source, label, placeholder, value, options, disabled, mandatory, hidden, md, margins }) => {
    const { handleChange } = useContext(FormContext)
    const marginTop = margins["top"] !== undefined && margins["top"] !== null ? margins["top"] : 5
    const marginRight = margins["right"] !== undefined && margins["right"] !== null ? margins["right"] : 5
    const marginBottom = margins["bottom"] !== undefined && margins["bottom"] !== null ? margins["bottom"] : 5
    const marginLeft = margins["left"] !== undefined && margins["left"] !== null ? margins["left"] : 5
    return (
        <Col md={md} className="pad-trbl-10 custom-select-holder" style={{ margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
            {label !== null ? <label htmlFor="exampleInputEmail1" className="form-label move-left">{source ? <Image src={source} style={{ width: 15, height: 15, borderRadius: 5, marginRight: 5 }} /> : ""}{label}{mandatory ? <span style={{ color: "red", marginLeft: 5 }}>*</span> : ""}</label> : <></>}
            <Select
                placeholder={"Select "}
                defaultValue={options[0]}
                onChange={event => handleChange(id, event)}
                options={options}
                className='custom-select'
                classNamePrefix="react-select"
                isDisabled={disabled}
                required={mandatory}
            />
            {/* <select className="form-select move-left custom-select" 
                    aria-label="Default select example"
                    onChange={event => handleChange(id, event)}
            >
                <option disabled selected hidden>{placeholder}</option>
                {options.length > 0 && options.map((option, i) =>
                    <option value={option.value} key={i} className="custom-option">{option.label}</option>

                )}
            </select> */}
        </Col>
    )
}

export default CustomSelect