import React from 'react'
import { Col } from "react-bootstrap";
import "./customelements.css"

const Header = ({ label, category, md, margins }) => {
    const marginTop = margins["top"] !== undefined && margins["top"] !== null ? margins["top"] : 5
    const marginRight = margins["right"] !== undefined && margins["right"] !== null ? margins["right"] : 5
    const marginBottom = margins["bottom"] !== undefined && margins["bottom"] !== null ? margins["bottom"] : 5
    const marginLeft = margins["left"] !== undefined && margins["left"] !== null ? margins["left"] : 5
    const HEADER_MAP = {
        "H1": <h1>{label}</h1>,
        "H2": <h2>{label}</h2>,
        "H3": <h3>{label}</h3>,
        "H4": <h4>{label}</h4>,
        "H5": <h5>{label}</h5>,
        "H6": <h6>{label}</h6>,
        "PARAGRAPH": <p style={{margin: 0}}>{label}</p>
    }
    return (
        <Col md={md} style={category !== "H6" ? { margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` } : { margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
            {label !== undefined ? (category !== undefined || category !== "" ? HEADER_MAP[category] : HEADER_MAP["H1"]) : <></>}
        </Col>
    )
}

export default Header