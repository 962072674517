import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Button
} from "react-bootstrap";
import { logout } from "../../../utils/index.js";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { ThreeCircles, Bars } from "react-loader-spinner";
import * as Icon from "react-bootstrap-icons";
import '../EngagementLetter.css';
import { ToastContainer, toast } from "react-toastify";
import MessageModal from '../MessageModal/MessageModal.js';

const ApprovalDetailsPage = (props) => {
    const [rowHeight, setRowHeight] = useState(25);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [approvalDetails, setApprovalDetails] = useState([]);
    const [userName, setUserName] = useState(localStorage.getItem("username"));
    const [userRole, setUserRole] = useState(localStorage.getItem("usertype"));
    const [messageModalShow, setMessageModalShow] = useState(false);
    const rejectLetterButtonRef = useRef(null);
    const [approveStatus, setApproveStatus] = useState(false);
    const [rejectStatus, setRejectStatus] = useState(false);
    
    const toastBasicConfig = {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    const toastSuccessConfig = {
        ...toastBasicConfig,
        className: "toastify-color-success",
    };

    const toastErrorConfig = {
        ...toastBasicConfig,
        className: "toastify-color-error",
    };

    const fetchApprovalPageData = useCallback(() => {
        // Reset state
        resetState();

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        setRefreshGrid(true);

        const params = `?UserName=${userName}&UserRole=${userRole}`;

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/processed_template_approval_details/${params}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                const details = data.data;

                setApprovalDetails(details);
            })
            .finally(() => {
                setRefreshGrid(false);
            });
    }, []);

    // Reference to the AgGrid component
    const gridRef = useRef();

    // Style for the AgGrid
    const gridStyle = useMemo(() => ({ height: "85vh", width: "100%" }), []);

    const removeFileExtension = (filename) => {
        return filename.replace(/\.[^/.]+$/, "");
    };

    const TemplateFileNameRenderer = (file_name_props) => {
        const template_id = file_name_props.data.TemplateId;

        const downloadTemplate = useCallback((template_id, file_name) => {
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };
    
            fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/download/template/?TemplateId=${template_id}`, getRequestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.blob();
                }).then((blob) => {
                    const href = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', file_name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
        }, []);

        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 0, hide: 100 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        Click to download
                    </Tooltip>
                }
                error={true}
            >
                <div style={{ padding: 0, margin: 0 }}>
                    <a className="icon-button download-links" style={{ padding: 0, justifyContent: "left" }} onClick={() => downloadTemplate(template_id, file_name_props.data["TemplateOriginalFileName"])}>
                        <span>
                            {removeFileExtension(file_name_props.data["TemplateOriginalFileName"])}
                        </span>
                    </a>
                </div>
            </OverlayTrigger>
        )
    }

    const CreatedDateRenderer = (created_date_props) => {

        const formatDateTime = (dateTimeString) => {
            // Convert the input string to a Date object in the specified timezone offset
            const date = new Date(dateTimeString + '-05:52'); // Append the timezone offset to the input string
    
            // Adjust to user's local timezone
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
    
            // Format hours, minutes, and seconds
            let hour = date.getHours();
            const minute = String(date.getMinutes()).padStart(2, '0');
            const second = String(date.getSeconds()).padStart(2, '0');
    
            // Determine AM/PM notation
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12; // Hour '0' should be '12'
            const formattedHour = String(hour).padStart(2, '0');
    
            // Format as "YYYY-MM-DD h:mm:ss A"
            return `${year}-${month}-${day} ${formattedHour}:${minute}:${second} ${ampm}`;
        };

        return (
            <span>
                {formatDateTime(created_date_props.data["CreatedDate"])}
            </span>
        )
    }

    const FileNameHeader = ({ headerName, IconComponent }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconComponent style={{ marginRight: '5px' }} />
                <span>{headerName}</span>
            </div>
        );
    };

    /**
     * Button cell renderer for the grid.
     * @param {Object} props - Props for the button cell renderer.
     * @returns Button for downloading and viewing the engagement letter.
     */
    const ApprovalDetailsBtnCellRenderer = (props) => {
        const getEngagementLetter = useCallback(async (fileName, containerName) => {
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };
            
            return await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/download/blob/?FileName=${fileName}&ContainerName=${containerName}`, getRequestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.blob();
                }).then((blob) => {
                    return blob;
                })
        }, []);

        const downloadEngagementLetterClick = async () => {
            const blob = await getEngagementLetter(props.data.LetterFileName, "engagementletterfiles");
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'engagement_letter.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        
        const viewClick = async () => {
            try {
                const blob = await getEngagementLetter(props.data.LetterFileName, "engagementletterfiles");
                const url = window.URL.createObjectURL(blob);
                const newWindow = window.open(url, '_blank');
                if (!newWindow) {
                    throw new Error('Failed to open new window.');
                }
            } catch (error) {
                console.error('Error viewing engagement letter:', error);
            }
        };
    
        return (
            <div className="icon-cell-container" style={{ justifyContent: "flex-start", gap: "13px"}}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            View document
                        </Tooltip>
                    }
                    error={true}
                >
                    <Button className="icon-button" style={{ padding: "0px", margin: "0px", width: "35%" }} 
                            onClick={() => viewClick()}>
                        <span className="file-icon" style={{ width: "max-content", height: "max-content" }}>
                            <img width="100%" height="100%" src="https://img.icons8.com/fluency-systems-regular/50/visible--v1.png" alt="visible--v1"/>
                        </span>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            Download document
                        </Tooltip>
                    }
                    error={true}
                >
                    <Button className="icon-button" style={{ padding: "0px", margin: "0px", width: "30%" }}
                            onClick={() => downloadEngagementLetterClick()}>
                            <Icon.Download />
                    </Button>
                </OverlayTrigger>
            </div>
        );
    }

    const defaultApprovalDetailsColumnDefs = [
        {
            headerName: "Client Id",
            field: "ClientId",
            sortable: false,
            minWidth: 200,
            checkboxSelection: true,
            headerCheckboxSelection: true
        },
        {
            headerName: "Client Name",
            field: "ClientName",
            minWidth: 260,
            sortable: false,
        },
        {
            headerName: "Client Email",
            minWidth: 200,
            field: "ClientEmail",
            sortable: false,
        },
        {
            headerName: "Template Name",
            field: "TemplateOriginalFileName",
            minWidth: 290,
            cellRenderer: TemplateFileNameRenderer,
            headerComponent: FileNameHeader, 
            headerComponentParams: {
                headerName: "Template Name",
                IconComponent: Icon.FileEarmarkWordFill
            },
            sortable: false,
        },
        {
            headerName: "Approval Status",
            field: "ApprovalStatus",
            minWidth: 130,
            sortable: false,
        },
        {
            headerName: "Created Date",
            field: "CreatedDate",
            minWidth: 170,
            cellRenderer: CreatedDateRenderer,
            sortable: false,
        },
        {
            headerName: "Action",
            field: "",
            pinned: "right",
            maxWidth: 80,
            minWidth: 80,
            cellRenderer: ApprovalDetailsBtnCellRenderer,
            sortable: false
        }
    ];

    const [approvalDetailsColumnDefs, setApprovalDetailsColumnDefs] = useState(defaultApprovalDetailsColumnDefs);

    useEffect(() => {
        fetchApprovalPageData();
    }, []);

    // Default column definition for AgGrid
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    let gridApi;
    // Callback when the grid is ready
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => !refreshGrid ? `No data available`:`Loading your data` } }, [refreshGrid]);

    const resetState = () => {
        setRefreshGrid(false);
        setApprovalDetails([]);
        setApprovalDetailsColumnDefs(defaultApprovalDetailsColumnDefs);
        setIsRowSelected(false);
    };

    // Function to handle row selection change
    const onSelectionChanged = useCallback(async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setIsRowSelected(selectedRows.length > 0); // Update state based on whether rows are selected
    }, []);

    const saveEngagementLetterApprovalDetails = (ApprovalStatus, message) => {
        // Get the AgGridReact instance from the ref
        const gridApi = gridRef.current.api;
        
        // Get selected rows
        const selectedRows = gridApi.getSelectedRows();

        if (selectedRows.length > 0) {
            const requestBody = selectedRows.map((row, index) => {
                return {...row, ApprovalStatus: ApprovalStatus, Message: message}
            })
            
            console.log("requestBody", requestBody);

            ApprovalStatus == "Approved" ? setApproveStatus(true) : setRejectStatus(true);

            fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/processed_template_approval_details/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }

                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    
                    toast.success(
                        `${ApprovalStatus} successfully!`,
                        toastSuccessConfig
                    );
                } else {
                    toast.error(
                        `${ApprovalStatus} failed: ${data.message}`,
                        toastErrorConfig
                    );
                }
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
            })
            .finally(() => {
                ApprovalStatus == "Approved" ? setApproveStatus(false) : setRejectStatus(false);
            });
        }
    }

    // Calculate the position of the modal based on the button's position
    const buttonPosition = rejectLetterButtonRef.current
    ? rejectLetterButtonRef.current.getBoundingClientRect()
    : { top: 0, left: 0 };

    const approveLetterBtnClick = () => {
        if(isRowSelected)
            saveEngagementLetterApprovalDetails("Approved", "");
        else {
            toast.error(
                `Please select one or more clients before approving.`,
                toastErrorConfig
            );
        }
    }
    
    const rejectLetterBtnClick = () => {
        if(isRowSelected)
            setMessageModalShow(true);
        else {
            toast.error(
                `Please select one or more clients before rejecting.`,
                toastErrorConfig
            );
        }
    }

    // JSX for the page component
    return (
        <>            
            {messageModalShow && (
                <div
                    style={{
                        position: 'absolute',
                        top: buttonPosition.bottom + window.scrollY + 2,
                        left: buttonPosition.left + window.scrollX - 302
                    }}
                >
                    <MessageModal
                        show={messageModalShow}
                        onConfirm={(message) => saveEngagementLetterApprovalDetails("Rejected", message)}
                        onHide={() => setMessageModalShow(false)}
                    />
                </div>
            )}
            <Row className='no-gutter'>
                <p className="display-6" style={{ height: "fit-content", width: "fit-content", padding: 0, marginBottom: 0 }}>
                    Approval Details
                </p>
            </Row>
            <Row className='no-gutter'>
                <Col md={12} className="d-flex justify-content-end" style={{ float: "right" }}>
                    
                    <div style={{ marginBottom: "0rem", marginRight: 0, marginTop: "-2.1rem" }}>
                        <Button 
                            onClick={approveLetterBtnClick}
                            style={{
                                marginRight: "1rem"
                            }}
                            className="custom-button"
                        >
                            {approveStatus ? (
                                <>
                                    <div style={{ marginRight: 6 }}>
                                        <ThreeCircles
                                            height="15"
                                            width="15"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="#4fa94d"
                                            innerCircleColor="#4fa94d"
                                            middleCircleColor="#4fa94d"
                                        />
                                    </div>
                                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                                        Approving...
                                    </p>
                                </>
                            ) : (
                                <p style={{ marginBottom: 0, marginTop: 0 }}>Approve Letter</p>
                            )}
                        </Button>
                    </div>
                    <div style={{ marginBottom: "0rem", marginRight: 0, marginTop: "-2.1rem" }}>
                        <Button
                            ref={rejectLetterButtonRef}
                            onClick={rejectLetterBtnClick}
                            style={{
                                marginRight: "1rem"
                            }}
                            className="custom-button"
                        >
                            {rejectStatus ? (
                                <>
                                    <div style={{ marginRight: 6 }}>
                                        <ThreeCircles
                                            height="15"
                                            width="15"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="#4fa94d"
                                            innerCircleColor="#4fa94d"
                                            middleCircleColor="#4fa94d"
                                        />
                                    </div>
                                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                                        Rejecting...
                                    </p>
                                </>
                            ) : (
                                <p style={{ marginBottom: 0, marginTop: 0 }}>Reject Letter</p>
                            )}
                        </Button>
                    </div>
                    <div style={{ marginBottom: "0rem", marginTop: "-1.75rem" }}>
                        {
                            refreshGrid ?
                                <div>
                                    <ThreeCircles
                                        height="20"
                                        width="20"
                                        color="#4fa94d"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="three-circles-rotating"
                                        outerCircleColor="var(--theme1_tertiary)"
                                        innerCircleColor="var(--theme1_secondary)"
                                        middleCircleColor="var(--theme1_primary)"
                                    />
                                </div>
                                :
                                <Icon.ArrowCounterclockwise onClick={() => fetchApprovalPageData()}
                                    style={{
                                        fontSize: 20,
                                        float: "right",
                                        border: "0px",
                                        cursor: "pointer"
                                    }}
                                />
                        }
                    </div>
                </Col>
            </Row>
            <Row className='no-gutter'>
                <Col md={12}>
                    <div className="ag-theme-balham" style={gridStyle}>
                        <AgGridReact
                            key={refreshGrid}
                            ref={gridRef}
                            rowData={approvalDetails}
                            columnDefs={approvalDetailsColumnDefs}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            alwaysShowHorizontalScroll={false}
                            alwaysShowVerticalScroll={false}
                            rowHeight={rowHeight}
                            rowSelection={"multiple"}
                            suppressContextMenu={true}
                            noRowsOverlayComponent={noRowsOverlayComponent}
                            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                            onSelectionChanged={onSelectionChanged}
                        />
                    </div>
                </Col>
            </Row>
            <ToastContainer />                
        </>
    );
};

export default ApprovalDetailsPage;