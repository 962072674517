import React from 'react'
import Checkbox from './elements/Checkbox';
import Input from './elements/Input';
import Password from './elements/Password';
import Date from './elements/Date';
import Time from './elements/Time';
import CustomSelect from './elements/Select';
import Daypicker from './elements/Daypicker';
import Numberinput from './elements/NumberInput'
import FileInput from './elements/File'
import Header from './elements/Header';
import Radio from './elements/Radio';

const Element = ({ field: { type, id, label, placeholder, value, options, example, disabled, hidden, pivot, pivotreverse, width, category, mandatory, approval, show, source, margins }, md }) => {
    // console.log("FIELD IN SCHEMA: ", type)
    
    // Update the source value to false for each field
    source = false;
    const defaultMargins = {
        "top": 5,
        "right": 5,
        "bottom": 5,
        "left": 5
    }

    switch (type) {
        case 'text':
            return (<Input
                id={id}
                source={source}
                label={label}
                placeholder={placeholder}
                value={value}
                example={example}
                disabled={disabled}
                mandatory={mandatory}
                hidden={hidden}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)
        case 'password':
            return (<Password
                id={id}
                source={source}
                label={label}
                placeholder={placeholder}
                value={value}
                example={example}
                disabled={disabled}
                mandatory={mandatory}
                hidden={hidden}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)
        case 'select':
            return (<CustomSelect
                id={id}
                source={source}
                label={label}
                placeholder={placeholder}
                value={value}
                options={options}
                disabled={disabled}
                mandatory={mandatory}
                hidden={hidden}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)
        case 'date':
            return (<Date
                id={id}
                source={source}
                label={label}
                placeholder={placeholder}
                value={value}
                options={options}
                example={example}
                disabled={disabled}
                hidden={hidden}
                mandatory={mandatory}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)
        case 'time':
            return (<Time
                id={id}
                source={source}
                label={label}
                placeholder={placeholder}
                value={value}
                options={options}
                example={example}
                disabled={disabled}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)
        case 'checkbox':
            return (<Checkbox
                id={id}
                source={source}
                label={label}
                value={value}
                disabled={disabled}
                pivot={pivot}
                pivotreverse={pivotreverse}
                mandatory={mandatory}
                hidden={hidden}
                md={md === undefined ? width : md}
                approval={approval}
                show={show}
                margins={margins !==undefined ? margins : defaultMargins}
            />)

        case 'daypicker':
            return (<Daypicker
                id={id}
                source={source}
                label={label}
                placeholder={placeholder !== undefined || placeholder !== null ? placeholder : ""}
                value={value}
                disabled={disabled}
                hidden={hidden}
                mandatory={mandatory}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)

        case 'numberinput':
            return (<Numberinput
                id={id}
                source={source}
                label={label}
                placeholder={placeholder !== undefined || placeholder !== null ? placeholder : ""}
                value={value}
                disabled={disabled}
                hidden={hidden}
                mandatory={mandatory}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)

        case 'file':
            return (<FileInput
                id={id}
                source={source}
                label={label}
                placeholder={placeholder !== undefined || placeholder !== null ? placeholder : ""}
                value={value}
                disabled={disabled}
                hidden={hidden}
                example={example}
                mandatory={mandatory}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)

        case 'radio':
            return (<Radio
                id={id}
                source={source}
                label={label}
                value={value}
                disabled={disabled}
                pivot={pivot}
                pivotreverse={pivotreverse}
                mandatory={mandatory}
                hidden={hidden}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)

        case 'header':
            return (<Header
                id={id}
                source={source}
                label={label}
                category={category}
                mandatory={mandatory}
                hidden={hidden}
                md={md === undefined ? width : md}
                margins={margins !==undefined ? margins : defaultMargins}
            />)

        default:
            return null;
    }


}

export default Element