export const client_onboarding = {
    "fields": {
        "79391d2b-a51c-434a-b6ef-b2d19d433455": {
            "id": "79391d2b-a51c-434a-b6ef-b2d19d433455",
            "schema": [
                {
                    "id": "79391d2b-a51c-434a-b6ef-b2d19d433455",
                    "label": "First name",
                    "mandatory": true,
                    "disabled": false,
                    "placeholder": "Untitled",
                    "type": "text",
                    "example": null,
                    "pivot": true,
                    "pivotreverse": false,
                    "category": "H1",
                    "value": "atultatke@gmail.com",
                    "options": [
                        {
                            "id": "79391d2b-a51c-434a-b6ef-b2d19d433455",
                            "label": "Untitled",
                            "value": "Untitled"
                        }
                    ],
                    "width": 6
                }
            ]
        },
        "804061d6-23e1-44da-aabe-fd8130d65f71": {
            "id": "804061d6-23e1-44da-aabe-fd8130d65f71",
            "schema": [
                {
                    "id": "804061d6-23e1-44da-aabe-fd8130d65f71",
                    "label": "Last name",
                    "mandatory": true,
                    "disabled": false,
                    "placeholder": "Untitled",
                    "type": "text",
                    "example": null,
                    "pivot": true,
                    "pivotreverse": false,
                    "category": "H1",
                    "value": "",
                    "options": [
                        {
                            "id": "804061d6-23e1-44da-aabe-fd8130d65f71",
                            "label": "Untitled",
                            "value": "Untitled"
                        }
                    ],
                    "width": 6
                }
            ]
        },
        "4f05c5f5-d806-4b1f-8ab1-932507526f98": {
            "id": "4f05c5f5-d806-4b1f-8ab1-932507526f98",
            "schema": [
                {
                    "id": "4f05c5f5-d806-4b1f-8ab1-932507526f98",
                    "label": "Username",
                    "mandatory": true,
                    "disabled": false,
                    "placeholder": "Untitled",
                    "type": "text",
                    "example": null,
                    "pivot": true,
                    "pivotreverse": false,
                    "category": "H1",
                    "value": "",
                    "options": [
                        {
                            "id": "4f05c5f5-d806-4b1f-8ab1-932507526f98",
                            "label": "Untitled",
                            "value": "Untitled"
                        }
                    ],
                    "width": 6
                }
            ]
        },
        "1d5b435c-0be1-4aa2-9d9d-2e0c1cf72af4": {
            "id": "1d5b435c-0be1-4aa2-9d9d-2e0c1cf72af4",
            "schema": [
                {
                    "id": "1d5b435c-0be1-4aa2-9d9d-2e0c1cf72af4",
                    "label": "Password",
                    "mandatory": true,
                    "disabled": false,
                    "placeholder": "Untitled",
                    "type": "password",
                    "example": null,
                    "pivot": true,
                    "pivotreverse": false,
                    "category": "H1",
                    "value": "",
                    "options": [
                        {
                            "id": "1d5b435c-0be1-4aa2-9d9d-2e0c1cf72af4",
                            "label": "Untitled",
                            "value": "Untitled"
                        }
                    ],
                    "width": 6
                }
            ]
        }
    },
    "columns": {
        "column": {
            "id": "column",
            "fieldIds": [
                "79391d2b-a51c-434a-b6ef-b2d19d433455",
                "804061d6-23e1-44da-aabe-fd8130d65f71",
                "4f05c5f5-d806-4b1f-8ab1-932507526f98",
                "1d5b435c-0be1-4aa2-9d9d-2e0c1cf72af4",
            ]
        }
    }
}


export const task_creation = {
    fields: {
    },
    columns: {
        'column': {
            id: 'column',
            fieldIds: [],
        },
    }
}

export const staff_creation = {
    fields: {
    },
    columns: {
        'column': {
            id: 'column',
            fieldIds: [],
        },
    }
}

export const custom = {
    fields: {
    },
    columns: {
        'column': {
            id: 'column',
            fieldIds: [],
        },
    }
}