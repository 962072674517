import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Select, { components } from "react-select";
import "./inputbox.css";
import { v4 } from "uuid";

// REQUIRED SPECIAL RENDERING INPUTS IMPORTS
import { RequiredCheckboxes } from './requiredcheckboxes';
import { RequiredRadios } from './requiredradiobuttons';
import { RequiredSelect } from './requiredselect';
import { Spacer } from './spacer';

// This mapper is used to render the selected option on input boxes when rerender happens
const INPUT_OPTION_MAPPER = {
    "text": {
        value: "text",
        label: "Text",
        id: "text",
        logo: <Icon.TextParagraph style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
    "password": {
        value: "password",
        label: "Password",
        id: "password",
        logo: <Icon.Key style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
    "numberinput": {
        value: "numberinput",
        label: "Number",
        id: "numberinput",
        logo: <Icon.Icon123 style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
    "checkbox": {
        value: "checkbox",
        label: "Checkbox",
        id: "checkbox",
        logo: <Icon.Check2Square style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
    "select": {
        value: "select",
        label: "Dropdown",
        id: "select",
        logo: <Icon.MenuButtonWide style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
    "radio": {
        value: "radio",
        label: "Choice",
        id: "radio",
        logo: <Icon.UiRadiosGrid style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
    "date": {
        value: "date",
        label: "Date",
        id: "date",
        logo: <Icon.Calendar2Date style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
    "header": {
        value: "header",
        label: "Title",
        id: "header",
        logo: <Icon.Fonts style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
    "file": {
        value: "file",
        label: "File",
        id: "file",
        logo: <Icon.FileEarmarkMedical style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
    },
}

const InputBox = (props) => {
    // console.log("INPUTBOX PROPS: ", props)
    const [label, setLabel] = useState(props.existingfieldschema.length > 0 ? props.existingfieldschema[0].label : "")
    const [radioGroup, setRadioGroup] = useState(props.existingfieldschema.length > 0 ? props.existingfieldschema[0].label : undefined)
    const [topMargin, setTopMargin] = useState((props.existingfieldschema[0] !== undefined && props.existingfieldschema[0].margins !== undefined ? props.existingfieldschema[0].margins["top"] : 5))
    const [rightMargin, setRightMargin] = useState((props.existingfieldschema[0] !== undefined && props.existingfieldschema[0].margins !== undefined ? props.existingfieldschema[0].margins["right"] : 0))
    const [bottomMargin, setBottomMargin] = useState((props.existingfieldschema[0] !== undefined && props.existingfieldschema[0].margins !== undefined ? props.existingfieldschema[0].margins["bottom"] : 5))
    const [leftMargin, setLeftMargin] = useState((props.existingfieldschema[0] !== undefined && props.existingfieldschema[0].margins !== undefined ? props.existingfieldschema[0].margins["left"] : 0))
    const [source, setSource] = useState(props.existingfieldschema.length > 0 ? props.existingfieldschema[0].source : false)
    const [activeToggle, setActiveToggle] = useState(props.existingfieldschema.length > 0 ? props.existingfieldschema[0].mandatory : false);
    const [fieldId, setFieldId] = useState(props.inputboxid)
    const [fieldSchema, setFieldSchema] = useState(props.existingfieldschema)
    const [selectedInput, setSelectedInput] = useState(props.existingfieldschema.length > 0 ? INPUT_OPTION_MAPPER[props.existingfieldschema[0].type] : INPUT_OPTION_MAPPER["text"])
    const [selectedWidth, setSelectedWidth] = useState()
    const [selectedHeader, setSelectedHeader] = useState()
    const [selectedField, setSelectedField] = useState()
    const [selectedAllFieldsCategory, setSelectedAllFieldsCategory] = useState()
    const [INPUT_MAP, SET_INPUT_MAP] = useState({ "text": "" })
    const [hiddenField, setHiddenField] = useState(props.existingfieldschema.length > 0 ? props.existingfieldschema[0].hidden : false)
    const [isTemplateFinalized, setIsTemplateFinalized] = useState(props.templatestatus)

    useEffect(() => {
        setIsTemplateFinalized(props.templatestatus)
    }, [props.templateStatus])

    /**
     * Update the properties of INPUT_MAP everytime the field schema and field id changes
     */
    useEffect(() => {
        SET_INPUT_MAP(
            {
                "header": "",
                "text": "",
                "numberinput": "",
                "password": "",
                "checkbox": <RequiredCheckboxes
                    baseid={fieldId}
                    fieldschema={fieldSchema}
                    selectedfields={selectedFieldOptions}
                    templatestatus={isTemplateFinalized}
                    addnewcomponent={(component) => AddNewComponentsToPreview(component)}
                    updatecomponent={(field, key, value) => UpdateComponentToPreview(field, key, value)}
                    removecomponent={(component) => RemoveComponentToPreview(component, false)}
                />,
                "radio": <RequiredRadios
                    baseid={fieldId}
                    fieldschema={fieldSchema}
                    selectedfields={selectedFieldOptions}
                    templatestatus={isTemplateFinalized}
                    radiogroup={fieldSchema.radiogroup ? fieldSchema.radiogroup : radioGroup}
                    addnewcomponent={(component) => AddNewComponentsToPreview(component)}
                    updatecomponent={(field, key, value) => UpdateComponentToPreview(field, key, value)}
                    removecomponent={(component) => RemoveComponentToPreview(component, false)}
                />,
                "select": <RequiredSelect
                    baseid={fieldId}
                    selectschema={fieldSchema}
                    updatecomponent={(field, key, value) => UpdateComponentToPreview(field, key, value)}
                    removecomponent={(field) => RemoveComponentToPreview(field, false)}
                />,
                "date": "",
                "file": ""
            }
        )
    }, [fieldId, fieldSchema])

    /**
     * Update the parent schema is current schema is updated and ot same as parent schema
     */
    useEffect(() => {
        if (props.existingfieldschema !== fieldSchema) {
            // if the radio button doesn't have a radio group id existing radio group id can be sent for it if inside the same input box context
            for(let field of fieldSchema){
                if(field.type === "radio" && field.radiogroup === ""){
                    field.radiogroup = radioGroup
                }
            }
            props.updatefieldschema(fieldSchema)
        }
    }, [fieldSchema])

    /**
     * Based on selected input update the current schema
     */
    useEffect(() => {
        // console.log("SELECTED INPUT : ", selectedInput)
        const index = fieldSchema.findIndex((obj) => obj.id === fieldId)
        const updatedActiveInputBox = [...fieldSchema]
        updatedActiveInputBox[index]["type"] = selectedInput.value
        setFieldSchema(updatedActiveInputBox)
    }, [selectedInput])

    // Setting default INPUT select options
    const [inputOptions, setInputOptions] = useState([
        {
            label: "Headings",
            options: [
                {
                    value: "header",
                    label: "Title",
                    id: "header",
                    logo: <Icon.Fonts style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
                }]
        },
        {
            label: "Inputs",
            options: [{
                value: "text",
                label: "Text",
                id: "text",
                logo: <Icon.TextParagraph style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
            },
            {
                value: "password",
                label: "Password",
                id: "password",
                logo: <Icon.Key style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
            },
                ,
            {
                value: "numberinput",
                label: "Number",
                id: "numberinput",
                logo: <Icon.Icon123 style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
            },
            {
                value: "checkbox",
                label: "Checkbox",
                id: "checkbox",
                logo: <Icon.Check2Square style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
            },
            {
                value: "radio",
                label: "Radio",
                id: "radio",
                logo: <Icon.UiRadios style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
            },
            {
                value: "select",
                label: "Dropdown",
                id: "select",
                logo: <Icon.MenuButtonWide style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
            },
            {
                value: "date",
                label: "Date",
                id: "date",
                logo: <Icon.Calendar2Date style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
            },
            {
                value: "file",
                label: "File",
                id: "file",
                logo: <Icon.FileEarmarkMedical style={{ fontSize: 20, color: "var(--theme1_primary)" }} />
            }]
        }
    ])

    // Setting default WIDTH select options
    const [widthOptions, setWidthOptions] = useState([
        {
            value: "12",
            label: "12 (100%)",
            id: "12"
        },
        {
            value: "11",
            label: "11",
            id: "11"
        },
        {
            value: "10",
            label: "10",
            id: "10"
        },
        {
            value: "9",
            label: "9 (75%)",
            id: "9"
        },
        {
            value: "8",
            label: "8 (66%)",
            id: "8"
        },
        {
            value: "7",
            label: "7",
            id: "7"
        },
        {
            value: "6",
            label: "6 (50%)",
            id: "6"
        },
        {
            value: "5",
            label: "5",
            id: "5"
        },
        {
            value: "4",
            label: "4 (33%)",
            id: "4"
        },
        {
            value: "3",
            label: "3 (25%)",
            id: "3"
        },
        {
            value: "2",
            label: "2",
            id: "2"
        },
        {
            value: "1",
            label: "1 (8%)",
            id: "1"
        }
    ])

    // Setting HEADER select options for TITLE input type
    const [headerOptions, setHeaderOptions] = useState([
        {
            value: "H1",
            label: <p style={{ fontSize: 20, marginBottom: 0 }}>Heading 1</p>,
            id: "H1",
        },
        {
            value: "H2",
            label: <p style={{ fontSize: 18, marginBottom: 0 }}>Heading 2</p>,
            id: "H2",
        },
        {
            value: "H3",
            label: <p style={{ fontSize: 16, marginBottom: 0 }}>Heading 3</p>,
            id: "6",
        },
        {
            value: "H4",
            label: <p style={{ fontSize: 14, marginBottom: 0 }}>Heading 4</p>,
            id: "H4",
        },
        {
            value: "H5",
            label: <p style={{ fontSize: 12, marginBottom: 0 }}>Heading 5</p>,
            id: "H5",
        },
        {
            value: "H6",
            label: <p style={{ fontSize: 10, marginBottom: 0 }}>Heading 6</p>,
            id: "H6",
        },
        {
            value: "PARAGRAPH",
            label: <p style={{ fontSize: 10, marginBottom: 0 }}>Paragraph</p>,
            id: "PARAGRAPH",
        }
    ])

    // Setting HEADER select options for TITLE input type
    const [selectedFieldOptions, setSelectedFieldOptions] = useState(props.selectedfieldoptions)
    useEffect(() => {
        setSelectedFieldOptions(props.selectedfieldoptions)
    }, [props.selectedfieldoptions])
    
    useEffect(() => {
        // console.log("selectedFieldOptions: ", selectedFieldOptions)
        // console.log("LBL PRE: ", props.existingfieldschema[0].label)
        if(selectedFieldOptions[0].value !== ""){
            const lbl = selectedFieldOptions.find((field) => field.label === props.existingfieldschema[0].label)
            // console.log("LBL: ", lbl)
            updateFieldCategory(lbl !== undefined ? lbl : selectedFieldOptions[0])
        }
    }, [selectedFieldOptions])
    

    // Custom react select option renderer
    const { Option, Control } = components;

    /**
     * Render option with logo and then title
     * @param {*} props: defined select props 
     * @returns: custom select option
     */
    const IconOption = props => {
        return (
            <Option {...props}>
                {props.data.logo} &nbsp;&nbsp;&nbsp;&nbsp;
                {props.data.label}
            </Option>
        )
    };

    /**
     * Icon option render controller
     * @param {*} param0: custom select option params
     * @returns: custom selected option 
     */
    const IconControl = ({ children, ...props }) => {
        return (
            <Control {...props}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {props.selectProps.value.logo} &nbsp;&nbsp;
                {children}
            </Control>
        );
    };

    /**
     * Add the provided component to current schema
     * @param {*} component: Field component
     */
    const AddNewComponentsToPreview = (component) => {
        setFieldSchema((fieldSchema) => [...fieldSchema, component])
    }

    /**
     * Update the property of field component inside the current schema with provided value
     * @param {*} field: Field component 
     * @param {*} key: The property to be changed
     * @param {*} value: The entered value in input field
     */
    const UpdateComponentToPreview = (field, key, value) => {
        const updatedComponents = [...fieldSchema]
        const index = updatedComponents.findIndex(q => q.id === field.id);
        if (index !== -1) {
            updatedComponents[index][key] = value;
        }
        setFieldSchema(updatedComponents)
    }

    /**
     * Update the property of field component inside the current schema with provided value
     * @param {*} field: Field component 
     * @param {*} key: The property to be changed
     * @param {*} value: The entered value in input field
     */
    const UpdateMarginToPreview = (field, key, side, value) => {
        const updatedComponents = [...fieldSchema]
        const index = updatedComponents.findIndex(q => q.id === field.id);
        if (index !== -1) {
            if (Object.keys(updatedComponents[index]).includes("margins")) {
                updatedComponents[index][key][side] = value;
            } else {
                Object.assign(updatedComponents[index], { "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 } })
                Object.assign(updatedComponents[index][key], { [side]: value });
            }
        }
        console.log("FIELD SCHEMA UPDATED: ", updatedComponents)
        setFieldSchema(updatedComponents)
    }

    /**
     * Remove the provided component from schema if inputboxflag is false else just remove the input box with all its field components
     * @param {*} field: Field component
     * @param {*} inputboxflag: Flag to control behaviour
     */
    const RemoveComponentToPreview = (field, inputboxflag) => {
        if (inputboxflag === true) {
            props.removeinputbox(fieldId)
        } else {
            const updatedComponents = [...fieldSchema]
            const index = updatedComponents.findIndex((obj) => obj.id === field.id)
            if (index !== -1) {
                updatedComponents.splice(index, 1)
            }
            setFieldSchema(updatedComponents)
        }
    }

    /**
     * Hide the provided component from schema
     * @param {*} field: Field component
     * @param {*} inputboxflag: Flag to control behaviour
     */
    const HideComponentToPreview = (field) => {
        const updatedComponents = [...fieldSchema]
        const index = updatedComponents.findIndex((obj) => obj.id === field.id)
        if (index !== -1) {
            // console.log("COMPONENT TO BE HIDDEN: ", updatedComponents[index])
            setHiddenField(!updatedComponents[index]["hidden"])
            updatedComponents[index]["hidden"] = !updatedComponents[index]["hidden"]
            // console.log("COMPONENT TO BE HIDDEN: ", updatedComponents[index])
        }

        setFieldSchema(updatedComponents)
    }

    /**
     * Updating the field width for any INPUT
     * @param {*} event: Width select trigger event
     */
    const updateFieldWidth = (event) => {
        setSelectedWidth(event)
        UpdateComponentToPreview({ "id": fieldId }, "width", parseInt(event.value))
    }

    /**
     * Updating the field required for any INPUT
     * @param {*} event: Width select trigger event
     */
    const updateFieldRequirement = () => {
        setActiveToggle(!activeToggle)
        UpdateComponentToPreview({ "id": fieldId }, "mandatory", !activeToggle)
    }

    /**
     * Updating header type for TITLE input
     * @param {*} event: Header select trigger event
     */
    const updateHeaderCategory = (event) => {
        setSelectedHeader(event)
        UpdateComponentToPreview({ "id": fieldId }, "category", event.value)
    }

    /**
     * Updating selected field type for all input
     * @param {*} event: Field select trigger event
     */
    const updateFieldCategory = (event) => {
        setSelectedField(event)
        setLabel(event.value)
        UpdateComponentToPreview({ "id": fieldId }, "label", event.label)
    }

    /**
     * Updating field category type
     * @param {*} event: Category select trigger event
     */
    const updateAllFieldsCategory = (event) => {
        setSelectedAllFieldsCategory(event)
        UpdateComponentToPreview({ "id": fieldId }, "allfields_category", event.value)
    }

    /**
     * Updating margins for the field
     * @param {*} event: Number input trigger event
     */
    const updateTopMargin = (event) => {
        setTopMargin(event.target.value)
        UpdateMarginToPreview({ "id": fieldId }, "margins", "top", event.target.value)
    }

    /**
     * Updating margins for the field
     * @param {*} event: Number input trigger event
     */
    const updateRightMargin = (event) => {
        setRightMargin(event.target.value)
        UpdateMarginToPreview({ "id": fieldId }, "margins", "right", event.target.value)
    }

    /**
     * Updating margins for the field
     * @param {*} event: Number input trigger event
     */
    const updateBottomMargin = (event) => {
        setBottomMargin(event.target.value)
        UpdateMarginToPreview({ "id": fieldId }, "margins", "bottom", event.target.value)
    }

    /**
     * Updating margins for the field
     * @param {*} event: Number input trigger event
     */
    const updateLeftMargin = (event) => {
        setLeftMargin(event.target.value)
        UpdateMarginToPreview({ "id": fieldId }, "margins", "left", event.target.value)
    }

    // The required toggle swith
    const Switch = ({ isToggled, onClick }) => {
        return (
            <label className="switch">
                <input type="checkbox" checked={isToggled} onChange={onClick} style={{ marginLeft: 10 }} />
                <span className="super-admin-slider super-admin-round"></span>
            </label>
        );
    };

    return (
        <Row className='no-gutter input-box'>
            <Col md={1} style={{ padding: 0, width: 20 }} className='flex-row-center-start input-box-col'>
                <div {...props.provided.dragHandleProps} className="field-dragger"><Icon.GripVertical /></div>
            </Col>
            <Col md={11} style={{ padding: 0 }} className="input-box-col">
                <Row className='no-gutter'>
                    {
                        selectedInput.value === "header" ?
                            <>
                                <Col md={5} className="input-box-col" style={{ width: "47%" }}>
                                    <label style={{ fontWeight: 500, fontSize: 8 }}>Header</label>
                                    <Select
                                        options={headerOptions}
                                        value={selectedHeader}
                                        defaultValue={headerOptions[0]}
                                        onChange={(event) => updateHeaderCategory(event)}
                                        placeholder={""}
                                        aria-label="Default"
                                        autosize={true}
                                        className='custom-select '
                                        classNamePrefix="react-select"
                                    />
                                </Col>
                                <Col md={12} style={{ marginTop: 10 }} className="input-box-col">
                                    <input type="text" className="form-control move-left custom-input" id="formTitle" aria-describedby="emailHelp"
                                        placeholder={"Untitled label"}
                                        value={label}
                                        onChange={event => {
                                            setLabel(event.target.value)
                                            UpdateComponentToPreview({ "id": fieldId }, "label", event.target.value)
                                        }}
                                    />
                                </Col>
                            </> :
                            ["checkbox", "radio"].includes(selectedInput.value) ? <></> :
                                <Col md={5} className="input-box-col" style={{ width: "47%" }}>
                                    <label style={{ fontWeight: 500, fontSize: 8 }}>Field</label>
                                    <Select
                                        options={selectedFieldOptions}
                                        value={selectedField}
                                        defaultValue={selectedFieldOptions[0]}
                                        onChange={(event) => updateFieldCategory(event)}
                                        placeholder={"Select field"}
                                        aria-label="Default"
                                        autosize={true}
                                        className='custom-select '
                                        classNamePrefix="react-select"
                                    />
                                </Col>}
                    <Col md={6} className="input-box-col" style={["checkbox", "radio"].includes(selectedInput.value)?{marginRight: 10}:{ marginLeft: 10 }}>
                        <label style={{ fontWeight: 500, fontSize: 8 }}>Type</label>
                        <Select
                            options={inputOptions}
                            value={selectedInput}
                            onChange={(event) => setSelectedInput(event)}
                            components={{ Control: IconControl, Option: IconOption }}
                            placeholder={""}
                            aria-label="Default"
                            autosize={true}
                            className='custom-select '
                            classNamePrefix="react-select"
                        />
                    </Col>
                    {
                        ["radio"].includes(selectedInput.value) ?
                            <Col md={5} className="input-box-col" style={{ width: "47%" }}>
                                <label style={{ fontWeight: 500, fontSize: 8 }}>Radio Buttons Group ID</label>
                                <input type="text" className="form-control move-left custom-input" id="radioGroup" aria-describedby="radioGroupHelp"
                                    placeholder={"Enter radio group ID"}
                                    value={radioGroup}
                                    onChange={event => {
                                        setRadioGroup(event.target.value)
                                        UpdateComponentToPreview({ "id": fieldId }, "radiogroup", event.target.value)
                                    }}
                                />
                            </Col> :
                            <></>
                    }
                    <Col md={12}>
                        {
                            INPUT_MAP[selectedInput.id]
                        }
                    </Col>
                </Row>
            </Col>
            <Row style={!["checkbox", "radio"].includes(selectedInput.value) ? { padding: "10px 0px", margin: "10px 0px 0px 0px", borderTop: "1px solid lightgray" } : { padding: "0px", margin: "0px", borderTop: "0px solid lightgray" }}>
                <Col md={4} className="input-box-col">
                    <div style={{ marginRight: 10 }}>
                        {!["checkbox", "radio"].includes(selectedInput.value) ?
                            <>
                                <label className="move-left" style={{ fontWeight: 500, fontSize: 8 }}>Width</label>
                                <Select
                                    options={widthOptions}
                                    value={selectedWidth}
                                    defaultValue={widthOptions[0]}
                                    onChange={(event) => updateFieldWidth(event)}
                                    placeholder={""}
                                    aria-label="Default"
                                    autosize={true}
                                    // menuPosition="fixed"
                                    className='custom-select width-select'
                                    classNamePrefix="react-select"
                                    style={{ marginRight: 10 }}
                                />
                            </>
                            : <></>}
                    </div>
                </Col>
                <Col md={8} className='flex-row-center-center input-box-col'>
                    {!["checkbox", "radio"].includes(selectedInput.value) ?
                        <>
                            <Spacer
                                topMargin={topMargin}
                                rightMargin={rightMargin}
                                bottomMargin={bottomMargin}
                                leftMargin={leftMargin}
                                updateTopMargin={(event) => updateTopMargin(event)}
                                updateRightMargin={(event) => updateRightMargin(event)}
                                updateBottomMargin={(event) => updateBottomMargin(event)}
                                updateLeftMargin={(event) => updateLeftMargin(event)}
                            />
                        </> :
                        <></>
                    }
                </Col>

            </Row>
            <Row className='no-gutter flex-row-space-center' style={{ borderTop: "1px solid lightgray", padding: "10px 0px 0px 0px" }}>
                <Col md={1} className="input-box-col">
                    <div style={{ width: "fit-content" }}>
                        {/* <Icon.SquareHalf style={{ fontSize: 20, color: "var(--theme1_primary)", width: "fit-content", paddingRight: 20 }} /> */}
                        {hiddenField
                            ?
                            <Icon.Eye style={{ fontSize: 20, color: "var(--theme1_primary)", width: "fit-content", cursor: "pointer", paddingRight: 20 }} onClick={() => HideComponentToPreview({ "id": fieldId }, true)} />
                            :
                            <Icon.EyeSlash style={{ fontSize: 20, color: "var(--theme1_primary)", width: "fit-content", cursor: "pointer", paddingRight: 20 }} onClick={() => HideComponentToPreview({ "id": fieldId }, true)} />
                        }
                    </div>
                </Col>
                <Col md={1}>
                    {
                        isTemplateFinalized !== "Final"
                            ?
                            <div style={{ width: "fit-content" }}>
                                <Icon.Trash style={{ fontSize: 20, color: "var(--theme1_primary)", width: "fit-content", cursor: "pointer", paddingRight: 20 }} onClick={() => RemoveComponentToPreview({ "id": fieldId }, true)} />
                            </div>
                            :
                            <></>
                    }
                </Col>
                <Col md={1} className="input-box-col">{source ? <Image src={source} style={{ width: 20, height: 20, borderRadius: 5 }} /> : ""}</Col>
                <Col md={5} className={"flex-row-center-center input-box-col"}>
                </Col>
                <Col md={4} className={"flex-row-center-center input-box-col"}>
                    {!["checkbox", "radio"].includes(selectedInput.value) ?
                        <div className={"flex-row-center-center"} style={{ width: "fit-content" }}>
                            Required &nbsp;
                            <Switch
                                isToggled={activeToggle}
                                onClick={() => updateFieldRequirement()}
                            />
                        </div> : <></>}
                </Col>
            </Row>
        </Row>
    )
}


export default InputBox;