import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useCallback, useRef } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

const gridParamsMapper = (result) => {
    if (result.length != 0) {
      const keys = Object.keys(result[0]) // You need to change this based on API response format in every report
      let jsonColDefs = keys.map(key => {
        return {
          field: key, headerName: GridColumnTitleRenderer(key)
        }
      });
      return jsonColDefs;
    }
  }

  /**
   * This function is used for formatting the default column names of the grid
   * @param {*} defaultName: Current name from API
   * @returns: Final column name
   */
  const GridColumnTitleRenderer = (defaultName) => {
    const splitWords = defaultName.split("_")
    for (let word in splitWords) {
      splitWords[word] = splitWords[word].charAt(0).toUpperCase() + splitWords[word].slice(1)
    }
    return splitWords.join(" ")
  }


export const ActionGrid = (props) => {
    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(
        () => ({ height: "100%", width: "100%" }),
        []
    );
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    useEffect(() => { 
        const res = gridParamsMapper(props.data);
        if(res != undefined) {
            setColumnDefs(res);
        }

    }, [props.data])

    // Declaring the api - grid connections
    const [columnDefs, setColumnDefs] = useState([
        //   { field: "ClientName", headerName: 'Client name',
        //   checkboxSelection: true,
        //   headerCheckboxSelection: true,
        //   suppressSizeToFit: true },
        //   { field: "AccountNo", headerName: 'Account No' },
        //   { field: "TypeCode", headerName: 'Task type' },
        //   { field: "ReturnStatus", headerName: 'Task description' },
        //   { field: "PeriodEnd", headerName: 'Period end' },
        //   { field: "CurrentDueDate", headerName: 'Due date' },
        //   { field: "Ext", headerName: 'Extension task' },
        //   { field: "categorized_status", headerName: 'Task' },
    ]);

    
    return (
        <div className="ag-theme-balham" style={gridStyle}>
            <AgGridReact
                ref={gridRef}
                rowData={props.data}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={props.onGridReady}
                alwaysShowHorizontalScroll={true}
                alwaysShowVerticalScroll={true}
                sideBar={"filters"}     
                rowSelection={'multiple'}
                rowHeight={25}
                rowMultiSelectWithClick={true}
                onFirstDataRendered={onFirstDataRendered}
            />
        </div>
    );
};