import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import {
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { logout } from "../../utils";
import { capitalizeFirstLetter } from "../../utils";
import { getFromLS } from "../../utils/storage";
import * as Icon from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Bars } from "react-loader-spinner";
import { PreviewFullWindowModal } from "./PreviewFullWindowModal";
import { toast } from "react-toastify";
import Select from 'react-select';

const MyClients = (props) => {
    const [data, setData] = useState({})
    const [allForms, setAllForms] = useState([])
    const [rowData, setRowData] = useState([])
    const [fieldNameMap, setFieldNameMap] = useState({})
    const [previewStructure, setPreviewStructure] = useState([])
    const [previewComponents, setPreviewComponents] = useState([])
    const [formTitle, setFormTitle] = useState("")
    const [formUrl, setFormUrl] = useState("")
    const [activeClientType, setActiveClientType] = useState()
    const [formDescription, setFormDescription] = useState("")
    const [previewFullWindowModalShow, setPreviewFullWindowModalShow] = useState(false)
    const [formOptions, setFormOptions] = useState([{ value: '', label: '' }])
    const [selectedFormOption, setSelectedFormOption] = useState({ value: '', label: '', url: '' })
    const [approvalConfig, setApprovalConfig] = useState({})

    const postOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            "FormPurpose": "Client Onboarding"
        }),
    };

    useEffect(() => {
        FetchApprovalConfiguration()
    }, [])


    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                // console.log("ALL FORMS: ", data)
                setAllForms(data.data)
            });
    }, [])

    useEffect(() => {
        const optionList = []
        for (let form of allForms) {
            const option = { "label": `${form.FormName} (${form.ClientType})`, "value": form.FirmFormId, "url": form.Url, "client_type": form.ClientType }
            optionList.push(option)
        }
        setFormOptions(optionList)
        if (optionList[0] !== undefined) {
            // console.log("OPTION LIST: ", optionList[0])
            setSelectedFormOption(optionList[0])
            setActiveClientType(optionList[0]["client_type"])
        }
    }, [allForms])

    const FetchFormRecords = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (selectedFormOption !== undefined && selectedFormOption.value !== '') {
            setFormTitle(selectedFormOption["label"])
            setFormUrl(selectedFormOption["url"])
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/responses/${selectedFormOption.value}`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    // console.log("FORM RESPONSES: ", data.data)
                    setData(data)
                });
        }
    }

    const FetchApprovalConfiguration = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/approval_config/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.data.length !== 0) {
                    const source_data = [{
                        "Category": "Clients created in MDM",
                        "Client Type": "Individual Client",
                        "Approval": data.data[0].MdmIndividual ? data.data[0].MdmIndividual : "No"
                    },
                    {
                        "Category": "Clients created in MDM",
                        "Client Type": "Entity Client",
                        "Approval": data.data[0].MdmEntity ? data.data[0].MdmEntity : "No"
                    },
                    {
                        "Category": "Clients created externally",
                        "Client Type": "Individual Client",
                        "Approval": data.data[0].ExternalIndividual ? data.data[0].ExternalIndividual : "No"
                    },
                    {
                        "Category": "Clients created externally",
                        "Client Type": "Entity Client",
                        "Approval": data.data[0].ExternalEntity ? data.data[0].ExternalEntity : "No"
                    }
                    ]
                    setApprovalConfig(source_data)
                } else {
                    const source_data = [{
                        "Category": "Clients created in MDM",
                        "Client Type": "Individual Client",
                        "Approval": "No"
                    },
                    {
                        "Category": "Clients created in MDM",
                        "Client Type": "Entity Client",
                        "Approval": "No"
                    },
                    {
                        "Category": "Clients created externally",
                        "Client Type": "Individual Client",
                        "Approval": "No"
                    },
                    {
                        "Category": "Clients created externally",
                        "Client Type": "Entity Client",
                        "Approval": "No"
                    }
                    ]
                    setApprovalConfig(source_data)
                }
            })
    }
    useEffect(() => {
        // console.log("SELECTED FORM OPTIONS: ", selectedFormOption)
        FetchFormRecords()
    }, [selectedFormOption])

    useEffect(() => {
        // console.log("DATA: ", data)
        if (Object.keys(data).length > 0) {
            const records = data["data"]["records"]
            const preview = data["data"]["previewStructure"]
            const fieldnamemap = data["data"]["fieldnamemap"]
            setRowData(records)
            setPreviewStructure(preview)
            setFieldNameMap(fieldnamemap)
            setFormDescription("Client onboarding")
        }
    }, [data])

    useEffect(() => {
        // console.log("ROWDATA:", rowData)
        // console.log("FIELD MAP DATA:", fieldNameMap)
        if (rowData.length > 0 && fieldNameMap !== undefined) {
            gridParamsMapper(rowData)
        }
    }, [rowData, previewStructure, fieldNameMap])


    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    let gridApi;
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            resizable: true,
            animateRows: true,
            sizeColumnsToFit: true,
            minWidth: 150
        }),
        []
    );

    const FetchRecordData = (props) => {
        // console.log("PROPS:", props)
        if (selectedFormOption !== undefined && selectedFormOption.value !== '') {
            const postOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getFromLS("token")}`,
                },
                body: JSON.stringify({
                    "ClientMasterId": props.data.ClientMasterId,
                    "FormId": selectedFormOption.value
                }),
            };
            setFormTitle(selectedFormOption["label"])
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/form/response/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    const ps_data = [...previewStructure]
                    // console.log("FIELD NAME MAP: ", fieldNameMap)
                    // console.log("DATA: ", data.data)
                    for (let field of ps_data) {
                        field["value"] = data.data[0][fieldNameMap[field["label"]]]
                        // console.log("FIELD: ", field)
                    }
                    props.selectedrow({
                        "id": props.data.ClientMasterId,
                        "previewComponent": ps_data,
                        "update": true
                    });
                    setPreviewFullWindowModalShow(true)
                }
                );
        }
    }

    const SendForApproval = (props) => {
        // console.log("SEND FOR APPROVAL PROPS:", props)
        const postOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getFromLS("token")}`,
            },
            body: JSON.stringify({
                "ClientMasterId": props.data.ClientMasterId,
            }),
        };
        // console.log("FORM URL: ", formUrl)
        // console.log("CLIENT MASTER ID: ", props.data.ClientMasterId)

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/send_for_approval/${formUrl}/`, postOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    toast.success(
                        "Sent for approval.",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                } else {
                    toast.error(
                        "Unable to send for approval, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    /**
     * A grid cell renderer button.
     * @param {*} props: props include the selected row data for editing.
     * @returns A button for editing the row in grid.
     */
    const BtnCellRenderer = (props) => {
        // console.log("BTN CELL RENDERER:", props)
        const editClick = () => {
            FetchRecordData(props)
        }

        const sendForApproval = () => {
            // console.log("SEND FOR APPROVAL CALLED")
            SendForApproval(props)
        }
        return (

            previewStructure.length > 0 ?
                <>
                    <Button
                        style={{
                            cursor: "pointer",
                            color: "var(--theme1_primary)",
                            backgroundColor: "transparent",
                            border: "0px solid white",
                            padding: "5px 0px"
                        }}
                        onClick={() => editClick()}>
                        <Icon.PencilSquare />
                    </Button>
                    {
                        props.data.ApprovalStatus === "Not started" || props.data.ApprovalStatus === "pending" || props.data.ApprovalStatus === undefined ?
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        Send for approval
                                    </Tooltip>
                                }
                                error={true}
                            >
                                <Button
                                    style={{
                                        cursor: "pointer",
                                        color: "var(--theme1_tertiary)",
                                        backgroundColor: "transparent",
                                        border: "0px solid white",
                                    }}
                                    onClick={() => sendForApproval()}>
                                    <Icon.SendPlusFill />
                                </Button>
                            </ OverlayTrigger>
                            :
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        The approval is in process.
                                    </Tooltip>
                                }
                                error={true}
                            >
                                <Button
                                    style={{
                                        cursor: "pointer",
                                        color: "gray",
                                        backgroundColor: "transparent",
                                        border: "0px solid white",
                                    }}>
                                    <Icon.SendSlashFill />
                                </Button>
                            </ OverlayTrigger>
                    }
                </>
                :

                <Button
                    style={{
                        cursor: "wait",
                        color: "var(--theme1_primary)",
                        backgroundColor: "transparent",
                        border: "0px solid white"
                    }}
                    onClick={() => editClick()}>
                    <Icon.PencilSquare />
                </Button>
        )
    }

    /**
     * A grid cell renderer button.
     * @param {*} props: props include the selected row data for editing.
     * @returns A button for editing the row in grid.
     */
    const ApprovalStatusRenderer = (props) => {
        // console.log("PROPS: ", props.data)
        return <>{
            props.data.ApprovalStatus !== undefined
                ?
                capitalizeFirstLetter(props.data.ApprovalStatus)
                :
                "Not started"
        }
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        {
                            props.data.ApprovalMessage
                                ?
                                props.data.ApprovalMessage :
                                "No message."
                        }
                    </Tooltip>
                }
                error={true}
            >
                <Icon.PatchQuestionFill style={{ color: "var(--theme1_tertiary)", margin: "2px 0px 0px 5px", cursor: "pointer" }} />
            </OverlayTrigger>

        </>
    }

    // Declaring the api - grid connections
    const [columnDefs, setColumnDefs] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState({ "id": "", "previewComponent": [], "update": false });
    useEffect(() => {
        // console.log("SELECTED ROW DATA: ", selectedRowData)
        for (let component of selectedRowData["previewComponent"]) {
            if (!component["show"]) {
                component["show"] = true
            }
        }
        setPreviewComponents(selectedRowData)
    }, [selectedRowData])

    const onSelectionChanged = () => { const selected_rows = gridRef.current.api.getSelectedRows() };

    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => `No data for ${formTitle} client onboarding yet. Please add new records first.` } }, []);

    /**
    * This function is used for formatting the default column names of the grid
    * @param {*} defaultName: Current name from API
    * @returns: Final column name
    */
    const GridColumnTitleRenderer = (defaultName) => {
        return Object.keys(fieldNameMap)[Object.values(fieldNameMap).indexOf(defaultName)]
    }

    /**
     * This function takes the API response data and maps it to the grid params
     * @param {*} result: API response
     */
    const gridParamsMapper = (rowData) => {
        if (rowData.length !== 0) {
            const keys = Object.keys(rowData[0]).slice(1)
            const index = keys.indexOf("ApprovalMessage");
            if (index > -1) {
                keys.splice(index, 1);
            }
            const jsonColDefs = keys.map(key => {
                return (
                    key === 'ApprovalStatus' ?
                        {
                            field: key, headerName: GridColumnTitleRenderer(key),
                            cellRenderer: ApprovalStatusRenderer,
                            cellRendererParams: {
                                selectedrow: setSelectedRowData
                            }
                        }
                        :
                        {
                            field: key, headerName: GridColumnTitleRenderer(key)
                        }
                )
            });
            const cold = [
                {
                    field: "", headerName: "Actions",
                    cellStyle: { 'border-right-color': '#ccc' },
                    maxWidth: 75,
                    width: 75,
                    suppressMenu: true,
                    cellRenderer: BtnCellRenderer,
                    cellRendererParams: {
                        selectedrow: setSelectedRowData
                    }
                }
            ].concat(jsonColDefs)

            // let colDefs = cold.concat([{
            //     field: "ApprovalStatus", headerName: "Approval Status",
            //     cellStyle: { 'border-right-color': '#ccc' },
            //     maxWidth: 150,
            //     width: 75,
            //     suppressMenu: true,
            //     cellRenderer: ApprovalStatusRenderer,
            //     cellRendererParams: {
            //         selectedrow: setSelectedRowData
            //     }
            // }])

            setColumnDefs(cold)
        }
    }

    const addNewRecord = () => {
        const approval = activeClientType === "Individual" ? approvalConfig.filter((obj) => {
            return obj.Category === "Clients created in MDM" &&
                obj["Client Type"] === "Individual Client"
        })[0]["Approval"] : approvalConfig.filter((obj) => {
            return obj.Category === "Clients created in MDM" &&
                obj["Client Type"] === "Entity Client"
        })[0]["Approval"]
        // console.log("APPROVAL: ", approval)
        const preview_comps = {
            "id": null,
            "previewComponent": [...previewStructure],
            "update": false,
            "approval": approval === "Yes" ? true : false
        }
        // console.log("APPROVAL CONFIG in PREVIEW COMPS: ", preview_comps)
        // console.log("APPROVAL CONFIG: ", approvalConfig)
        // console.log("ACTIVE CLIENT TYPE: ", activeClientType)

        setPreviewComponents(preview_comps)
        setPreviewFullWindowModalShow(true)
    }
    const transporter = (pill) => {
        props.transporter(pill);
    };

    const refreshGrid = () => {
        FetchFormRecords()
    }

    return (
        <>
            {data["message"] !== undefined
                ?
                <>
                    <PreviewFullWindowModal
                        show={previewFullWindowModalShow}
                        onHide={() => setPreviewFullWindowModalShow(false)}
                        previewcomponents={previewComponents}
                        refresh={() => refreshGrid()}
                        formtitle={formTitle}
                        formurl={formUrl}
                    />
                    <Row className='no-gutter page-wrapper'>
                        <Col md={2} className="flex-row-space-center" style={{ padding: "0px 20px 0px 0px" }}>
                            <p className="display-6" style={{ height: "fit-content", marginBottom: 0, width: "fit-content" }}>Clients</p> <p style={{ width: "fit-content" }}></p>
                        </Col>
                        <Col md={2} className="flex-row-space-center" style={{ padding: "0px 20px 0px 0px" }}>
                            <Button className="custom-button"
                                style={{
                                    display: "inline-block",
                                    padding: "10px 10px",
                                }}
                                onClick={() => addNewRecord()} >
                                <Icon.PlusCircleFill style={{ fontSize: 20, margin: "0px 10px 0px 0px" }} />
                                Add new client
                            </Button>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={2} className="flex-row-space-center">
                            {/* <div className="form-select-wrapper" style={{ width: 250 }}>
                                <p style={{ marginRight: "0.5rem", marginBottom: 0, width: 100, textAlign: "right", fontWeight: 500 }}>Client type: </p>
                                <Select
                                    value={selectedClientTypeOption}
                                    onChange={(e) => setSelectedClientTypeOption(e)}
                                    options={clientTypeOptions}
                                    className="form-select custom-select"
                                    aria-label="Default"
                                    closeMenuOnSelect={true}
                                    autosize={true}
                                    placeholder="Filter by form"
                                    menuPosition="fixed"
                                    classNamePrefix="react-select"
                                />
                            </div> */}
                        </Col>
                        <Col md={3}>
                            <div className="form-select-wrapper" style={{ width: 300, marginLeft: 20 }}>
                                <p style={{ marginRight: "0.5rem", marginBottom: 0, width: 100, textAlign: "right", fontWeight: 500 }}>Onboarding template: </p>
                                <Select
                                    value={selectedFormOption}
                                    onChange={(e) => setSelectedFormOption(e)}
                                    options={formOptions}
                                    className="form-select custom-select"
                                    aria-label="Default"
                                    closeMenuOnSelect={true}
                                    autosize={true}
                                    // isClearable={true}
                                    placeholder="Filter by form"
                                    menuPosition="fixed"
                                    classNamePrefix="react-select"
                                />
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="navigator" style={{ float: "right", width: "fit-content", marginBottom: "0rem" }}>
                                {/* <Button className="nav-direction" onClick={() => navigate("/forms")}><Icon.CaretLeftFill /> Client data management</Button> */}
                                <Button className="nav-direction" onClick={() => transporter("client_approval")}><Icon.CaretRightFill /> Client Approval</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className='no-gutter' style={{ height: "80vh" }}>
                        <Col md={12}>
                            <div className="ag-theme-balham" style={gridStyle}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    alwaysShowHorizontalScroll={false}
                                    alwaysShowVerticalScroll={false}
                                    rowHeight={25}
                                    rowSelection={"single"}
                                    suppressContextMenu={true}
                                    onFirstDataRendered={onFirstDataRendered}
                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
                :
                <div
                    className="flex-row-center-center"
                    style={{ marginTop: "20%" }}
                >
                    <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                        <Bars
                            height="80"
                            width="80"
                            color="var(--theme1_primary)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                        Loading your data
                    </div>
                </div>
            }
        </>
    )
}

export default MyClients;