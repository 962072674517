import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import {
    Row,
    Col,
    Button
} from "react-bootstrap";
import { logout } from "../../utils";
import { toast } from "react-toastify";
import { getFromLS } from "../../utils/storage";
import * as Icon from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Bars } from "react-loader-spinner";
import { PreviewFullWindowModal } from "./PreviewFullWindowModal";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

const ClientApproval = (props) => {
    useEffect(() => {
      localStorage.setItem("transfer","approval")
    }, [])
    
    const [data, setData] = useState({})
    const [allForms, setAllForms] = useState([])
    const [rowData, setRowData] = useState([])
    const [fieldNameMap, setFieldNameMap] = useState({})
    const [previewStructure, setPreviewStructure] = useState([])
    const [previewComponents, setPreviewComponents] = useState([])
    const [formTitle, setFormTitle] = useState("")
    const [formUrl, setFormUrl] = useState("")
    const [formDescription, setFormDescription] = useState("")
    const [previewFullWindowModalShow, setPreviewFullWindowModalShow] = useState(false)
    const [formOptions, setFormOptions] = useState([{ value: '', label: '' }])
    const [selectedFormOption, setSelectedFormOption] = useState({ value: '', label: '', url: ''})

    const postOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            "FormPurpose": "Client Onboarding"
        }),
    };

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setAllForms(data.data)
            });
    }, [])

    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/type/responses/`, postOptions)
    //         .then((response) => {
    //             if (!response.ok) {
    //                 if (response.status === 401) {
    //                     // Handle unauthorized error
    //                     logout();
    //                     alert("Session ended , Please login back");
    //                 } else {
    //                     throw new Error("Request failed.");
    //                 }
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             console.log("ALL FORM RESPONSES: ", data)
    //             // setData(data)
    //         });
    // }, []);

    useEffect(() => {
        // const uniqueFormNameList = new Set([])
        // for (let form of allForms["data"]) {
        //     // form options list mapping below
        //     uniqueFormNameList.add(form["FormName"])
        // }
        const optionList = []
        for (let form of allForms) {
            const option = { "label": form.FormName, "value": form.FirmFormId, "url":form.Url }
            optionList.push(option)
        }
        setFormOptions(optionList)
        setSelectedFormOption(optionList[0])
    }, [allForms])

    const FetchAllRecords = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (selectedFormOption !== undefined && selectedFormOption.value !== '') {
            setFormTitle(selectedFormOption["label"])
            setFormUrl(selectedFormOption["url"])
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/form/approval/${selectedFormOption.value}`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    // console.log("DATA: ", data)
                    setData(data)
                });
        }
    }
    useEffect(() => {
        FetchAllRecords()
    }, [selectedFormOption])

    useEffect(() => {
        console.log("DATA: ", data)
        if (Object.keys(data).length > 0) {
            const records = data.data["records"]
            const preview = data.data["previewStructure"]
            const fieldnamemap = data.data["fieldnamemap"]
            setRowData(records)
            setPreviewStructure(preview)
            setFieldNameMap(fieldnamemap)
            setFormDescription("Client onboarding")
        }
    }, [data])

    useEffect(() => {
        // console.log("ROWDATA:", rowData)
        // console.log("FIELD MAP DATA:", fieldNameMap)
        if (rowData.length > 0 && fieldNameMap!== undefined) {
            gridParamsMapper(rowData)
        }
    }, [rowData, previewStructure, fieldNameMap])


    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    let gridApi;
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            resizable: true,
            animateRows: true,
            sizeColumnsToFit: true,
            minWidth: 150
        }),
        []
    );

    const FetchRecordData = (props) => {
        // console.log("PROPS:", props)
        if (selectedFormOption !== undefined && selectedFormOption.value !== '') {
            const postOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getFromLS("token")}`,
                },
                body: JSON.stringify({
                    "ClientMasterId": props.data.ClientMasterId,
                    "FormId": selectedFormOption.value
                }),
            };
            setFormTitle(selectedFormOption["label"])
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/form/response/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    const ps_data = [...previewStructure]
                    for (let field of ps_data) {
                        console.log("FIELD: ", field)
                        field["disabled"] = true
                        field["value"] = data.data[0][fieldNameMap[field["label"]]]
                    }
                    props.selectedrow({
                        "id": props.data.ClientMasterId,
                        "previewComponent": ps_data,
                        "update": false,
                        "approval": true
                    });
                    setPreviewFullWindowModalShow(true)
                }
                );
        }
    }

    /**
     * A grid cell renderer button.
     * @param {*} props: props include the selected row data for editing.
     * @returns A button for editing the row in grid.
     */
    const BtnCellRenderer = (props) => {
        const editClick = () => {
            FetchRecordData(props)
        }
        return (

            previewStructure.length > 0 ?
                <Button
                    style={{
                        cursor: "pointer",
                        color: "var(--theme1_primary)",
                        backgroundColor: "transparent",
                        border: "0px solid white",
                    }}
                    onClick={() => editClick()}>
                    <Icon.PersonFillCheck />
                </Button>
                :

                <Button
                    style={{
                        cursor: "wait",
                        color: "var(--theme1_primary)",
                        backgroundColor: "transparent",
                        border: "0px solid white"
                    }}
                    onClick={() => editClick()}>
                    <Icon.PersonFillCheck />
                </Button>
        )
    }

    // Declaring the api - grid connections
    const [columnDefs, setColumnDefs] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState({ "id": "", "previewComponent": [], "update": false });
    useEffect(() => {
        // console.log("SELECTED ROW DATA: ", selectedRowData)
        for (let component of selectedRowData["previewComponent"]) {
            if (!component["show"]) {
                component["show"] = true
            }
        }
        setPreviewComponents(selectedRowData)
    }, [selectedRowData])

    const onSelectionChanged = () => { const selected_rows = gridRef.current.api.getSelectedRows() };

    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => `No clients for your to approve.` } }, []);

    /**
    * This function is used for formatting the default column names of the grid
    * @param {*} defaultName: Current name from API
    * @returns: Final column name
    */
    const GridColumnTitleRenderer = (defaultName) => {
        return Object.keys(fieldNameMap)[Object.values(fieldNameMap).indexOf(defaultName)]
    }

    /**
     * This function takes the API response data and maps it to the grid params
     * @param {*} result: API response
     */
    const gridParamsMapper = (rowData) => {
        if (rowData.length !== 0) {
            const keys = Object.keys(rowData[0]).slice(1)
            const jsonColDefs = keys.map(key => {
                return {
                    field: key, headerName: GridColumnTitleRenderer(key)
                }
            });
            let colDefs = [{
                field: "", headerName: "Actions",
                cellStyle: { 'border-right-color': '#ccc' },
                maxWidth: 75,
                width: 75,
                suppressMenu: true,
                cellRenderer: BtnCellRenderer,
                cellRendererParams: {
                    selectedrow: setSelectedRowData
                }
            }].concat(jsonColDefs)
            setColumnDefs(colDefs)
        }
    }

    const transporter = (pill) => {
        props.transporter(pill);
    };

    const refresh = () => {
        FetchAllRecords()
    }

    return (
        <>
            {data["message"] !== undefined
                ?
                <>
                    <PreviewFullWindowModal
                        show={previewFullWindowModalShow}
                        onHide={() => setPreviewFullWindowModalShow(false)}
                        previewcomponents={previewComponents}
                        formtitle={formTitle}
                        formurl={formUrl}
                        formid={selectedFormOption.value}
                        forapproval={true}
                        refresh={() => refresh()}
                    />
                    <Row className='no-gutter page-wrapper'>
                        <Col md={6} className="flex-row-space-center" style={{ padding: "0px 20px 0px 0px" }}>
                            <p className="display-6" style={{ height: "fit-content", marginBottom: 0, width: "fit-content" }}>Clients Approval</p> <p style={{ width: "fit-content" }}></p>
                        </Col>
                        <Col md={6} className="flex-row-space-center">
                            <div className="form-select-wrapper" style={{ width: 350 }}>
                                <p style={{ marginRight: "0.75rem", marginBottom: 0, width: 150 }}>Select client type: </p>
                                <Select
                                    value={selectedFormOption}
                                    onChange={(e) => setSelectedFormOption(e)}
                                    options={formOptions}
                                    className="form-select custom-select"
                                    aria-label="Default"
                                    closeMenuOnSelect={true}
                                    autosize={true}
                                    placeholder="Filter by form"
                                    menuPosition="fixed"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            <div className="navigator" style={{ float: "right", width: "fit-content", marginBottom: "0rem" }}>
                                <Button className="nav-direction" onClick={() => transporter("clients")}><Icon.CaretLeftFill /> Clients</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className='no-gutter' style={{ height: "80vh" }}>
                        <Col md={12}>
                            <div className="ag-theme-balham" style={gridStyle}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    alwaysShowHorizontalScroll={false}
                                    alwaysShowVerticalScroll={false}
                                    rowHeight={25}
                                    rowSelection={"single"}
                                    suppressContextMenu={true}
                                    onFirstDataRendered={onFirstDataRendered}
                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
                :
                <div
                    className="flex-row-center-center"
                    style={{ marginTop: "20%" }}
                >
                    <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                        <Bars
                            height="80"
                            width="80"
                            color="var(--theme1_primary)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                        Loading your data
                    </div>
                </div>
            }
        </>
    )
}

export default ClientApproval;