import React, {
    useState
} from "react";
import {
    Row
} from "react-bootstrap";
import WizardFormLayout from "../layout.js"

const WizardFormConfig = (props) => {
    console.log("PROPSYYY: ", props)
    const [targetFields, setTargetFields] = useState(props.configoption.targetFields)
    const [createWizardFormOption, setCreateFormOption] = useState(props.configoption.create_wizard_form_option)
    return (
        <>
        {
            createWizardFormOption !== undefined
            ?
            <Row className="no-gutter flex-row-center-center">
                {/* JUGAAD WITH FIELDS TO NOT SEND EMPTY */}
                <WizardFormLayout fields={[]} createwizardformoption={createWizardFormOption} transporter={props.transporter}/>
            </Row>
            :
            <Row className="no-gutter flex-row-center-center">
                <WizardFormLayout fields={targetFields !== undefined ? targetFields : []} createwizardformoption={{}}  transporter={props.transporter} />
            </Row>
        }
        </>
    )
}
export default WizardFormConfig