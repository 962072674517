import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import NewLogin from "./pages/Loginnew";
import MyClients from "./pages/ClientOnboarding/myclients";
import ClientOnboardingFormConfig from "./pages/ClientOnboarding/clientonboardingformconfig";
import ClientDataManagementConfig from "./pages/ClientOnboarding/clientdatamanagementconfig";
import MyForms from "./pages/OnboardingForms/myforms/myforms"
import FormLayout from "./pages/OnboardingForms/layout";
import Dashboard from "./pages/NewDashboard/Dashboard";
// import ReportDashboardBackup from './pages/Reports/ReportDashboard';
import ReportDashboard from './pages/Reports/ReportDashboard/ReportDashboard';
import IndividualVsEntityChart from './pages/Reports/reports/custPerType';
import SingleVsJointFiling from './pages/Reports/reports/custPerJoinType';
// import ZipCodeMap from './pages/Reports/reports/custDistZipCode';
import GeoDistMap from './pages/Reports/reports/custGeoDistOfficewise';
import ExtensionStatus from "./pages/Reports/reports/custExtensionStatus";

import DiffToolMainClientGridPage from "./pages/ClientReconciliation/DiffToolMainClientGridPage/DiffToolMainClientGridPage";
import EngagementLetterMainPage from "./pages/EngagementLetter/EngagementLetterMainPage/EngagementLetterMainPage";
import MyTemplatesPage from "./pages/EngagementLetter/MyTemplatesPage/MyTemplatesPage";
import EngagementLetterDetailsPage from "./pages/EngagementLetter/EngagementLetterDetailsPage/EngagementLetterDetailsPage";
import ApprovalDetailsPage from "./pages/EngagementLetter/ApprovalDetailsPage/ApprovalDetailsPage";

import { GlobalStyles } from "./GlobalStyles";
import ClientDataMapping from "./pages/ClientOnboarding/clientdatamapping";
import EntityTypeDistribution from "./pages/Reports/reports/EntityTypeDist";
import ClientApproval from "./pages/ClientOnboarding/clientapproval";

function App() {
  return (
    <>
    {/*  */}
      <Routes>
        <Route path={""} element={<NewLogin />}></Route>
        <Route path={"/"} element={<NewLogin />}></Route>
        <Route path={"/login"} element={<NewLogin />}></Route>
        <Route path={"/login2"} element={<NewLogin />}></Route>
        <Route path={"/dashboard"} element={<Dashboard />}></Route>
        <Route path={"/client-onboarding-form"} element={<ClientOnboardingFormConfig />}></Route>
        <Route path={"/client-data-management"} element={<ClientDataManagementConfig />}></Route>
        <Route path={"/client-field-mapping"} element={<ClientDataMapping />}></Route>
        <Route path={"/forms"} element={<MyForms />}></Route>
        <Route path={"/create-form"} element={<FormLayout />}></Route>
        <Route path={"/my-clients"} element={<MyClients />}></Route>
        <Route path={"/approvals"} element={<ClientApproval />}></Route>
        {/* <Route path={"/reports"} element={<ReportDashboardBackup />}></Route> */}
        <Route path={"/clientdashboard"} element={<ReportDashboard />}></Route>

        <Route path={"/dashboard/cust_type_reports"} element={<IndividualVsEntityChart />}></Route>
        <Route path={"/dashboard/cust_join_type"} element={<SingleVsJointFiling />}></Route>
        {/* <Route path={"/dashboard/cust_geo_dist"} element={<ZipCodeMap />}></Route> */}
        <Route path={"/dashboard/cust_geo_dist"} element={<GeoDistMap />}></Route>
        <Route path={"/dashboard/cust_extension_status"} element={<ExtensionStatus />}></Route>
        <Route path={"/dashboard/cust_entity_dist"} element={<EntityTypeDistribution />}></Route>

        <Route path={"/diff-tool"} element={<DiffToolMainClientGridPage />}></Route>
        <Route path={"/templates"} element={<MyTemplatesPage />}></Route>
        <Route path={"/wizard-form-builder"} element={<EngagementLetterMainPage />}></Route>
        <Route path={"/template-mappings"} element={<EngagementLetterMainPage />}></Route>
        <Route path={"/client-details"} element={<EngagementLetterDetailsPage />}></Route>
        <Route path={"/approval-details"} element={<ApprovalDetailsPage />}></Route>

      </Routes>
    </>
  );
}

export default App;
