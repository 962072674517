import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Button
} from "react-bootstrap";
import { logout } from "../../../utils/index.js";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { ThreeCircles, Bars } from "react-loader-spinner";
import * as Icon from "react-bootstrap-icons";
import '../EngagementLetter.css';
import { ToastContainer, toast } from "react-toastify";
import ContentBlockCRUDModel from '../ContentBlockCRUDModel/ContentBlockCRUDModel.js';

const ContentBlocksPage = (props) => {
    const [rowHeight, setRowHeight] = useState(25);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [contentBlocksDetails, setContentBlocks] = useState([]);
    const [userName, setUserName] = useState(localStorage.getItem("username"));
    const [userRole, setUserRole] = useState(localStorage.getItem("usertype"));
    const [contentBlockModelShow, setContentBlockModelShow] = useState(false);
    const [contentBlockId, setContentBlockId] = useState();
    const [blockName, setBlockName] = useState("");
    const [blockContent, setBlockContent] = useState("");
    const [active, setActive] = useState("Y");
    const [modelMode, setModelMode] = useState("create");

    const toastBasicConfig = {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    const toastSuccessConfig = {
        ...toastBasicConfig,
        className: "toastify-color-success",
    };

    const toastErrorConfig = {
        ...toastBasicConfig,
        className: "toastify-color-error",
    };

    const fetchContentBlocksPageData = useCallback(() => {
        // Reset state
        resetState();

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        setRefreshGrid(true);

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/content_blocks/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                const details = data.data;

                setContentBlocks(details);
            })
            .finally(() => {
                setRefreshGrid(false);
            });
    }, []);

    // Reference to the AgGrid component
    const gridRef = useRef();

    // Style for the AgGrid
    const gridStyle = useMemo(() => ({ height: "85vh", width: "100%" }), []);

    const CreatedDateRenderer = (created_date_props) => {

        const formatDateTime = (dateTimeString) => {
            // Convert the input string to a Date object in the specified timezone offset
            const date = new Date(dateTimeString + '-05:52'); // Append the timezone offset to the input string
    
            // Adjust to user's local timezone
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
    
            // Format hours, minutes, and seconds
            let hour = date.getHours();
            const minute = String(date.getMinutes()).padStart(2, '0');
            const second = String(date.getSeconds()).padStart(2, '0');
    
            // Determine AM/PM notation
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12; // Hour '0' should be '12'
            const formattedHour = String(hour).padStart(2, '0');
    
            // Format as "YYYY-MM-DD h:mm:ss A"
            return `${year}-${month}-${day} ${formattedHour}:${minute}:${second} ${ampm}`;
        };

        return (
            <span>
                {formatDateTime(created_date_props.data["CreatedDate"])}
            </span>
        )
    }

    useEffect(() => {
        fetchContentBlocksPageData();
    }, []);

    // Default column definition for AgGrid
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    let gridApi;
    // Callback when the grid is ready
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => !refreshGrid ? `No data available`:`Loading your content blocks` } }, [refreshGrid]);

    const resetState = () => {
        setRefreshGrid(false);
        setContentBlocks([]);
        setContentBlocksColumnDefs(defaulContentBlocksColumnDefs);
        setIsRowSelected(false);
        setContentBlockId(null);
        setBlockName("");
        setBlockContent("");
        setActive("Y");
        setModelMode("create");
    };

    // Function to handle row selection change
    const onSelectionChanged = useCallback(async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setIsRowSelected(selectedRows.length > 0); // Update state based on whether rows are selected
    }, []);

    const handleContentBlockShow = (mode, data = null) => {
        if(mode !== "create") {
            setContentBlockId(data.ContentBlockId);
            setBlockName(data.BlockName);
            setBlockContent(data.BlockContent);
            setActive(data.Active);
        }
        setModelMode(mode);

        setContentBlockModelShow(true);
    }
    
    const handleContentBlockHide = () => {
        setContentBlockModelShow(false);
        fetchContentBlocksPageData();
    }

    /**
     * Button cell renderer for the grid.
     * @param {Object} props - Props for the button cell renderer.
     * @returns Button for downloading and viewing the engagement letter.
     */
    const BtnCellRenderer = (props) => {
    
        const viewClick = async () => {
            handleContentBlockShow("view", props.data);
        };

        const editClick = () => {
            handleContentBlockShow("edit", props.data);
        }

        return (
            <div className="icon-cell-container" style={{ justifyContent: "flex-start", gap: "15px" }}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            View content block
                        </Tooltip>
                    }
                    error={true}
                >
                    <div style={{
                        margin: "0px",
                        width: "35%"
                    }}>
                        <Button className="icon-button" style={{
                            padding: "0px", margin: "0px",
                            width: "100%", height: "100%"
                        }} 
                                onClick={() => viewClick()}>
                            <span className="file-icon" style={{ width: "max-content", height: "max-content" }}>
                                <img width="100%" height="100%" src="https://img.icons8.com/fluency-systems-regular/100/visible--v1.png" alt="visible--v1"/>
                            </span>
                        </Button>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="button-tooltip">
                                Edit content block
                            </Tooltip>
                        }
                        error={true}
                    >
                    <div style={{
                        margin: "0px",
                        width: "35%"
                    }}>
                        <Button className="icon-button" style={{
                                padding: "0px", margin: "0px",
                                width: "100%", height: "100%"
                            }} 
                            onClick={() => editClick()}>
                            <Icon.PencilSquare />
                        </Button>
                    </div>
                </OverlayTrigger>
            </div>
        );
    }

    const defaulContentBlocksColumnDefs = [
        {
            headerName: "Block Name",
            field: "BlockName",
            minWidth: 400,
            sortable: false,
        },
        {
            headerName: "Status",
            field: "Status",
            minWidth: 300,
            sortable: false,
        },
        {
            headerName: "Created Date",
            field: "CreatedDate",
            cellRenderer: CreatedDateRenderer,
            minWidth: 625,
            sortable: false,
        },
        {
            headerName: "Action",
            field: "",
            pinned: "right",
            cellRenderer: BtnCellRenderer,
            maxWidth: 80,
            minWidth: 80,
            sortable: false
        }
    ];

    const [contentBlocksColumnDefs, setContentBlocksColumnDefs] = useState(defaulContentBlocksColumnDefs);

    // JSX for the page component
    return (
        <>
            {contentBlockModelShow && (
                <ContentBlockCRUDModel
                    mode={modelMode}
                    contentBlockId={contentBlockId}
                    blockName={blockName}
                    blockContent={blockContent}
                    active={active}
                    onHide={handleContentBlockHide}
                />
            )}
            <Row className='no-gutter'>
                <p className="display-6" style={{ height: "fit-content", width: "fit-content", padding: 0, marginBottom: 0 }}>
                    Content Blocks
                </p>
            </Row>
            <Row className='no-gutter'>
                <Col md={12} className="d-flex justify-content-end" style={{ float: "right" }}>
                    
                    <div style={{ marginBottom: "0rem", marginRight: 0, marginTop: "-2.1rem" }}>
                        <Button 
                            onClick={() => handleContentBlockShow("create")}
                            style={{
                                marginRight: "1rem"
                            }}
                            className="custom-button"
                        >
                            <p style={{ marginBottom: 0, marginTop: 0 }}>Create Content Block</p>
                        </Button>
                    </div>
                    <div style={{ marginBottom: "0rem", marginTop: "-1.75rem" }}>
                        {
                            refreshGrid ?
                                <div>
                                    <ThreeCircles
                                        height="20"
                                        width="20"
                                        color="#4fa94d"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="three-circles-rotating"
                                        outerCircleColor="var(--theme1_tertiary)"
                                        innerCircleColor="var(--theme1_secondary)"
                                        middleCircleColor="var(--theme1_primary)"
                                    />
                                </div>
                                :
                                <Icon.ArrowCounterclockwise onClick={fetchContentBlocksPageData}
                                    style={{
                                        fontSize: 20,
                                        float: "right",
                                        border: "0px",
                                        cursor: "pointer"
                                    }}
                                />
                        }
                    </div>
                </Col>
            </Row>
            <Row className='no-gutter'>
                <Col md={12}>
                    <div className="ag-theme-balham" style={gridStyle}>
                        <AgGridReact
                            key={refreshGrid}
                            ref={gridRef}
                            rowData={contentBlocksDetails}
                            columnDefs={contentBlocksColumnDefs}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            alwaysShowHorizontalScroll={false}
                            alwaysShowVerticalScroll={false}
                            rowHeight={rowHeight}
                            rowSelection={"multiple"}
                            suppressContextMenu={true}
                            noRowsOverlayComponent={noRowsOverlayComponent}
                            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                            onSelectionChanged={onSelectionChanged}
                        />
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </>
    );
};

export default ContentBlocksPage;