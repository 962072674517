import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

:root {
    --theme1_primary: ${({ theme }) => theme.colors.primary} !important;
    --theme1_secondary: ${({ theme }) => theme.colors.secondary} !important;
    --theme1_tertiary: ${({ theme }) => theme.colors.tertiary} !important;
    --theme1_quarternary: ${({ theme }) => theme.colors.quarternary} !important;
    --theme1_text: ${({ theme }) => theme.colors.quarternary} !important;
    --theme1_background: ${({ theme }) => theme.colors.quarternary} !important;
    --rs-toggle-bg: #3c3f43 !important;
    --rs-toggle-disabled-bg: lightgray !important;
    --rs-toggle-checked-bg: ${({ theme }) => theme.colors.secondary} !important;
    --small_font: 8px !important;
    --default: 11px !important;
    --h1: 50px !important;
    --h2: 40px !important;
    --h3: 30px !important;
    --h4: 24px !important;
    --h5: 18px !important;
    --h6: 14px !important;
    --h7: 12px !important;
    --small_image: 20px !important;
    --medium_image: 50px !important;
    --large_image: 100px !important;
  }
`;

// body {
//   background: ${({ mode }) => mode.colors.body} !important;
//   color: ${({ mode }) => mode.colors.text} !important;
// }
