import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import {
    Row,
    Col,
    Button
} from "react-bootstrap";
import { logout } from "../../../utils";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { ThreeCircles, Bars } from "react-loader-spinner";
import * as Icon from "react-bootstrap-icons";
import './DiffToolMainClientGridPage.css';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ClientReconciliationByFieldPage from '../ClientReconciliationByFieldPage/ClientReconciliationByFieldPage';
import ClientReconciliationByClientPage from '../ClientReconciliationByClientPage/ClientReconciliationByClientPage';
import Select, { components } from "react-select";


/**
 * Component for displaying the Diff Tool Main Client Grid Page.
 * @param {Object} props - Props for the page.
 * @returns Component for displaying the Diff Tool Main Client Grid Page.
 */
const DiffToolMainClientGridPage = (props) => {
    const [rowHeight, setRowHeight] = useState(25);
    const [rowCount, setRowCount] = useState(0);
    const [totalMatchCount, setTotalMatchCount] = useState(0);
    const [showDetailsTab, setShowDetailsTab] = useState(false);
    const [activeTab, setActiveTab] = useState("byclient");
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [reconciliationDetails, setReconciliationDetails] = useState([]);
    const [reconciliationDetailsByField, setReconciliationDetailsByField] = useState([]);
    const [selectedType, setSelectedType] = useState({
        "label": "Individual",
        "value": "Individual",
    });

    const fetchReconciliationData = useCallback(() => {
        // Reset state
        resetState();

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        setRefreshGrid(true);

        const clientType = localStorage.getItem("ClientType") || "Individual";

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/diff-tool/reconciliation_details/?ClientType=${clientType}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                const details = data.data;

                const rowCount = details.length;
                setRowCount(rowCount);
                localStorage.setItem("ClientCount", rowCount);

                // Extract ClientMasterIds and Save
                const clientMasterIds = details.map(item => item.ClientMasterId);
                localStorage.setItem("ClientMasterIds", JSON.stringify(clientMasterIds));

                // Remove ClientMasterId keys
                const newData = details.map(({ ClientMasterId, ...rest }) => rest);
                setReconciliationDetails(newData);
            })
            .finally(() => {
                setRefreshGrid(false);
            });
    }, []);

    const fetchReconciliationByFieldData = useCallback(() => {
        // Reset state
        resetState();

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        setRefreshGrid(true);

        const clientType = localStorage.getItem("ClientType") || "Individual";

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/diff-tool/reconciliation_details_by_field/?ClientType=${clientType}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                const details = data.data;

                // Extract FieldNames and Save
                const fieldNames = details.map(item => item.Field);
                localStorage.setItem("FieldNames", JSON.stringify(fieldNames));

                // Extract FieldNames and Save
                const sourceOfTruths = details.map(item => item["Source of Truth"]);
                localStorage.setItem("SourceOfTruths", JSON.stringify(sourceOfTruths));

                localStorage.setItem("TotalFieldCount", details.length);

                setReconciliationDetailsByField(details);
            })
            .finally(() => {
                setRefreshGrid(false);
            });
    }, []);

    useEffect(() => {
        fetchReconciliationData();
    }, []);

    // Reference to the AgGrid component
    const gridRef = useRef();

    // Style for the AgGrid
    const gridStyle = useMemo(() => ({ height: "78vh", width: "100%" }), []);

    // Callback to handle sort change
    const onSortChanged = (e) => {
        e.api.refreshCells();
    };

    /**
     * Button cell renderer for the grid.
     * @param {Object} props - Props for the button cell renderer.
     * @returns Button for editing the row in the grid.
     */
    const BtnCellRenderer = (props) => {
        const editClick = () => {
            const mainTab = localStorage.getItem("MainTab");
            if (mainTab == "byfield")
                localStorage.setItem("CurrFieldIndex", props.rowIndex);
            else
                localStorage.setItem("CurrClientIndex", props.rowIndex);

            setActiveTab("details");
            setShowDetailsTab(true);
        }
        return (<Button style={{ cursor: "pointer", color: "var(--theme1_primary)", backgroundColor: "#fff", border: "0px solid white" }} onClick={() => editClick()}><Icon.PencilSquare /></Button>);
    }

    // Column definitions for AgGrid
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "No.",
            field: "", // No field needed for row number
            valueGetter: "node.rowIndex + 1",
            maxWidth: 100,
            minWidth: 100,
            sortable: false,
            pinned: "left"
        },
        {
            headerName: "Match Status",
            field: "Match Status",
            pinned: "right",
            cellRenderer: (params) => {
                if (params.value) {
                    return <Icon.CheckCircleFill className="icon-cell done" onClick={() => { }} />;
                } else {
                    return <Icon.XCircleFill className="icon-cell dump" onClick={() => { }} />;
                }
            },
            maxWidth: 140,
            minWidth: 140
        },
        {
            headerName: "Details",
            field: "",
            field: "",
            pinned: "right",
            cellRenderer: BtnCellRenderer,
            maxWidth: 80,
            minWidth: 80,
            sortable: false
        }
    ]);

    // Column definitions for AgGrid
    const [byFieldColumnDefs, setByFieldColumnDefs] = useState([
        {
            headerName: "No.",
            field: "", // No field needed for row number
            valueGetter: "node.rowIndex + 1",
            maxWidth: 100,
            minWidth: 100,
            sortable: false,
            pinned: "left"
        },
        {
            headerName: "Details",
            field: "",
            cellRenderer: BtnCellRenderer,
            pinned: "right",
            maxWidth: 80,
            minWidth: 80,
            sortable: false
        }
    ]);

    useEffect(() => {
        if (reconciliationDetails.length > 0) {
            // Check if keys are not already present in columnDefs
            const keysToAdd = Object.keys(reconciliationDetails[0]).filter(key => key !== "Match Status" && key !== "details" && !columnDefs.find(column => column.field === key));

            // Generate dynamic column definitions only for keys that are not already present
            const dynamicColumnDefs = keysToAdd.map((key) => ({
                headerName: key,
                field: key,
            }));

            // Update columnDefs with dynamic column definitions
            setColumnDefs(prevDefs => [...prevDefs, ...dynamicColumnDefs]);

            // Iterate over the array and update totalMatchCount
            setTotalMatchCount(0);
            reconciliationDetails.forEach(item => {
                if (item["Match Status"]) {
                    setTotalMatchCount(prevCount => prevCount + 1);
                }
            });
        }
    }, [reconciliationDetails])

    useEffect(() => {
        if (reconciliationDetailsByField.length > 0) {
            // Check if keys are not already present in columnDefs
            const keysToAdd = Object.keys(reconciliationDetailsByField[0]).filter(key => !byFieldColumnDefs.find(column => column.field === key));

            // Generate dynamic column definitions only for keys that are not already present
            const dynamicColumnDefs = keysToAdd.map((key) => ({
                headerName: key,
                field: key,
            }));

            // Update columnDefs with dynamic column definitions
            setByFieldColumnDefs(prevDefs => [...prevDefs, ...dynamicColumnDefs]);
        }
    }, [reconciliationDetailsByField])


    // Default column definition for AgGrid
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    let gridApi;
    // Callback when the grid is ready
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => `No data available` } }, []);

    const handleSelect = (key) => {
        setActiveTab(key);
        localStorage.setItem("MainTab", key);

        if (key == "byclient") {
            setShowDetailsTab(false);
            localStorage.setItem("ClientType", "Individual");
            setSelectedType({
                "label": "Individual",
                "value": "Individual",
            });
            fetchReconciliationData();
        }
        else if (key == "byfield") {
            setShowDetailsTab(false);
            localStorage.setItem("ClientType", "Individual");
            setSelectedType({
                "label": "Individual",
                "value": "Individual",
            });
            fetchReconciliationByFieldData();
        }
        else if (key === "details") {
            setShowDetailsTab(true);
        }
    }

    const renderDetailsTab = () => {
        if (showDetailsTab) {
            const mainTab = localStorage.getItem("MainTab");

            return (
                <Tab eventKey="details" title="Details" className="tabs">
                    {/* Content of the Details tab */}
                    {
                        mainTab === "byfield" ? (
                            <ClientReconciliationByFieldPage
                                show={showDetailsTab}
                            />
                        ) : (
                            <ClientReconciliationByClientPage
                                show={showDetailsTab}
                            />
                        )
                    }
                </Tab>
            );
        }
    };

    const updateClientType = (e) => {
        setSelectedType(e);
        localStorage.setItem("ClientType", e.value);

        if (activeTab == "byclient")
            fetchReconciliationData();
        else if (activeTab == "byfield")
            fetchReconciliationByFieldData();
    }

    const resetState = () => {
        setRefreshGrid(false);
        setReconciliationDetails([]);
        setReconciliationDetailsByField([]);
        setColumnDefs([
            {
                headerName: "No.",
                field: "", // No field needed for row number
                valueGetter: "node.rowIndex + 1",
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                pinned: "left"
            },
            {
                headerName: "Match Status",
                field: "Match Status",
                pinned: "right",
                cellRenderer: (params) => {
                    if (params.value) {
                        return <Icon.CheckCircleFill className="icon-cell done" onClick={() => { }} />;
                    } else {
                        return <Icon.XCircleFill className="icon-cell dump" onClick={() => { }} />;
                    }
                },
                maxWidth: 140,
                minWidth: 140
            },
            {
                headerName: "Details",
                field: "",
                field: "",
                pinned: "right",
                cellRenderer: BtnCellRenderer,
                maxWidth: 80,
                minWidth: 80,
                sortable: false
            }
        ]);

        setByFieldColumnDefs([
            {
                headerName: "No.",
                field: "", // No field needed for row number
                valueGetter: "node.rowIndex + 1",
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                pinned: "left"
            },
            {
                headerName: "Details",
                field: "",
                cellRenderer: BtnCellRenderer,
                pinned: "right",
                maxWidth: 80,
                minWidth: 80,
                sortable: false
            }
        ]);
    };

    useEffect(() => {
        // Code to execute on component mount
        return () => {
            // Code to execute on component unmount
            localStorage.removeItem("ClientType");
        };
    }, []);

    // JSX for the page component
    return (
        <>
            {!refreshGrid
                ?
                <>
                    <Row className='no-gutter'>
                        <Col md={12} className="flex-row-space-start">
                            <p className="display-6" style={{ height: "fit-content", marginBottom: 0, width: "fit-content" }}>Client Reconciliation</p> <p style={{ width: "fit-content" }}></p>
                        </Col>
                    </Row>
                    <Row className='no-gutter'>
                        <Col md={12}>
                            <Tabs
                                defaultActiveKey={activeTab}
                                activeKey={activeTab}
                                className="mb-3"
                                onSelect={handleSelect}
                            >
                                {
                                    <Tab eventKey="byclient" title="By Client" className="tabs">
                                        <Row className='no-gutter'>
                                            <Col md={12} className="d-flex justify-content-end" style={{ float: "right" }}>
                                                <div className="form-select-wrapper" style={{ width: 200, fontSize: 14, marginRight: "1.5rem", marginTop: "-5rem" }}>
                                                    <p style={{ width: 150, fontWeight: 600, marginBottom: 0 }}>Client type:</p>
                                                    <Select
                                                        value={selectedType}
                                                        onChange={(event) => updateClientType(event)}
                                                        options={[
                                                            {
                                                                "label": "Individual",
                                                                "value": "Individual",
                                                            },
                                                            {
                                                                "label": "Entity",
                                                                "value": "Entity"
                                                            }
                                                        ]}
                                                        className="form-select custom-select"
                                                        aria-label="Default"
                                                        closeMenuOnSelect={true}
                                                        autosize={true}
                                                        placeholder="Select type of client"
                                                        menuPosition="fixed"
                                                        classNamePrefix="react-select"
                                                    />
                                                </div>
                                                <div className="form-select-wrapper" style={{ fontSize: "0.9rem", marginRight: "1.5rem", marginBottom: "-0.92rem", marginTop: "-5rem" }}>
                                                    <p style={{ fontWeight: "bold", marginRight: "0.5rem" }}>Total Match Count:</p>
                                                    <p>{totalMatchCount} / {rowCount}</p>
                                                </div>
                                                <div style={{ marginBottom: "0rem", marginTop: "-3.1rem" }}>
                                                    {
                                                        refreshGrid ?
                                                            <div>
                                                                <ThreeCircles
                                                                    height="20"
                                                                    width="20"
                                                                    color="#4fa94d"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                    ariaLabel="three-circles-rotating"
                                                                    outerCircleColor="var(--theme1_tertiary)"
                                                                    innerCircleColor="var(--theme1_secondary)"
                                                                    middleCircleColor="var(--theme1_primary)"
                                                                />
                                                            </div>
                                                            :
                                                            <Icon.ArrowCounterclockwise onClick={() => fetchReconciliationData()}
                                                                style={{
                                                                    fontSize: 20,
                                                                    float: "right",
                                                                    border: "0px",
                                                                    cursor: "pointer"
                                                                }}
                                                            />
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='no-gutter'>
                                            <div className="ag-theme-balham" style={gridStyle}>
                                                <AgGridReact
                                                    key={refreshGrid}
                                                    ref={gridRef}
                                                    rowData={reconciliationDetails}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={defaultColDef}
                                                    onGridReady={onGridReady}
                                                    alwaysShowHorizontalScroll={false}
                                                    alwaysShowVerticalScroll={false}
                                                    rowHeight={rowHeight}
                                                    rowSelection={"none"}
                                                    suppressContextMenu={true}
                                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                                    onSortChanged={onSortChanged}
                                                    suppressCellFocus={true}
                                                />
                                            </div>
                                            =                                        </Row>
                                    </Tab>
                                }

                                {
                                    <Tab eventKey="byfield" title="By Field" className="tabs">
                                        <Row className='no-gutter'>
                                            <Col md={12} className="d-flex justify-content-end" style={{ float: "right" }}>
                                                <div className="form-select-wrapper" style={{ width: 200, fontSize: 14, marginRight: "1.5rem", marginTop: "-5rem" }}>
                                                    <p style={{ width: 150, fontWeight: 600, marginBottom: 0 }}>Client type:</p>
                                                    <Select
                                                        value={selectedType}
                                                        onChange={(event) => updateClientType(event)}
                                                        options={[
                                                            {
                                                                "label": "Individual",
                                                                "value": "Individual",
                                                            },
                                                            {
                                                                "label": "Entity",
                                                                "value": "Entity"
                                                            }
                                                        ]}
                                                        className="form-select custom-select"
                                                        aria-label="Default"
                                                        closeMenuOnSelect={true}
                                                        autosize={true}
                                                        placeholder="Select type of client"
                                                        menuPosition="fixed"
                                                        classNamePrefix="react-select"
                                                    />
                                                </div>
                                                <div style={{ marginBottom: "0rem", marginTop: "-3.1rem" }}>
                                                    {
                                                        refreshGrid ?
                                                            <div>
                                                                <ThreeCircles
                                                                    height="20"
                                                                    width="20"
                                                                    color="#4fa94d"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                    ariaLabel="three-circles-rotating"
                                                                    outerCircleColor="var(--theme1_tertiary)"
                                                                    innerCircleColor="var(--theme1_secondary)"
                                                                    middleCircleColor="var(--theme1_primary)"
                                                                />
                                                            </div>
                                                            :
                                                            <Icon.ArrowCounterclockwise onClick={() => fetchReconciliationByFieldData()}
                                                                style={{
                                                                    fontSize: 20,
                                                                    float: "right",
                                                                    border: "0px",
                                                                    cursor: "pointer"
                                                                }}
                                                            />
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='no-gutter'>
                                            <div className="ag-theme-balham" style={gridStyle}>
                                                <AgGridReact
                                                    key={refreshGrid}
                                                    ref={gridRef}
                                                    rowData={reconciliationDetailsByField}
                                                    columnDefs={byFieldColumnDefs}
                                                    defaultColDef={defaultColDef}
                                                    onGridReady={onGridReady}
                                                    alwaysShowHorizontalScroll={false}
                                                    alwaysShowVerticalScroll={false}
                                                    rowHeight={25}
                                                    rowSelection={"none"}
                                                    suppressContextMenu={true}
                                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                                />
                                            </div>
                                        </Row>
                                    </Tab>
                                }

                                {/* Render the Details tab conditionally */}
                                {renderDetailsTab()}

                            </Tabs>
                        </Col>
                    </Row>
                </>
                :
                <div
                    className="flex-row-center-center"
                    style={{ marginTop: "20%" }}
                >
                    <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                        <Bars
                            height="80"
                            width="80"
                            color="var(--theme1_primary)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                        Loading your data
                    </div>
                </div>
            }
        </>
    );
};

export default DiffToolMainClientGridPage;