import React from 'react';
export const Spacer = ({ topMargin, rightMargin, bottomMargin, leftMargin, updateTopMargin, updateBottomMargin, updateRightMargin, updateLeftMargin }) => {
    return (
        <>
            <div style={{ width: "20%", marginRight: 10 }}>
                <label style={{ fontWeight: 500, fontSize: 8 }}>Top</label>
                <input type="number" className="form-control move-left custom-input" id="topMargin" aria-describedby="topMargin"
                    value={topMargin}
                    onChange={event => {
                        updateTopMargin(event)
                    }}
                />
            </div>
            <div style={{ width: "20%", marginRight: 10 }}>
                <label style={{ fontWeight: 500, fontSize: 8 }}>Right</label>
                <input type="number" className="form-control move-left custom-input" id="rightMargin" aria-describedby="rightMargin"
                    value={rightMargin}
                    onChange={event => {
                        updateRightMargin(event)
                    }}
                />
            </div>
            <div style={{ width: "20%", marginRight: 10 }}>
                <label style={{ fontWeight: 500, fontSize: 8 }}>Bottom</label>
                <input type="number" className="form-control move-left custom-input" id="bottomMargin" aria-describedby="bottomMargin"
                    value={bottomMargin}
                    onChange={event => {
                        updateBottomMargin(event)
                    }}
                />
            </div>
            <div style={{ width: "20%", marginRight: 10 }}>
                <label style={{ fontWeight: 500, fontSize: 8 }}>Left</label>
                <input type="number" className="form-control move-left custom-input" id="leftMargin" aria-describedby="leftMargin"
                    value={leftMargin}
                    onChange={event => {
                        updateLeftMargin(event)
                    }}
                />
            </div>
        </>
    )
}