import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { getFromLS } from "../../../utils/storage";
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import './ContentBlockCRUDModel.css';
import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import { toast } from "react-toastify";
import { logout } from "../../../utils/index.js";
import { ThreeCircles, Bars } from "react-loader-spinner";

const ContentBlockCRUDModel = (props) => {
    const token = getFromLS("token");
    const [blockName, setBlockName] = useState(props.blockName);
    const [blockContent, setBlockContent] = useState(props.blockContent);
    const [active, setActive] = useState(props.active);
    const [saveStatus, setSaveStatus] = useState(false);

    const handleCancel = () => {
        props.onHide();
    }

    const handleOk = async () => {
        setSaveStatus(true);

        const status = await saveContentBlockDetails();

        setSaveStatus(false);
        
        if(status)
            handleCancel();
    }

    // Toolbar settings for the editor
    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
    };

    // Handler for content change
    const handleContentChange = (args) => {
        setBlockContent(args.value);
    };

    const Switch = ({ isToggled, onClick, isDisabled }) => {
        return (
            <label className="switch">
                <input type="checkbox" checked={isToggled} onChange={onClick} disabled={isDisabled} style={{ appearance: "none" }} />
                <span className={`super-admin-slider super-admin-round ${isDisabled ? "disabled" : ""}`}></span>
            </label>
        );
    };

    // Determine if inputs should be disabled based on mode
    const isDisabled = props.mode === "view";

    const saveContentBlockDetails = async () => {
        const contentBlockId = props.contentBlockId;
        
        let url = `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/content_blocks/`;
        
        const body = {
            ContentBlockId: contentBlockId,
            BlockName: blockName,
            BlockContent: blockContent,
            Status: active === "Y" ? "Active" : "Inactive",
            Active: active
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended, please log in again.");
                } else {
                    throw new Error("Request failed.");
                }
            }

            const data = await response.json();

            if (data.success) {
                toast.success(
                    `Content block ${props.mode === "create" ? "created" : "updated"} successfully!`,
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-success",
                    }
                );
                return true;
            } else {
                toast.error(
                    `Failed to save block data: ${data.message}`,
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-error",
                    }
                );
            }
        } catch (error) {
            console.error('Save block data error:', error);
        }

        return false;
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className="block-modal"
            scrollable="false"
            show="true"
            style={{ marginTop: "20px" }}
        >
            <Modal.Header className="block-modal-header" closeButton>
                <div className="flex-row-space-center">
                    {props.mode === "create" ? (
                        <h4>Create Content Block&nbsp;&nbsp;</h4>
                    ) : (
                        <h4>{blockName}&nbsp;&nbsp;</h4>
                    )}

                    <Switch
                        isToggled={active === "Y"}
                        onClick={() => setActive(active === "Y" ? "N" : "Y")}
                        isDisabled={isDisabled}
                    />
                </div>
            </Modal.Header>
            <Modal.Body className="block-modal-body">
                {/* Block Name Row */}
                <Row className="no-gutter" style={{ padding: "0px", marginBottom: "15px" }}>
                    <Col md={4}>
                        <div className="flex-row-space-center">
                            <span className="block-select-label">Block Name</span>
                        </div>
                        <input 
                            id="block-name"
                            type="text" 
                            className={`form-control move-left custom-input ${isDisabled ? "disabled" : ""}`}
                            placeholder={'Enter block name'}
                            value={blockName}
                            onChange={(event) => setBlockName(event.target.value)}
                            autoComplete="nope"
                            disabled={isDisabled || (active === "Y" && props.mode === "edit")} // Disable input if in view mode
                        />
                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>
                
                {/* Block Content Row */}
                <Row className="no-gutter" style={{ padding: "0px", marginBottom: "15px" }}>
                    <Col md={12}>
                        <div className="flex-row-space-center">
                            <span className="block-select-label">Block Content</span>
                        </div>
                        <RichTextEditorComponent 
                            height={300} 
                            value={blockContent}
                            toolbarSettings={toolbarSettings} 
                            change={handleContentChange}
                            placeholder={'Enter block content'}
                            className={isDisabled ? "disabled" : ""}
                            readonly={isDisabled} // Disable editing if in view mode
                        >
                            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer className="action-footer" style={{ paddingTop: 0, marginTop: 0 }}>
                <Row style={{ padding: 0, margin: 0, marginRight: ".5rem" }}>
                    <Col md={6}>
                        <Button 
                            onClick={handleOk} 
                            className={`${!saveStatus ? "custom-button" : "api-progress"} ${isDisabled ? "disabled" : ""}`}
                            disabled={isDisabled}
                        >   
                            {saveStatus ? (
                                <>
                                    <ThreeCircles
                                        height="15"
                                        width="15"
                                        color="#4fa94d"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="three-circles-rotating"
                                        outerCircleColor="#4fa94d"
                                        innerCircleColor="#4fa94d"
                                        middleCircleColor="#4fa94d"
                                    />
                                </>
                            ) : (
                                <>
                                    <p style={{ marginBottom: 0, marginTop: 0 }}>OK</p>
                                </>
                            )}
                        </Button>
                    </Col>
                    <Col md={6} style={{ padding: "0px 10px 0px 0px" }}>
                        <Button onClick={handleCancel} className="custom-button">
                            <p className="icon-wrapper" style={{ marginBottom: 0, marginTop: 0 }}>Cancel</p>
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default ContentBlockCRUDModel;
